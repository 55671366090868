<div class="cart-extra-total">
  <span class="total-label">{{ 'Extras Total' | translate:'price-display' }}</span>
  <span class="total-value" attr.aria-label="{{'total' | translate: 'price-display'}}">{{ total | currencyDisplay
    }}</span>
</div>

<div class="extra-item" [ngClass]="{'open' : isExpanded[extrasItem.ssrType]  }"
  *ngFor="let extrasItem of extras; let fIndex = index">
  <button type="button" class="extra-item-summary"
    attr.aria-label="{{ 'Click to expand {0} for more options' | translate:'price-display':[ssrTypeMetadata[extrasItem.ssrType].name | translate:'price-display' | lowercase ]}}"
    (click)="toggleExpanded(extrasItem.ssrType)">
    <i class="icon {{ssrTypeMetadata[extrasItem.ssrType].icon}}" role="presentation"></i>
    <span class="title">{{ ssrTypeMetadata[extrasItem.ssrType].name | translate:'price-display' }}</span>
    <ng-container *ngIf="extrasItem.flightsWithItems.length > 1; then detailsAll else detailsSingle"></ng-container>
    <ng-template #detailsAll>
      <span class="details">{{ 'All segments' | translate: 'price-display' }}</span>
    </ng-template>
    <ng-template #detailsSingle>
      <span class="details" *ngIf="extrasItem.flightsWithItems[0].departureStation">{{ '{0} to {1}' | translate:
        '':extrasItem.flightsWithItems[0].departureStation:extrasItem.flightsWithItems[0].arrivalStation }}</span>
    </ng-template>
    <span class="summary-value">{{ extrasItem.amount | cultureFormatNumber:'1.2-2' }}</span>
  </button>

  <ul role="menu" class="extra-item-details" [style.display]="isExpanded[extrasItem.ssrType] ? 'block':''">
    <ng-container *ngFor="let flight of extrasItem.flightsWithItems" [ngSwitch]="extrasItem.ssrType">
      <ng-container *ngSwitchCase="SsrTypeEnum.Meal">
        <ng-container
          *ngTemplateOutlet="extrasForSegments; context: {flight: flight, ssrType: extrasItem.ssrType}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="SsrTypeEnum.Seat">
        <ng-container
          *ngTemplateOutlet="extrasForSegments; context: {flight: flight, ssrType: extrasItem.ssrType}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="SsrTypeEnum.Other">
        <ng-container
          *ngTemplateOutlet="extrasForSegments; context: {flight: flight, ssrType: extrasItem.ssrType}"></ng-container>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <ng-container
          *ngTemplateOutlet="extrasForFlight; context: {flight: flight, passengers: flight.journeyPassengers, ssrType: extrasItem.ssrType}"></ng-container>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #extrasForSegments let-flight="flight" let-ssrType="ssrType">
  <ng-container *ngFor="let segment of flight.segments">
    <ng-container
      *ngTemplateOutlet="extrasForFlight; context: {flight: segment, passengers: segment.passengers, ssrType: ssrType}"></ng-container>
  </ng-container>
</ng-template>

<ng-template #extrasForFlight let-flight="flight" let-passengers="passengers" let-ssrType="ssrType">
  <li role="menuitem" class="flight-details" *ngIf="flight.departureStation && passengers.length">
    {{ '{0} to {1}' | translate:'':flight.departureStation:flight.arrivalStation }}
  </li>
  <ng-container *ngFor="let passenger of passengers">
    <li *ngFor="let item of passenger.items">
      <span class="left" *ngIf="passenger.passengerType">{{ passenger.passengerType.substring(0,3) + '-title' |
        translate:'price-display'}} {{paxNumberByType[passenger.passengerNumber]}}</span>
      <ng-container [ngSwitch]="ssrType">
        <!-- <span class="left" *ngSwitchCase="SsrTypeEnum.Baggage">{{ ssrItem.ssrCode  + ' ' }} </span> -->
        <span class="center" *ngSwitchCase="SsrTypeEnum.Baggage">{{ item.quantity}} x {{ item.ssrCode + '-size' |
          translate:'extras.bag-size-' + flight.departureStation + '_' + flight.arrivalStation}}</span>
        <span class="center" *ngSwitchCase="SsrTypeEnum.SpecialEquipment">{{ item.quantity }} x {{ item.ssrCode +
          '-title' | translate:'extras' }}</span>
        <span class="center" *ngSwitchCase="SsrTypeEnum.Pet">
          {{ item.quantity }} x
          <ng-container *ngIf="item.ssrCode == 'PETC'">
            {{ 'Pet in cabin' | translate:'extras' }}
          </ng-container>
          <ng-container *ngIf="item.ssrCode == 'AVIH'">
            {{ 'Pet in hold' | translate:'extras' }}
          </ng-container>
        </span>
        <span class="center" *ngSwitchCase="SsrTypeEnum.SpecialAssistance"> {{ item.ssrCode + '-title' |
          translate:'extras' }} </span>
        <span class="center" *ngSwitchCase="SsrTypeEnum.Insurance">
          <span *ngIf="isRoundTrip else onwWayInsuranceDisplay">
            {{ 'Travel insurance' | translate:'extras' }}
          </span>
          <ng-template #onwWayInsuranceDisplay>
            {{ item.ssrCode + '-insurance' | translate:'insurance.days' }}
          </ng-template>
        </span>
        <span class="center" *ngSwitchCase="SsrTypeEnum.Seat">{{ item.ssrCode }}</span>
        <span class="center" *ngSwitchCase="SsrTypeEnum.Lounge">{{ item.ssrCode | translate: 'ssr.name' }}</span>
        <span class="center" *ngSwitchDefault>{{ item.quantity }} x {{item.ssrCode + '-title' | translate:
          'extras'}}</span>
      </ng-container>

      <span class="right">{{ item.totalAmount | cultureFormatNumber:'1.2-2' }}

      </span>
    </li>
  </ng-container>
</ng-template>