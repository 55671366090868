import { OnInit, Renderer2, OnDestroy } from '@angular/core';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { PaymentsService } from '../payments.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { PaymentRequestDetails } from '../payment-request-details';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { SessionService } from 'src/app/common-modules/blue-air-common/session.service';
import { Location } from '@angular/common';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { LoadingSpinnerService } from 'src/app/common-modules/blue-air-common/loading-spinner.service';
import { EnvHelper } from 'src/app/env-helper';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
import { AeroInvoicingComponent } from '../invoicing-aero/invoicing-aero.component';
import { isNullOrUndefined, isUndefined } from 'util';
import { BookingService } from '../../core/booking.service';
import { FeesService } from '../../extras/fees.service';
import { ConfigService } from '../../core/config.service';
import { Constants } from '../../constants';
var SummaryComponent = /** @class */ (function () {
    // priviate FeeConfig(): IFeeDiscount{
    //   return this.configService.config.markets
    // }
    function SummaryComponent(bookingService, steps, paymentsService, translateService, render, route, router, sessionService, location, flowManager, feesService, configService, spinnerService) {
        var _this = this;
        this.bookingService = bookingService;
        this.steps = steps;
        this.paymentsService = paymentsService;
        this.translateService = translateService;
        this.render = render;
        this.route = route;
        this.router = router;
        this.sessionService = sessionService;
        this.location = location;
        this.flowManager = flowManager;
        this.feesService = feesService;
        this.configService = configService;
        this.spinnerService = spinnerService;
        this.feeCodeConst = "SCLY";
        this.currentStep = CheckinSteps.summary;
        this.invoicingStateInfo = new InvoicingStateInfo();
        this.isSicilyResident = false;
        this.isOnAgencyPortal = EnvHelper.IsOnAgencyPortal();
        this.unsubscribeSubject = new Subject();
        this.steps.currentStep.next(this.currentStep);
        this.assetsPath = environment.assetsPath;
        bookingService.refresh(false)
            .then(function (booking) {
            _this.setPsoFee(booking)
                .then(function (_) {
                if (!isUndefined(_)) {
                    sessionStorage.setItem(Constants.HasPsoFee, "true");
                }
                _this.route.queryParams.pipe(take(1)).subscribe(function (params) {
                    _this.paymentFailed = params['error'] === 'true';
                    if (_this.paymentFailed) {
                        _this.router.navigate([], { replaceUrl: true });
                    }
                });
                _this.route.paramMap.pipe(take(1)).subscribe(function (params) {
                    _this.bookingFlow = params.get('flow');
                });
                _this.flowManager.applicationFlowService.loadPriceBreakdown(true).then(function (breakdown) {
                    if (breakdown) {
                        sessionStorage.setItem(Constants.CartBreakdown, JSON.stringify(breakdown));
                        if (breakdown.currentShoppingCart.balanceDue > 0) {
                            _this.loadPaymentMethods();
                        }
                        else {
                            _this.router.navigateByUrl('/verifypayment/ok');
                        }
                    }
                });
                _this.paymentError = _this.paymentsService.paymentError;
                _this.invoicingStateInfo.isEnabled = !_this.isOnAgencyPortal;
                // DEBUG invoice only - do not let this here at the end of implementation
                _this.invoicingStateInfo.isAvailable = true;
                // this.invoicingStateInfo.isRequested = true;
                // this.invoicingStateInfo.isDisplayingEditor = true;
            });
        });
    }
    Object.defineProperty(SummaryComponent.prototype, "balanceDueAfterWalletPayment", {
        get: function () {
            return this.balanceDue - this.walletPayableAmount;
        },
        enumerable: true,
        configurable: true
    });
    SummaryComponent.prototype.isInvoicingAvailableForPayment = function (paymentGroup) {
        return this.invoicingStateInfo.isEnabled
            && this.invoicingStateInfo.isAvailable
            && paymentGroup.name.toLowerCase().indexOf('card') != -1;
    };
    SummaryComponent.prototype.ngOnInit = function () {
    };
    SummaryComponent.prototype.setPsoFee = function (booking) {
        var _this = this;
        // const feeCodeConst = "SCLY";
        this.initSicilyAgent(booking.bookingDetail);
        var bookingFees = booking.passengers.items[0].fees.items;
        var hasPsoFee = function () { return sessionStorage.getItem(Constants.HasPsoFee) == "true"; };
        if (this.isSicilyResident //is Sicilian route
            && !hasPsoFee() // it doesn't have the fee SCLY
            && (isNullOrUndefined(booking.bookingDetail.recordLocator) || booking.bookingDetail.recordLocator === "") // it doesn't have a record locator
            && (bookingFees.length == 0
                || isUndefined(bookingFees.find(function (fee) { return fee.code === _this.feeCodeConst; })))) // it doesn't have the fee SCLY
         {
            // const booking = this.bookingService.getBooking();
            var request = {
                amount: this.computeSicilyResidentFeeAmount(booking),
                currencyCode: booking.convertedJourneys.currencyCode,
                feeCode: this.feeCodeConst,
                passengerNumber: 0,
                applyToAll: false,
                comment: "PSO"
            };
            return this.feesService.sellFee(request);
        }
        return Promise.resolve();
    };
    SummaryComponent.prototype.computeSicilyResidentFeeAmount = function (booking) {
        var amountDue = booking.payments.balanceDue;
        var passengerNo = booking.passengers.items.length;
        var limitAmount = 75;
        var discount = 0.25;
        var sellFeeAmount = 0;
        if (amountDue * discount / passengerNo < limitAmount) {
            sellFeeAmount = discount * amountDue;
        }
        else {
            sellFeeAmount = limitAmount * passengerNo;
        }
        // we need to deduct this amount
        return sellFeeAmount * -1;
    };
    SummaryComponent.prototype.initSicilyAgent = function (bookingDetail) {
        if (isNullOrUndefined(bookingDetail))
            return;
        try {
            //this.configService.config.markets.agent;
            this.isSicilyResident = bookingDetail.pointOfSale.agentCode == "sclres";
        }
        catch (err) {
            this.isSicilyResident = false;
        }
    };
    SummaryComponent.prototype.ngOnDestroy = function () {
        this.unsubscribeSubject.next(true);
    };
    SummaryComponent.prototype.skipInvoicing = function () {
        this.invoicingStateInfo.isRequested = false;
        this.goToNextStep();
    };
    SummaryComponent.prototype.goToNextStep = function () {
        var _this = this;
        this.submitted = false;
        if (this.isWalletPaymentSelected && this.balanceDueAfterWalletPayment === 0) {
            this.setupPaymentAndRedirect(null, this.walletPayableAmount);
            return;
        }
        var selectedPaymentMethod = null;
        var selectedGroup = this.paymentMethodGroups.find(function (g) { return g.isSelected; });
        if (selectedGroup) {
            selectedPaymentMethod =
                selectedGroup.items.length === 1 ?
                    selectedGroup.items[0] :
                    selectedGroup.items.find(function (i) { return i.isSelected; });
        }
        this.submitted = true;
        this.isTermsAccepted = selectedGroup && selectedGroup.isTermsAccepted;
        this.isPaymentMethodSelected = selectedPaymentMethod ? true : false;
        if (this.isTermsAccepted && this.isPaymentMethodSelected) {
            if (selectedGroup.name === 'Agency' && this.agencyPaymentModal) {
                this.agencyPaymentModal.openPopup(function (isOk) {
                    if (isOk) {
                        _this.setupPaymentAndRedirect(selectedPaymentMethod.code, _this.isWalletPaymentSelected ? _this.walletPayableAmount : 0);
                    }
                });
            }
            else {
                this.setupPaymentAndRedirect(selectedPaymentMethod.code, this.isWalletPaymentSelected ? this.walletPayableAmount : 0);
            }
        }
    };
    SummaryComponent.prototype.promoCodeChanged = function (promoCode) {
        this.loadPaymentMethods();
    };
    SummaryComponent.prototype.loadPaymentMethods = function () {
        var _this = this;
        this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
        this.paymentsService.loadPaymentMethods(this.bookingFlow).pipe(takeUntil(this.unsubscribeSubject)).subscribe(function (response) {
            _this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
            _this.invoicingStateInfo.isEnabled = response.invoicingEnabled;
            _this.invoicingStateInfo.isRequested = response.invoicingRequested;
            _this.paymentMethods = response.paymentMethods;
            var groupBy = _this.paymentMethods.reduce(function (group, item) {
                if (item.code === 'WT') {
                    if (item.details && item.details.available > 0) {
                        _this.walletPaymentMethod = item;
                        _this.flowManager.applicationFlowService.shoppingCartBreakdown.pipe(take(1)).subscribe(function (pb) {
                            _this.walletPayableAmount = Math.min(pb.currentShoppingCart.balanceDue, _this.walletPaymentMethod.details.availableInBookingCurrency / 100);
                            _this.balanceDue = pb.currentShoppingCart.balanceDue;
                        });
                    }
                    // stop here do not add wallet to all payments
                    return group;
                }
                group[item.group] = group[item.group] || {
                    name: item.group, key: item.group.replace(' ', '-'), fee: 0, items: [],
                    description: _this.translateService.instant(item.group + ' - description', 'summary'),
                    isSelected: false, isTermsAccepted: false, isDisabled: false
                };
                group[item.group].items.push(item);
                return group;
            }, {});
            _this.paymentMethodGroups = [];
            for (var group in groupBy) {
                if (groupBy.hasOwnProperty(group)) {
                    _this.paymentMethodGroups.push(groupBy[group]);
                }
            }
            //reorder Payment Methods
            if (_this.paymentMethodGroups.filter(function (pm) { return pm.name.match('Card'); })[0]) {
                var cardsGroup = _this.paymentMethodGroups.filter(function (pm) { return pm.name.match('Card'); })[0];
                var visaIndex = cardsGroup.items.findIndex(function (p) { return p.name.match('VISA') != null; });
                var masterIndex = cardsGroup.items.findIndex(function (p) { return p.name.match('Master') != null; });
                var visa = cardsGroup.items[visaIndex];
                var master = cardsGroup.items[masterIndex];
                cardsGroup.items[visaIndex] = master;
                cardsGroup.items[masterIndex] = visa;
            }
            _this.selectGroup("Credit-Card");
        });
    };
    SummaryComponent.prototype.redirectToPayment = function (selectedPaymentCode, walletPaymentAmount, invoiceData) {
        var _this = this;
        if (invoiceData === void 0) { invoiceData = null; }
        var flowInsert = this.bookingFlow ? "/" + this.bookingFlow : '';
        var successUrl = "/verifypayment" + flowInsert + "/ok";
        var errorUrl = "/verifypayment" + flowInsert + "/error";
        this.spinnerService.showSpinnerById(Constants.mainSpinnerId);
        this.paymentsService.selectPaymentMethod(selectedPaymentCode, walletPaymentAmount, this.prepareExternalUrl(successUrl), this.prepareExternalUrl("/summary" + flowInsert), this.prepareExternalUrl(errorUrl), invoiceData).subscribe(function (response) {
            if (response.isSuccessful) {
                if (response.redirectUrl) {
                    window.location.href = response.redirectUrl;
                }
                else {
                    _this.router.navigateByUrl(successUrl);
                }
            }
            else {
                _this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
                _this.paymentError = response.errorCode ? _this.translateService.instant(response.errorCode, 'payment-error') : response.errorMessage;
                _this.paymentFailed = true;
            }
        }, function () {
            _this.spinnerService.hideSpinnerById(Constants.mainSpinnerId);
            _this.paymentError = _this.translateService.instant('Something went wrong with your payment please try again!', 'payment-error');
            _this.paymentFailed = true;
        });
    };
    SummaryComponent.prototype.setupPaymentAndRedirect = function (selectedPaymentCode, walletPaymentAmount) {
        var _this = this;
        if (this.invoicingStateInfo.shouldDisplayInvoicingFields()) {
            this.invoicingStateInfo.isDisplayingEditor = true;
            return;
        }
        if (this.invoicingStateInfo.shouldApplyInvoicingFields()) {
            this.invoicingComponent.validateAndRetrieveInvoiceData()
                .pipe(takeUntil(this.unsubscribeSubject))
                .subscribe(function (result) {
                if (result.formValid && result.dataValid) {
                    _this.redirectToPayment(selectedPaymentCode, walletPaymentAmount, result.invoiceData);
                }
            });
        }
        else {
            this.redirectToPayment(selectedPaymentCode, walletPaymentAmount);
        }
    };
    SummaryComponent.prototype.selectGroup = function (groupKey) {
        var _this = this;
        var selectedGroup = this.paymentMethodGroups.find(function (g) { return g.isSelected; });
        if (selectedGroup && selectedGroup.key === groupKey) {
            this.updateGroupSelection(selectedGroup, false);
        }
        else {
            this.paymentMethodGroups.forEach(function (g) {
                _this.updateGroupSelection(g, g.key === groupKey);
            });
            this.selectFirstPaymentMethod();
        }
        this.updateInvoicingInfoByPaymentGroup(groupKey);
    };
    SummaryComponent.prototype.updateGroupSelection = function (group, isSelected) {
        if (group.isSelected !== isSelected) {
            this.selectedPaymentMethod = null;
        }
        group.isSelected = isSelected;
        if (!group.isSelected) {
            group.isTermsAccepted = false;
            group.items.forEach(function (i) { return i.isSelected = false; });
        }
    };
    SummaryComponent.prototype.updateInvoicingInfoByPaymentGroup = function (groupKey) {
        switch (groupKey) {
            case 'Cash-Deposit':
            case 'Offline-Bank Transfer':
                this.invoicingStateInfo.isAvailable = false;
                break;
            default:
                this.invoicingStateInfo.isAvailable = true;
        }
    };
    SummaryComponent.prototype.acceptGroup = function (groupKey) {
        var selectedGroup = this.paymentMethodGroups.find(function (g) { return g.isSelected; });
        selectedGroup.isTermsAccepted = !selectedGroup.isTermsAccepted;
    };
    SummaryComponent.prototype.selectFirstPaymentMethod = function () {
        var selectedGroup = this.paymentMethodGroups.find(function (g) { return g.isSelected; });
        if (selectedGroup.items.length == 0)
            return;
        var firstMethod = selectedGroup.items[0];
        firstMethod.isSelected = true;
        this.selectedPaymentMethod = firstMethod;
    };
    SummaryComponent.prototype.selectPaymentMethod = function (groupKey, paymentCode) {
        var _this = this;
        var selectedGroup = this.paymentMethodGroups.find(function (g) { return g.isSelected; });
        selectedGroup.items.forEach(function (i) {
            i.isSelected = i.code === paymentCode;
            if (i.isSelected) {
                _this.selectedPaymentMethod = i;
            }
        });
    };
    SummaryComponent.prototype.walletPaymentSelectedChanged = function () {
        var _this = this;
        this.paymentMethodGroups.forEach(function (pm) {
            pm.isDisabled = _this.isWalletPaymentSelected && (_this.balanceDueAfterWalletPayment === 0 || pm.name !== 'Credit Card');
            pm.isTermsAccepted = false;
            pm.isSelected = false;
            pm.items.forEach(function (i) { return i.isSelected = false; });
        });
    };
    SummaryComponent.prototype.prepareExternalUrl = function (path) {
        var origin = window.location.origin;
        var sessionUrlPart = "/session/" + this.sessionService.sessionId + "?path=";
        var url = "" + sessionUrlPart + path;
        return origin + this.location.prepareExternalUrl(url);
    };
    return SummaryComponent;
}());
export { SummaryComponent };
var InvoicingStateInfo = /** @class */ (function () {
    function InvoicingStateInfo() {
    }
    InvoicingStateInfo.prototype.shouldDisplayInvoicingFields = function () {
        return this.isEnabled && this.isAvailable && this.isRequested && !this.isDisplayingEditor;
    };
    InvoicingStateInfo.prototype.shouldApplyInvoicingFields = function () {
        return this.isEnabled && this.isAvailable && this.isRequested && this.isDisplayingEditor;
    };
    return InvoicingStateInfo;
}());
export { InvoicingStateInfo };
