var Constants = /** @class */ (function () {
    function Constants() {
    }
    Object.defineProperty(Constants, "MobileAppOrigin", {
        get: function () {
            return "https://" + Constants.MobileAppDomain;
        },
        enumerable: true,
        configurable: true
    });
    // fee codes
    Constants.InsuranceFeeCode = 'ASIG';
    Constants.InsuranceInfantCode = 'INFT';
    // ssr codes
    Constants.InfantSsrCode = 'INF';
    Constants.SsrCodeUM = 'UM';
    Constants.SsrCodeUMFR = 'UMFR';
    Constants.SsrCodeUMNR = 'UMNR';
    Constants.SsrCodeUMRO = 'UMRO';
    Constants.SsrCodePSO = 'PSO';
    // passenger types
    Constants.AdultPaxType = 'ADT'; // 18+ years
    Constants.YoungAdultPaxType = 'YTH'; // 16-18 years
    Constants.TeenPaxType = 'CLD'; // 12-16 years
    Constants.ChildrenPaxType = 'CHD'; // 2-12 years
    Constants.InfantPaxType = 'INL'; // under 2 years
    // journey types
    Constants.OneWayTrip = 'OneWay';
    Constants.RoundTrip = 'RoundTrip';
    // others
    Constants.mobileScreenWith = 768;
    Constants.mainSpinnerId = 'mainSpinner';
    Constants.NotCheckedIn = 'Default';
    Constants.SelectedFlightIndexForChange = 'SelectedFlightForChange';
    Constants.CartBreakdown = 'CartBreakdown';
    Constants.MoveFlightsFlow = 'MoveFlightsFlow';
    Constants.HasPsoFee = 'hasPsoFee';
    // corporate bundle codes
    Constants.CorporateBundles = ['CORP', 'CYPF', 'CYPL'];
    // seats
    Constants.seatTypeDISABIL = 'DISABIL';
    Constants.seatTypePET = 'PET';
    Constants.seatTypeINFANT = 'INFANT';
    Constants.seatTypeNCHILD = 'NCHILD';
    Constants.seatTypeLOCATION = 'LOCATION';
    Constants.seatTypeLEGROOM = 'LEGROOM';
    Constants.MobileAppDomain = 'mobile.flyblueair.com';
    Constants.TypeCodes = ['Z', 'Y', 'X', 'V', 'U', 'T', 'S', 'R', 'Q', 'N'];
    return Constants;
}());
export { Constants };
