import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { of as observableOf } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { Helpers, } from '../helpers';
import { EnvHelper } from '../env-helper';
import { LoginResponseModel } from './models/profile/login-response-model';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./config.service";
import * as i3 from "../common-modules/blue-air-common/translator/translate.service";
export class ProfileService {
    constructor(http, config, translate) {
        this.http = http;
        this.config = config;
        this.translate = translate;
        this.profile = new BehaviorSubject(null);
        this.isAgent = EnvHelper.IsOnAgencyPortal();
        this.organizationCode = null;
        // setTimeout(() => {
        //   if (window.blueAirSessionObj && window.blueAirSessionObj.session && window.blueAirSessionObj.session.User) {
        //     this.refreshProfile();
        //   }
        // }, 0);
    }
    loginAgent(username, password) {
        return this.http.post(this.config.AgentLoginUrl, {
            autologinagent: {
                username: username,
                password: password
            }
        }).pipe(switchMap((result) => {
            const profile = this.validateProfile(result.memberProfile);
            if (profile != null) {
                return observableOf(result);
            }
            return this.getResetPassword();
        }), map((result) => {
            const response = new LoginResponseModel();
            if (result.memberProfile) {
                this.profile.next(result.memberProfile.member);
                response.loginOk = true;
                response.member = this.profile.value;
                response.errors = [];
            }
            else if (result.resetPassword) {
                response.loginOk = false;
                response.mustResetPassword = true;
                response.member = null;
                response.errors = [];
            }
            else {
                response.loginOk = false;
                response.member = null;
                response.errors = [this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
            }
            return response;
        }), catchError(errorResult => {
            const response = new LoginResponseModel();
            response.errors = [];
            if (errorResult.error && errorResult.error.errors && errorResult.error.errors.length) {
                response.errors.push(...errorResult.error.errors.map((err) => {
                    const t = this.translate.instant(err.errorCode, 'profile-info');
                    return t === err.errorCode ? `${err.errorCode}: ${err.errorMessage}` : t;
                }));
            }
            else {
                response.errors.push(this.translate.instant('GenericAuthenticationFailed', 'profile-info'));
            }
            return observableOf(response);
        }));
    }
    logout() {
        return this.http.get(this.config.LogoutUrl).toPromise();
    }
    refreshProfile(forced = false) {
        const getValue = {
            subject: this.profile,
            subscription: this.profileSubscription,
            valueProvider: () => this.getProfile(),
            forced: forced
        };
        const promise = Helpers.GetValueAsync(getValue);
        this.profileSubscription = getValue.subscription;
        return promise;
    }
    getProfile() {
        return this.http.get(this.config.ProfileUrl).pipe(map((p) => this.validateProfile(p.memberProfile)));
    }
    validateProfile(memberProfile) {
        if (memberProfile && memberProfile.member.agentId !== 9) {
            return memberProfile.member;
        }
        return null;
    }
    getResetPassword() {
        return this.http.get(this.config.ResetPasswordUrl);
    }
    resetPassword(agentId, username, oldPassword, password, passwordConf) {
        return this.http.post(this.config.ResetPasswordUrl, {
            resetPassword: {
                agentId: agentId,
                username: username,
                oldPassword: oldPassword,
                password: password,
                passwordConfirmation: passwordConf
            }
        }).pipe(map((result) => {
            const response = new LoginResponseModel();
            if (result.memberProfile) {
                if (this.validateProfile(result.memberProfile)) {
                    this.profile.next(result.memberProfile.member);
                    response.loginOk = true;
                    response.member = this.profile.value;
                    response.errors = [];
                }
                else {
                    response.loginOk = false;
                    response.member = null;
                    response.errors = [this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
                }
            }
            else {
                response.loginOk = false;
                response.member = null;
                response.errors = [this.translate.instant('GenericAuthenticationFailed', 'profile-info')];
            }
            return response;
        }), catchError(errorResult => {
            const response = new LoginResponseModel();
            response.errors = [];
            if (errorResult.error && errorResult.error.errors && errorResult.error.errors.length) {
                response.errors.push(...errorResult.error.errors.map((err) => {
                    const t = this.translate.instant(err.errorCode, 'profile-info');
                    return t === err.errorCode ? `${err.errorCode}: ${err.errorMessage}` : t;
                }));
            }
            else {
                response.errors.push(this.translate.instant('GenericAuthenticationFailed', 'profile-info'));
            }
            return observableOf(response);
        }));
    }
    updateAgent(updatedInfo) {
        const obj = {
            agentProfile: {
                agent: updatedInfo
            }
        };
        return this.http.post(this.config.SaveAgentInfoUrl, obj)
            .toPromise()
            .then(() => this.refreshProfile(true));
    }
    updateProfile(updatedInfo) {
        const obj = {
            memberProfile: {
                member: updatedInfo
            }
        };
        return this.http.post(this.config.SaveProfileInfoUrl, obj)
            .toPromise()
            .then(() => this.refreshProfile());
    }
    updateTravelDocument(updatedInfo, itemIndex) {
        const obj = {
            travelDocuments: {
                index: itemIndex,
                updateItem: updatedInfo
            }
        };
        if (itemIndex === -1) {
            return this.http.post(this.config.SaveTravelDocumentUrl, obj).toPromise()
                .then((travelDocs) => {
                return travelDocs;
            });
        }
        return this.http.put(this.config.SaveTravelDocumentUrl, obj).toPromise()
            .then((travelDocs) => {
            return travelDocs;
        });
    }
    deleteTravelDocument(itemIndex) {
        return this.http
            .delete(this.config.SaveTravelDocumentUrl, {
            params: {
                'travelDocuments.index': itemIndex + ''
            }
        })
            .toPromise()
            .then((travelDocs) => {
            return travelDocs;
        });
    }
    updateAddressBookItem(updateInfo, itemIndex) {
        const obj = {
            personAddresses: {
                updateItem: updateInfo,
                index: itemIndex
            }
        };
        if (itemIndex === -1) {
            return this.http.post(this.config.SaveAddressBookItemUrl, obj).toPromise()
                .then((data) => {
                return data;
            });
        }
        return this.http.put(this.config.SaveAddressBookItemUrl, obj).toPromise()
            .then((data) => {
            return data;
        });
    }
    deleteAddressBookItem(itemIndex) {
        return this.http
            .delete(this.config.SaveAddressBookItemUrl, {
            params: {
                'personAddresses.Index': itemIndex + ''
            }
        })
            .toPromise()
            .then((data) => {
            return data;
        });
    }
    updateSeatPreferences(updateInfo) {
        const obj = {
            memberProfile: {
                member: {
                    person: {
                        locationPreference: {
                            propertyCode: updateInfo.seatLocation
                        },
                        typePreference: {
                            propertyTypeCode: updateInfo.seatType
                        }
                    }
                }
            }
        };
        return this.http.post(this.config.SaveSeatPreferencesUrl, obj)
            .toPromise()
            .then(() => this.refreshProfile());
    }
}
ProfileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProfileService_Factory() { return new ProfileService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ConfigService), i0.ɵɵinject(i3.TranslateService)); }, token: ProfileService, providedIn: "root" });
