import { ConfigService } from '../core/config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingService } from '../core/booking.service';

@Injectable()
export class FeesService {

  constructor(
    private bookingService: BookingService,
    private http: HttpClient, private configService: ConfigService) { }


  sellFee(request: SellFeeParameters): Promise<any> {

    const applyFeesParams = {
      applyFees: {
        ...request
      }
    }
    return this.http.post(this.configService.SellFee, applyFeesParams).toPromise();
  }


}


export type SellFeeParameters = {
  comment: string,
  passengerNumber: number,
  amount: number,
  currencyCode: string,
  feeCode: string,
  applyToAll: boolean
}


