<check-in-unaccompanied-minor-notification #UMmodal></check-in-unaccompanied-minor-notification>
<check-in-selections-clear-warning-modal #changeFlightsWarningModal></check-in-selections-clear-warning-modal>

<check-in-default-modal #samePassengersNameWarningModal modalId="samePassengersNameModalId"
    modalTitle="{{'Passengers are not allowed to have the same first name and last name with others' | translate: 'passengers'}}"
    modalText="{{'Two or more passengers have the same title,first name and last name!' | translate: 'passengers'}}"
    okButtonText="{{'Ok' | translate: 'passengers'}}" [okRequired]="true" [cancelRequired]="false">
    <ul class="pending-list">
        <li *ngFor="let pax of duplicates">
            <span>{{pax.name.title + ' ' + pax.name.first + ' ' + pax.name.last}} </span>
        </li>
    </ul>
</check-in-default-modal>

<check-in-default-modal #subscribeTermsModal modalId="subscribeTermsModal"
    modalTitle="{{'Privacy-policy-warning-title' | translate:'passengers' }}"
    okButtonText="{{'Privacy-policy-warning-dismiss-btn' | translate:'passengers' }}"
    cancelButtonText="{{'Privacy-policy-warning-agree-btn' | translate: 'passengers'}}" [okRequired]="true"
    [cancelRequired]="true">
    <div
        innerHTML="{{'Privacy-policy-warning-content' | translate: 'passengers':['Privacy-policy-warning-agree-btn' | translate: 'passengers']}}">
    </div>
</check-in-default-modal>

<check-in-form-wrapper [ariaLabel]="ariaLabel" [step]="'step-3'" [showContinue]="false" [showPriceDisplay]="true"
    (continue)="applySelection()">
    <div class="form-wrapper-middle-body">

        <div attr.aria-label="{{ 'Passengers details' | translate:'passengers' }}">
            <h2 class="pageTitle">{{'Passenger Details'|translate}}</h2>

            <div>
                <span class="error-txt" [style.display]="submitted && invalidForm ? 'block' : ''">
                    {{ 'Please fill in all fields for this passenger' | translate:'documents' }}
                </span>
            </div>

            <div #passengersBox>
                <ng-container *ngFor="let pax of passengers">
                    <ng-container
                        *ngTemplateOutlet="!pax.readonlyDetails ? passengerDetails : readonlyDetails; context:{pax: pax}"></ng-container>
                </ng-container>
            </div>

            <ng-container *ngIf="infants">
                <ng-container *ngFor="let inft of infants">
                    <ng-container
                        *ngTemplateOutlet="!inft.readonlyDetails ? infantDetails : readonlyDetails; context:{pax: inft}"></ng-container>
                </ng-container>
            </ng-container>

            <!-- contact -->
            <check-in-pax-contact-details [(ngModel)]="contact" [firstPaxName]="this.passengers[0].name"
                [titleItems]="titleItems" [countries]="countries"></check-in-pax-contact-details>
        </div>

    </div>
</check-in-form-wrapper>

<ng-template #passengerDetails let-pax="pax">
    <div class="pax-container" ngForm="passengerDocsForm" #passengerDocsForm="ngForm">
        <div class="pax-container_title pax-container-{{pax.passengerNumber}}">
            {{pax.displayName}}
        </div>
        <div *ngIf="pax.info.umSsrResource" class="UMinfoDisplay">
            <div class="UMinfoDisplay-text">{{pax.info.umSsrResource | translate:'passengers'}}</div>
            <button *ngIf="!bookingSnapshot" type="button" class="remove-selected" (click)="removeUmSsr(pax)">
                <span class="font-ico-close-menu">
                    <span class="sr-only">
                        {{'Click to remove selected UM' | translate:'passengers' }}
                    </span>
                </span>
            </button>
        </div>
        <br />
        <div class="pax-container_row">
            <!-- <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="title-{{pax.passengerNumber}}">{{ 'Title' | translate:'documents' }}</label>
                    <blue-select-list [(ngModel)]="pax.name.title" (ngModelChange)="titleChanged($event, pax)" [items]="pax.typeInfo.paxType && pax.typeInfo.paxType.substr(0,3) === 'ADT' ? adultTitleItems : titleItems" [disabled]="pax.typeInfo.paxType  && pax.typeInfo.paxType.substr(0,3) !== 'ADT'"
                        [enableSearch]="false" required placeholder="{{ 'Select title' | translate:'documents' }}" [my-error]="title.dirty" name="title" #title="ngModel"></blue-select-list>
                </div>
            </div> -->
        </div>
        <div class="pax-container_row">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="firstName-{{pax.passengerNumber}}">{{ 'First Name' | translate:'documents' }}</label>
                    <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="first-name"
                        id="firstName-{{pax.passengerNumber}}" [(ngModel)]="pax.name.first" name="firstName"
                        [my-error]="firstName.dirty" patternError="{{ 'Only letters' | translate:'documents' }}"
                        required placeholder="{{ 'e.g. Ionescu' | translate:'documents' }}" pattern="{{namePattern}}"
                        maxlength="32" #firstName="ngModel"
                        (ngModelChange)="pax.passengerNumber === 0 && firstPaxChanged()" />
                </div>
            </div>
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="lastName-{{pax.passengerNumber}}">{{ 'Last Name' | translate:'documents' }}</label>
                    <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="last-name"
                        id="last-{{pax.passengerNumber}}" [(ngModel)]="pax.name.last"
                        (ngModelChange)="pax.passengerNumber === 0 && firstPaxChanged()" name="lastName"
                        [my-error]="lastName.dirty" patternError="{{ 'Only letters' | translate:'documents' }}" required
                        placeholder="{{ 'e.g. Ion' | translate:'documents' }}" pattern="{{namePattern}}" maxlength="32"
                        #lastName="ngModel" />
                </div>
            </div>
        </div>
        <div class="pax-container_row">
            <!-- <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="gender-{{pax.passengerNumber}}">{{ 'Gender' | translate:'documents' }}</label>
                    <blue-select-list (ngModelChange)="genderChanged($event, pax)" [(ngModel)]="pax.info.gender" [items]="genderItems" [enableSearch]="false" [my-error]="gender.dirty" required placeholder="{{ 'Select gender' | translate:'documents' }}" name="gender" #gender="ngModel"></blue-select-list>
                </div>
            </div> -->
            <!-- <div class="pax-container_2-col">
                <div class="field-inner">
                    <label>{{ 'Nationality' | translate:'documents' }}</label>
                    <blue-select-list [(ngModel)]="pax.info.nationality" [items]="countries" [enableSearch]="true" [my-error]="nationality.dirty" required placeholder="{{ 'Select country' | translate:'documents' }}" name="issueCountryNationality" #nationality="ngModel" (ngModelChange)="showUMnotification(pax)"></blue-select-list>
                </div>
            </div> -->
        </div>

        <div class="pax-container_row">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="birth-date-{{pax.passengerNumber}}">{{ 'Date Of Birth' | translate:'documents'
                        }}</label>
                    <blue-date-picker name="dateOfBirth" [(ngModel)]="pax.typeInfo.dateOfBirth"
                        (ngModelChange)="showUMnotification(pax)" [elementName]="'dateOfBirth'"
                        [dateRange]='dateRanges[pax.typeInfo.paxType]' [dateFormat]="dateFormat" required
                        [my-error]="dateOfBirth.dirty" #dateOfBirth="ngModel">
                    </blue-date-picker>
                </div>
            </div>
            <div *ngIf="isAgent" class="pax-container_2-col">
                <div class="field-inner">
                    <label for="paxPhoneNumber-{{pax.address.phoneNumber}}">{{ 'Phone Number' | translate:'documents'
                        }}</label>
                    <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="paxPhoneNumber"
                        id="paxPhoneNumber-{{pax.address.phoneNumber}}" [(ngModel)]="pax.address.phoneNumber"
                        name="paxPhoneNumber" [my-error]="paxPhoneNumber.dirty"
                        patternError="{{ 'Invalid phone number' | translate:'documents' }}" required
                        placeholder="{{ 'Phone Number' | translate:'documents' }}" pattern="{{phonePattern}}"
                        #paxPhoneNumber="ngModel" />
                </div>
            </div>
        </div>

        <div class="pax-container_row" *ngIf="isSicilyResident">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="residenceCity-{{pax.passengerNumber}}">{{ 'Residence City' | translate:'documents'
                        }}</label>
                    <input type="text" class="paxPhoneNumber" id="residenceCity-{{pax.passengerNumber}}"
                        [(ngModel)]="pax.address.city" name="residenceCity" [my-error]="paxResidenceCity.dirty" required
                        placeholder="{{ 'Residence City' | translate:'documents' }}" #paxResidenceCity="ngModel" />
                </div>
            </div>
            <!-- <div class="pax-container_2-col">
                <div class="field-inner">
                    <div class="label">{{ 'Document type' | translate:'documents' }}</div>
                    <blue-select-list [(ngModel)]="pax.docTypeCode" [items]="documentTypes" [enableSearch]="true"
                        required placeholder="{{ 'Select type' | translate:'documents' }}"
                        name="documentType"></blue-select-list>
                </div>
            </div> -->
            <!-- <div class="pax-container_2-col">
                <div class="field-inner">
                    <label>{{ 'Nationality' | translate:'documents' }}</label>
                    <blue-select-list [(ngModel)]="pax.info.nationality" [items]="countries" [enableSearch]="true"
                        [my-error]="nationality.dirty" required
                        placeholder="{{ 'Select country' | translate:'documents' }}" name="issueCountryNationality"
                        #nationality="ngModel" (ngModelChange)="showUMnotification(pax)"></blue-select-list>
                </div>
            </div> -->
        </div>

        <div *ngIf="isSicilyResident">
            <ng-container
                *ngTemplateOutlet="pax.readonlyDocuments ? readonlyDocuments : passengerDocuments; context:{pax: pax}"></ng-container>
        </div>

        <div *ngIf="isAgent" class="pax-container_row">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="pax-email-contact-{{pax.address.emailAddress}}">{{ 'Email address' |
                        translate:'documents' }}</label>
                    <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="email-city"
                        id="pax-email-contact" [(ngModel)]="pax.address.emailAddress" name="pax-email-contact"
                        [my-error]="paxEmailContact.dirty"
                        patternError="{{ 'Invalid email address' | translate:'documents' }}" required
                        placeholder="{{ 'Email address' | translate:'documents' }}" pattern="{{emailPattern}}"
                        maxlength="266" #paxEmailContact="ngModel" />
                </div>
            </div>
        </div>

        <input id="special-assistence-{{pax.displayName}}" type="checkbox" [ngModelOptions]="{standalone: true}"
            [(ngModel)]="pax.specialAssistance" />
        <label class="specialAssistance_label" for="special-assistence-{{pax.displayName}}">
            <span class="checkinbox specialAssistance">
                {{'Special Assistance' | translate: 'passengers'}}
            </span>
        </label>

        <div class="pax-wrapper_specialAssistance" *ngIf="pax.specialAssistance">
            <div class="pax-container_specialAssistance">
                <div class="pax-container_title">
                    {{'Special Assistance' | translate: 'passengers'}}
                </div>
                <div class="pax-container_subtitle">
                    {{'Please indicate which services you require on your flight(s)' | translate: 'passengers'}}
                </div>
                <check-in-accordion>
                    <ng-container *ngFor="let flight of flights">
                        <check-in-single-passenger-special-assistance #singlePassengerSA
                            (saveChangesEvent)='saveChanges($event)' [passengerNumber]="pax.passengerNumber"
                            [flight]="flight" [isSSRInfoSet]="isSSRInfoSet">
                        </check-in-single-passenger-special-assistance>
                    </ng-container>
                </check-in-accordion>
                <blue-loading-spinner [id]="getSpinnerId(pax.passengerNumber)"></blue-loading-spinner>
            </div>
        </div>
        <!-- <button class="checkinbox" (click)="toggle(pax,1)">{{'Travel documents' | translate: 'passengers'}}</button> -->


        <div *ngIf="pax.addDocuments">
            <ng-container
                *ngTemplateOutlet="pax.readonlyDocuments ? readonlyDocuments : passengerDocuments; context:{pax: pax}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #infantDetails let-pax="pax">
    <div class="pax-container" ngForm="passengerDocsForm" #passengerDocsForm="ngForm">
        <div class="pax-container_title pax-container-{{pax.passengerNumber}}">
            {{pax.displayName}}
        </div>
        <div class="pax-container_row">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="firstName-infant-{{pax.attachedPassengerNumber}}">{{ 'First Name' |
                        translate:'documents' }}</label>
                    <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="first-name"
                        id="firstName-infant-{{pax.attachedPassengerNumber}}" [(ngModel)]="pax.name.first"
                        name="firstName-indant" required placeholder="{{ 'e.g. Ionescu' | translate:'documents' }}"
                        [my-error]="infantFirstName.dirty" patternError="{{ 'Only letters' | translate:'documents' }}"
                        pattern="{{namePattern}}" #infantFirstName="ngModel" />
                </div>
            </div>
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <label for="lastname-infant-{{pax.attachedPassengerNumber}}">{{ 'Last Name' | translate:'documents'
                        }}</label>
                    <input type="text" [disabled]="isRestricted || isCanceledBookingStatus" class="first-name"
                        id="lastname-infant-{{pax.attachedPassengerNumber}}" [(ngModel)]="pax.name.last"
                        name="lastname-infant" required placeholder="{{ 'e.g. Ion' | translate:'documents' }}"
                        pattern="{{namePattern}}" [my-error]="infantLastName.dirty"
                        patternError="{{ 'Only letters' | translate:'documents' }}" #infantLastName="ngModel" />
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #readonlyDetails let-pax="pax">
    <div class="pax-container" ngForm="passengerDocsForm" #passengerDocsForm="ngForm">
        <div class="pax-container_title">
            {{pax.displayName}}
        </div>

        <div class="pax-container_row">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <div class="readonly-label">{{ 'Title' | translate:'documents' }}</div>
                    <div class="readonly-text">{{pax.name.title}}</div>
                </div>
            </div>
        </div>
        <div class="pax-container_row">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <div class="readonly-label">{{ 'First Name' | translate:'documents' }}</div>
                    <div class="readonly-text">{{pax.name.first}}</div>
                </div>
            </div>
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <div class="readonly-label">{{ 'Last Name' | translate:'documents' }}</div>
                    <div class="readonly-text">{{pax.name.last}}</div>
                </div>
            </div>
        </div>
        <div class="pax-container_row">
            <div class="pax-container_2-col" *ngIf="pax.info">
                <div class="field-inner">
                    <div class="readonly-label">{{ 'Gender' | translate:'documents' }}</div>
                    <div class="readonly-text">{{getText(genderItems, pax.info.gender) | translate: 'documents'}}</div>
                </div>
            </div>
            <!-- <div class="pax-container_2-col">
                <div class="field-inner">
                    <div class="readonly-label">{{ 'Nationality' | translate:'documents' }}</div>
                    <div class="readonly-text" *ngIf="pax.info">{{getText(countries, pax.info.nationality)}}</div>
                    <div class="readonly-text" *ngIf="pax.nationality">{{getText(countries, pax.nationality)}}</div>
                </div>
            </div> -->
            <div class="pax-container_2-col" *ngIf="!pax.typeInfo">
                <div class="field-inner">
                    <div class="readonly-label">{{ 'Date Of Birth' | translate:'documents' }}</div>
                    <div class="readonly-text">{{pax.dateOfBirth | customDate: 'fulldateyear'}}</div>
                </div>
            </div>
        </div>
        <div class="pax-container_row" *ngIf="pax.typeInfo">
            <div class="pax-container_2-col">
                <div class="field-inner">
                    <div class="readonly-label">{{ 'Date Of Birth' | translate:'documents' }}</div>
                    <div class="readonly-text">{{pax.typeInfo.dateOfBirth| customDate: 'fulldateyear'}}</div>
                </div>
            </div>
        </div>

        <button class="checkinbox specialAssistance" (click)="toggle(pax,0)" *ngIf="pax.typeInfo">{{'Special Assistance'
            | translate: 'passengers'}}</button>

        <div *ngIf="pax.specialAssistance && pax.typeInfo">
            <div class="pax-container_specialAssistance">
                <div class="pax-container_title">
                    {{'Special Assistance' | translate: 'passengers'}}
                </div>
                <div class="pax-container_subtitle">
                    {{'Please indicate which services you require on your flight(s)' | translate: 'passengers'}}
                </div>
                <check-in-accordion>
                    <ng-container *ngFor="let flight of flights">
                        <check-in-single-passenger-special-assistance #singlePassengerSA
                            [passengerNumber]="pax.passengerNumber" [flight]="flight" [isSSRInfoSet]="isSSRInfoSet">
                        </check-in-single-passenger-special-assistance>
                    </ng-container>
                </check-in-accordion>
            </div>
            <div class="checkin-total-options-bar">
                <blue-loading-spinner [id]="getSpinnerId(pax.passengerNumber)"></blue-loading-spinner>
                <button type="button" class="btn btn-primary"
                    attr.aria-label="{{ 'Save Changes' | translate:'extras' }}" (click)="saveChanges(pax)">{{ 'Save
                    Changes' | translate:'extras' }}</button>
            </div>
        </div>
        <button class="checkinbox" (click)="toggle(pax,1)" *ngIf="pax.typeInfo">{{'Travel documents' | translate:
            'passengers'}}</button>

        <div *ngIf="pax.addDocuments && pax.typeInfo">
            <ng-container
                *ngTemplateOutlet="pax.readonlyDocuments ? readonlyDocuments : passengerDocuments; context:{pax: pax}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #passengerDocuments let-pax="pax">
    <div class="pax-container_row">
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label>{{ 'Document type' | translate:'documents' }}</label>
                <blue-select-list [(ngModel)]="pax.travelDocument.docTypeCode" [items]="documentTypes"
                    *ngIf="!isSicilyResident" [enableSearch]="true" required
                    placeholder="{{ 'Select type' | translate:'documents' }}" [my-error]="documentType.dirty"
                    name="documentType" #documentType="ngModel">
                </blue-select-list>
                <input *ngIf="isSicilyResident" type="text" [disabled]="true" [ngModel]="getText(documentTypes,
                    pax.travelDocument.docTypeCode)" />

            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label for="doc-number-{{pax.passengerNumber}}">{{ 'Document number' | translate:'documents' }}</label>
                <input type="text" class="document-number" id="doc-number-{{pax.passengerNumber}}"
                    [(ngModel)]="pax.travelDocument.docNumber" name="documentNumber" [my-error]="documentNumber.dirty"
                    required placeholder="{{ 'e.g. 2569-782312-34' | translate:'documents' }}"
                    #documentNumber="ngModel" />
            </div>
        </div>
    </div>
    <div class="pax-container_row">
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label>{{ 'Issue country' | translate:'documents' }}</label>
                <blue-select-list [(ngModel)]="pax.travelDocument.issuedByCode" [items]="countries"
                    [enableSearch]="true" required placeholder="{{ 'Select country' | translate:'documents' }}"
                    name="issueCountry" [my-error]="issueCountryDoc.dirty" documentNumber="issueCountry"
                    #issueCountryDoc="ngModel"></blue-select-list>
            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label for="issue-date-{{pax.passengerNumber}}">{{ 'Issue date' | translate:'documents' }}</label>
                <blue-date-picker name="issueDate" [(ngModel)]="pax.travelDocument.issuedDate"
                    [elementName]="'issueDate'" [dateRange]='issueDateRange' [dateFormat]="dateFormat"
                    [my-error]="issueDateRange.dirty" #issueDate="ngModel" required></blue-date-picker>
            </div>
        </div>
    </div>
    <div class="pax-container_row">
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label for="expiry-date-{{pax.passengerNumber}}">{{ 'Expiry date' | translate:'documents' }}</label>
                <blue-date-picker name="expirationDatePicker" [(ngModel)]="pax.travelDocument.expirationDate"
                    [elementName]="'expiryDate'" [dateRange]='expirationDateRange' [dateFormat]="dateFormat"
                    [my-error]="expiryDate.dirty" #expiryDate="ngModel" required></blue-date-picker>

            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <label>{{ 'Birth country' | translate:'documents' }}</label>
                <blue-select-list [(ngModel)]="pax.travelDocument.birthCountry" [items]="countries"
                    [enableSearch]="true" required placeholder="{{ 'Select country' | translate:'documents' }}"
                    name="birthCountry" [my-error]="birthCountry.dirty" #birthCountry="ngModel"></blue-select-list>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #readonlyDocuments let-pax="pax">
    <div class="pax-container_row">
        <div class="pax-container_2-col">
            <div class="field-inner">
                <div class="readonly-label">{{ 'Document type' | translate:'documents' }}</div>
                <div class="readonly-text">{{getText(documentTypes, pax.travelDocument.docTypeCode)}}</div>
            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <div class="readonly-label">{{ 'Document number' | translate:'documents' }}</div>
                <div class="readonly-text">{{pax.travelDocument.docNumber}}</div>
            </div>
        </div>
    </div>
    <div class="pax-container_row">
        <div class="pax-container_2-col">
            <div class="field-inner">
                <div class="readonly-label">{{ 'Issue country' | translate:'documents' }}</div>
                <div class="readonly-text">{{getText(countries, pax.travelDocument.issuedByCode)}}</div>
            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <div class="readonly-label">{{ 'Issue date' | translate:'documents' }}</div>
                <div class="readonly-text">{{pax.travelDocument.issuedDate| customDate: 'fulldateyear'}}</div>
            </div>
        </div>
    </div>
    <div class="pax-container_row">
        <div class="pax-container_2-col">
            <div class="field-inner">
                <div class="readonly-label">{{ 'Expiry date' | translate:'documents' }}</div>
                <div class="readonly-text">{{pax.travelDocument.expirationDate | customDate: 'fulldateyear'}}</div>
            </div>
        </div>
        <div class="pax-container_2-col">
            <div class="field-inner">
                <div class="readonly-label">{{ 'Birth country' | translate:'documents' }}</div>
                <div class="readonly-text">{{getText(countries, pax.travelDocument.birthCountry)}}</div>
            </div>
        </div>
    </div>
</ng-template>

<seat-selected-notification>
    <ng-container *ngIf="pendingSsrsList.length">
        <div class="section-SubTitle">{{'The following options have been selected, but not added to cart:' | translate:
            'seat'}}</div>
        <div class="section-text">
            <ul class="pending-list">
                <ng-container *ngFor="let ssrType of pendingSsrsList">
                    <ng-container [ngSwitch]="ssrType">
                        <li *ngSwitchCase="SsrTypeEnum.BundleUpgrade"><span class="font-ico-tag ssr-icon"></span>
                            <div class="text">{{'Bundle upgrade' | translate: 'extras'}}</div>
                        </li>
                        <li *ngSwitchCase="SsrTypeEnum.SpecialAssistance"><span
                                class="font-ico-spec-assistance ssr-icon"></span>
                            <div class="text">{{'Special assistance' | translate: 'extras'}}</div>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>
    </ng-container>
</seat-selected-notification>

<check-in-ssrs-seats-removal-notification #seatRemovalModal [ssrType]="SsrTypeEnum.SpecialAssistance">
</check-in-ssrs-seats-removal-notification>