import { AbstractControl, ValidatorFn } from '@angular/forms';

export class AeroInvoicingValidators {
    public static alphaNumericFix(noDigits: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            // const pattern = new RegExp(`/^[A-Za-z0-9]*$`, "i");

            const forbidden = !/^[A-Za-z0-9]+$/i.test(control.value);

            return (forbidden || control.value.length !== noDigits) ? { alphaNumericFix: { value: control.value, noDigits } } : null;
        };
    }
    public static alphanumeric(allowSpaces: boolean = true): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }

            const forbidden = allowSpaces ?
                !/^[A-Za-z0-9]+[A-Za-z0-9 ]*$/i.test(control.value) :
                !/^[A-Za-z0-9]+$/i.test(control.value);

            return forbidden ? { alphanumeric: { value: control.value } } : null;
        };
    }

    public static headquarterZipCode(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const forbidden = /^[0-9]+$/i.test(control.value) && control.value.length === 5;

            return forbidden ? null: { headquarterZipCode: { value: control.value } };
        };
    }

    public static taxno(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const forbidden =
                !/^[-A-Za-z0-9]+$/i.test(control.value);

            return forbidden ? { taxno: { value: control.value } } : null;
        };
    }

    public static pecItEmail(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const x = !/^[a-zA-Z0-9._%+-]+@pec\.it$/i.test(control.value);
            return x ? { pecEmail: { value: control.value } } : null;
        };
    }

    public static phoneNumber(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const x = !/^[0-9+()\/-][0-9+() \/-]{0,19}?$/i.test(control.value);
            return x ? { phonenumber: { value: control.value } } : null;
        };
    }

    public static emailAddress(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const x = !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(control.value);
            return x ? { emailaddress: { value: control.value } } : null;
        };
    }

    public static address(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (!control.value) {
                return null;
            }
            const forbidden = !/^[A-Za-z0-9]+[A-Za-z0-9, ]*$/i.test(control.value);

            return forbidden ? { address: { value: control.value } } : null;
        };

    }
    public static vat(isNonItalian: boolean = false): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
          const value = control.value;
          if (value === null || value === '') {
            return null;
          }
          const x = /^[-A-Za-z0-9]+$/i.test(value) && 
           (isNonItalian? 
            value.length === 11 : //NO ITALIAN VAT has 11 digits
            (value.length >= 11 && value.length <= 16)); // ITALIAN VAT has between 11 and 16 digits
          return x ? null :{ vat: { value: control.value, isNonItalian } };
        };
      }

    public static customRequired(defaultValue: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control.value !== defaultValue) {
                return null;
            }

            return { required: true };
        };
    }
}
