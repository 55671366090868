import { ConfigService } from './../core/config.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class InsuranceService {

  constructor(private http: HttpClient, private configService: ConfigService) { }

  getInsuranceDetails() {
    return this.http.get(this.configService.InsuranceDetails).toPromise();
  }

  sellInsurance(postObj) {
    return this.http.post(this.configService.SellFee, postObj).toPromise();
  }

  deleteInsurance(passengerNumber: number, feeCode: string, feeNumber: number) {
    return this.http.delete
    (this.configService.SellFee + `?passengerNumber=${passengerNumber}&feeNumber=${feeNumber}&feeCode=${feeCode}`)
      .toPromise();
  }
}


