<check-in-form-wrapper [ariaLabel]="ariaLabel" [step]="'itinerary'" [showContinue]="false" [showPriceDisplay]="false">
  <ng-container ngProjectAs="alerts">
    <div class="alert-holder alert-info" *ngIf="emailSent">
      <div class="alert-item secondary">
        <h4>
          {{ 'Info' | translate: 'alerts' }}
        </h4>
        <ul>
          <li>
            {{ 'A confirmation email has been sent to your inbox' | translate: 'alerts' }}
          </li>
        </ul>
      </div>
    </div>
  </ng-container>
  <div class="form-wrapper-sidebar">
    <div class="transferParking_summary" *ngIf="false">
      <div class="">

        <a target="_blank" href="{{ getAirportTransferURL() }}">
          test
          <img src="https://static.blueairweb.com/OnlineBookingAdvertising/BusTransfer/280-150-en.jpg" />
        </a>
      </div>
      <div class="" *ngIf="false">
        <a href="{{ getAirportParkingURL() }}">
          <img src="https://static.blueairweb.com/OnlineBookingAdvertising/Parking/Blue-Air-280x150-en.jpg" />
        </a>
      </div>
    </div>
    <a routerLink="/summary" class="btn btn-primary itineraryBox_paymentDue_addPayment"
      *ngIf="!isOnLockFareFlow && paymentDue > 0">
      {{'Make payment'|translate:'itinerary'}}
    </a>
  </div>
  <div class="form-wrapper-sidebar">

  </div>
  <div class="form-wrapper-middle-body">
    <h1 *ngIf="firstArrivalStation !== ''">{{"Your flight to
      {0}!"|translate:"itinerary":[firstArrivalStation|translate:'station.name']}}</h1>
    <h1 *ngIf="firstArrivalStation === ''">{{"There is no flight in the nearest future" | translate: 'itinerary'}}</h1>
    <h2 *ngIf="isCorporateBundle">{{'Please go to agencyportal and use your login to change this booking.' | translate:
      'itinerary'}}</h2>

    <check-in-accordion class="itineraryBox_paymentDue" *ngIf="paymentDue > 0">
      <check-in-accordion-item title="{{'Payment Due:'|translate:'itinerary'}} {{paymentDue | currencyDisplay}}"
        *ngIf="!isOnLockFareFlow">
        <div class="itineraryBox_paymentDue_details">
          <div class="info">
            <div class="title" *ngFor="let contact of contacts">{{'Dear {0},' | translate:'itinerary':contact.name.title
              +' ' +
              contact.name.first +' ' + contact.name.last}}</div>
            <div class="text">{{'Thank you for choosing Blue Air!' | translate:'itinerary'}}</div>

            <ng-container *ngIf="paymentAccounts.length">
              <div class="title">{{'Chosen method:' | translate:'itinerary'}}</div>
              <div class="text">
                {{ 'For payments made through {0}, you have 72 hours to make sure that the payment of your reservation
                is made in the Blue Air Aviation accounts opened with:' |
                translate:'itinerary':[selectedPaymentTypeDescription | translate:'itinerary'] }}
              </div>
              <div class="title">{{'Bank references:' | translate:'itinerary'}}</div>
              <div class="text">
                <ul class="bank-ref" *ngFor="let paymentAccount of paymentAccounts">
                  <li><label>{{ 'Name of the bank:' | translate:'itinerary'
                      }}</label><span>{{paymentAccount.name}}</span></li>
                  <li><label>{{ 'IBAN/Account number:' | translate: 'itinerary'
                      }}</label><span>{{paymentAccount.accountNumber}}</span></li>
                  <li><label>{{ 'BIC' | translate:'itinerary' }}</label><span>{{paymentAccount.bIC}}</span></li>
                </ul>
              </div>
            </ng-container>

          </div>
          <div class="passengers">
            <div class="title">{{'Passengers:' | translate:'itinerary'}}</div>
            <ul *ngFor="let pax of passengers">
              <li>{{pax.name.title+ ' ' + pax.name.first +' ' + pax.name.last}}</li>
            </ul>
          </div>
          <div class="footer">

            <div class="warning" *ngIf="paymentAccounts.length">
              {{'If your payment will not be made in due time, the reservation may be canceled.' |
              translate:'itinerary'}}
            </div>

            <div class="printButton_wrap" *ngIf="paymentAccounts.length">
              <button type="button" class="btn" (click)="print()">
                <img [src]="assetsPath + 'svg/printer.svg'">
                <span class="printButton_text">
                  {{'Print'|translate:'itinerary'}}
                </span>
              </button>
            </div>
            <div class="total">{{'Payment Due:'|translate:'itinerary'}} <strong>{{paymentDue |
                currencyDisplay}}</strong></div>
          </div>
        </div>
      </check-in-accordion-item>

      <check-in-accordion-item title="{{'Payment Due:'|translate:'itinerary'}} {{paymentDue | currencyDisplay}}"
        *ngIf="isOnLockFareFlow" [blockExpanded]="true">
        <div class="itineraryBox_paymentDue_details">
          <div class="info">
            <div class="title" *ngFor="let contact of contacts">{{ 'The fare is locked until {0}.' | translate:
              'itinerary' : lockedFarePaymentDueDate.format("DD MMM YYYY, HH:mm") }}</div>
            <div class="text" innerHTML="{{'Locked-Fare-Payment-Box-Paragraph-1' | translate:'itinerary'}}"></div>
            <div class="text" innerHtml="{{'Locked-Fare-Payment-Box-Paragraph-2' | translate:'itinerary'}}"></div>
          </div>
        </div>
        <div class="btn_wrap">
          <a routerLink="/extras" class="btn btn-primary">
            {{'Finalize booking'|translate:'itinerary'}}
          </a>
        </div>
      </check-in-accordion-item>
    </check-in-accordion>

    <div class="itineraryBox_paymentDue_wrapper">
      <a routerLink="/summary" class="btn btn-primary itineraryBox_paymentDue_addPayment"
        *ngIf="!isOnLockFareFlow && paymentDue > 0">
        {{'Make payment'|translate:'itinerary'}}
      </a>
    </div>

    <!-- start blue benefits -->
    <!-- <div class="itineraryBox_bookingSummary topBlueBorder" *ngIf="false">
      <div class="info">
        <div class="title">{{ 'thankyou.title' | translate:'rainbow.bookingflow':[bbActivationInfo.promoId +
          '.promo.title' | translate:'rainbow.header'] }}</div>
        <div class="text"
          innerHtml="{{ bbActivationInfo.promoId + '.promo.upsellDescription' | translate: 'rainbow.upsell' }}">
        </div>

      </div>
      <div class="btn_wrap"><a class="btn btn-primary" href="{{bbActivationInfo.url}}">{{ 'thankyou.activatebtn' |
          translate: 'rainbow.bookingflow' }}</a></div>
    </div> -->
    <!-- end blue benefits -->
    <div class="itineraryBox_bookingSummary">
      <div class="itineraryBox_bookingSummary_left">
        <div class="itineraryBox_bookingSummary_left_display_flex">
          <div class="itineraryBox_bookingSummary_left_display_flex_left">
            <div class="itineraryBox_bookingSummary_title">{{"{0} to
              {1}"|translate:'itinerary':[getStation(0,fistJourney)|translate:'station.name']:[getStation(1,fistJourney)|translate:'station.name']}}
            </div>
            <div class="itineraryBox_bookingSummary_subtitle">{{getFlightDates()}}</div>
            <div class="itineraryBox_bookingSummary_buttonWrapper">
              <button type="button" class="itineraryBox_bookingSummary_printButton" (click)="print()">
                <img [src]="assetsPath + 'svg/printer.svg'">
                <span class="itineraryBox_bookingSummary_buttonText">
                  {{'Print'|translate:'itinerary'}}
                </span>
              </button>
            </div>
          </div>
          <div class="itineraryBox_bookingSummary_left_display_flex_right">
            <div class="itineraryBox_bookingSummary_recordLocator">
              <div class="itineraryBox_bookingSummary_recordLocator_label">{{'Booking reference'|translate}}</div>
              <div class="itineraryBox_bookingSummary_font_size_45px">
                {{ recordLocator }}
              </div>
              <div *ngFor="let code of recordCodes" class="itineraryBox_bookingSummary_recordLocator_third_party">
                {{ code }}
              </div>
              <div *ngIf="flightStatusAffectedMessage && flightStatusAffectedMessage.length > 0">
                {{flightStatusAffectedMessage}}</div>
              <div *ngIf="isBookingOnHold()" class="itineraryBox_bookingSummary_recordLocator_pending">
                <p>{{'pending payments'|translate:'itinerary'}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-blueair-products *ngIf="false"></app-blueair-products>

    <accomodation *ngIf="false"></accomodation>
    <car-hire *ngIf="false"></car-hire>

    <div class="itineraryBox_wrapper">
      <div class="itineraryBox_title">
        <h2>
          {{'Flights'|translate:'itinerary'}}
        </h2>

        <a *ngIf="voucherRequest" class="itineraryBox_modifyBtn alternative" (click)="requestVoucher()">
          {{'Request voucher'|translate:'itinerary'}}
        </a>

        <a *ngIf="!isOnLockFareFlow && !multiJourney && !hideModifyPaxFlights && !isCorporateBundle"
          class="itineraryBox_modifyBtn" (click)="changeBooking(ChangeBooking.flight)">
          <span *ngIf="moveFlight">
            {{'Move'|translate:'itinerary'}}
          </span>
          <span *ngIf="!moveFlight">
            {{'Modify'|translate:'itinerary'}}
          </span>
        </a>
      </div>

      <div>
        <div *ngIf="multiJourney" class="multiJourneyInfo">
          {{'Please contact your seller in order to modify these flights!' | translate:'itinerary'}}
        </div>

        <div class="flightSummary_wrapper" *ngFor="let journey of journeys; let i = index">
          <div class="flightSummary_flightInfo">
            <div *ngIf="!isJourneyChangeAllowed(journey)" class="journeyNAChange">
              {{ 'The departure date exceeded the allowed time limit to change this journey!' | translate:'itinerary' }}
            </div>

            <div class="flightSummary_originDestination">
              <div *ngIf="changeDateActive; else test" [ngClass]="{'change-flight':isJourneyChangeAllowed(journey)}">
                <input *ngIf="isJourneyChangeAllowed(journey) && !isRestricted" type="checkbox"
                  id="{{getStation(0,journey)|translate:'station.name'}}-{{getStation(1,journey)|translate:'station.name'}}"
                  (change)="changeThisFlight(journey, i, $event)" />
                <label
                  for="{{getStation(0,journey)|translate:'station.name'}}-{{getStation(1,journey)|translate:'station.name'}}"></label>
                <span [ngClass]="{'withCheckbox':isJourneyChangeAllowed(journey)}" class="flightSummary_origin ">
                  {{ getStation(0,journey)|translate:'station.name' }}
                </span>

                <span>
                  —
                </span>

                <span class="flightSummary_destination"> {{getStation(1,journey)|translate:'station.name' }}
                </span>
              </div>

              <ng-template #test>
                <span class="flightSummary_origin">
                  {{getStation(0,journey)|translate:'station.name'}}
                </span>

                <span>
                  —
                </span>

                <span class="flightSummary_destination">
                  {{ getStation(1,journey)|translate:'station.name' }}
                </span>
              </ng-template>
            </div>

            <div class="flightSummary_date">
              {{ formatDate(journey.segments[0].sTD,'dddd, DD MMM YYYY' )}}
            </div>
          </div>

          <div class="flightSummary_flightDetails">
            <div class="flightSummary_flightDesigantors">
              <div class="flightSummary_flightDesigantor" *ngFor="let segment of journey.segments">
                <img [src]="assetsPath + 'img/' + segment.flightDesignator.carrierCode + '.png'">

                <div class="flightSummary_flightNumber">
                  {{ segment.flightDesignator.carrierCode }} {{ segment.flightDesignator.flightNumber }}
                  <ng-container *ngFor="let leg of segment.legs">
                    <span *ngIf="leg.legInfo.codeShareIndicator === 'L'">
                      *
                    </span>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="flightSummary_flightSegments">
              <div class="flightSummary_flightSegment" *ngFor="let segment of journey.segments; index as i">
                <ng-container *ngFor="let leg of segment.legs">
                  <div class="flightSummary_leftDetails">
                    <span class="flightSummary_location">
                      {{ segment.departureStation }}
                    </span>

                    <time class="flightSummary_timeBig">
                      {{ formatDate(segment.sTD,'HH:mm') }}
                    </time>
                  </div>

                  <div class="flightSummary_middleDetails">
                    <span class="font-ico-plane-runway">
                    </span>

                    <span *ngIf="leg.legInfo.status === 'Canceled'" class="flightSummary_canceled">
                      {{ "CANCELED" | translate: 'itinerary' }}
                    </span>

                    <time *ngIf="leg.legInfo.status !== 'Canceled'" class="flightSummary_duration">
                      {{ subtractTimezoneOffset(segment.sTD, segment.legs[0].legInfo.deptLTV) |
                      duration:subtractTimezoneOffset(segment.sTA, segment.legs[segment.legs.length -
                      1].legInfo.arrvLTV):false }}
                    </time>
                  </div>

                  <div class="flightSummary_rightDetails">
                    <span class="flightSummary_location">
                      {{ segment.arrivalStation }}
                    </span>

                    <time class="flightSummary_timeBig">
                      {{ formatDate(segment.sTA,"HH:mm") }}
                    </time>
                  </div>

                  <div class="operating-carier-details"
                    *ngIf="i === journey.segments.length - 1  && isNextDay(journey.segments[0].sTA, journey.segments[journey.segments.length - 1].sTD)">
                    {{ "Arrives next day!" | translate }}
                  </div>

                  <div class="operating-carier-details" *ngIf="leg.legInfo.codeShareIndicator === 'L'">
                    *{{ 'Operated by {0} as {1}' | translate: '':[ leg.legInfo.operatingCarrier | translate:
                    'operatingcarrier.name' ] : leg.legInfo.operatingCarrier + leg.legInfo.operatingFlightNumber }}
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="connection-detail" *ngIf="journey.segments.length > 1">
              {{ 'Connection' | translate: 'itinerary' }}
            </div>
          </div>
        </div>

        <div class="flight-change" *ngIf="changeDateActive && changeFlight.departureStation">
          <ng-container *ngIf="moveFlight else changeFlightTemplate">
            <check-in-move-flight [departureStation]="changeFlight.departureStation"
              [arrivalStation]="changeFlight.arrivalStation" [tripType]="changeFlight.journeyType"
              [inbound]="changeFlight.inboundDate" [outbound]="changeFlight.outboundDate"
              [isForSearchingFlight]="false">
            </check-in-move-flight>
          </ng-container>
          <ng-template #changeFlightTemplate>
            <change-flight [outbound]="changeFlight.outboundDate" [inbound]="changeFlight.inboundDate"
              [tripType]="changeFlight.journeyType" [departure]="changeFlight.departureStation"
              [destination]="changeFlight.arrivalStation" [isForChangingFlight]="true"
              [ignoreConnectionErrors]="ignoreConnectionErrors">
            </change-flight>
          </ng-template>
        </div>

      </div>
    </div>

    <div class="itineraryBox_wrapper" *ngIf="airportTransferExternalUrl">
      <div>
        <a [href]="airportTransferExternalUrl">
          <img [src]="CarRentalUrl" alt="Rental Plus" style="
          max-width: 100%;
          max-height: 100%;" />
        </a>
      </div>
    </div>

    <div class="itineraryBox_wrapper transferParking" *ngIf="false">
      <div>
        <div class="transferParking_summary_mobile">
          <div class="">
            <a href="#">
              <img src="https://static.blueairweb.com/OnlineBookingAdvertising/BusTransfer/280-150-en.jpg" />
            </a>
          </div>
          <div class="">
            <a href="http://blueair.parkcloud.com/en-GB">
              <img src="https://static.blueairweb.com/OnlineBookingAdvertising/Parking/Blue-Air-280x150-en.jpg" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="itineraryBox_wrapper" *ngIf="showExtrasOnLockFare">
      <div class="itineraryBox_title">
        <h2>{{'Luggage & Extras'|translate:'itinerary'}}</h2>
        <a *ngIf="!isOnLockFareFlow && !noFlightAvailable && !isCorporateBundle" class="itineraryBox_modifyBtn"
          (click)="changeBooking(ChangeBooking.luggage)">{{'Modify'|translate:'itinerary'}}</a>
      </div>
      <div>
        <ng-container *ngIf="ssrChanges.length === 0 && insuranceChanges.length === 0">
          <div class="extrasSummary_wrapper segm0">
            <div class="extrasSummary_rowData notFound">
              <br />
              <div>{{'No extras found!'|translate:'itinerary'}}</div>
            </div>
          </div>
        </ng-container>
        <div class="" [attr.class]="getSegCount()">
          <div *ngIf="ssrChanges.length > 0 || insuranceChanges && insuranceChanges.length"
            class="extrasSummary_header">
            <div>
              <div> {{ 'Passenger' | translate:'itinerary' }}</div>
              <ng-container *ngFor="let journey of journeys">
                <ng-container *ngFor="let segment of journey.segments">
                  <div>
                    {{ '{0} to {1}' | translate: null : [segment.departureStation | translate: 'station.name'] :
                    [segment.arrivalStation | translate: 'station.name']}}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <!-- ssrs -->
          <ng-container *ngFor="let ssrType of ssrChanges">
            <ng-container>
              <div class="extrasSummary_rowTitle" (click)="toggleVisibility(extrasToggle)">
                {{ ssrType.ssrTypeName | splitcamelcasestring | translate: 'itinerary' }}
              </div>
              <div [ngClass]="{'extrasSummary_mobileToggle': mobileMatches}" #extrasToggle>
                <ng-container *ngFor="let paxSsr of ssrType.passengerSsrsByType">
                  <div class="extrasSummary_rowData">
                    <div>
                      <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">
                        {{'Passenger' | translate:'itinerary' }}
                      </div>
                      <div class="extrasSummary_mobileRight title">
                        <b>
                          {{paxSsr.passenger?.name.title}} {{paxSsr.passenger?.name.first}}
                          {{paxSsr.passenger?.name.last}}
                        </b>
                      </div>
                    </div>
                    <div *ngFor="let ssr of paxSsr.ssrCountOnSegment">
                      <div class="extrasSummary_mobileSegment" *ngIf="mobileMatches && ssr.ssrCount[0].code">
                        <b>
                          {{ '{0} to {1}' | translate: null : [ssr.segment.DepartureStation | translate: 'station.name']
                          : [ssr.segment.ArrivalStation | translate: 'station.name']}}
                        </b>
                      </div>
                      <div *ngFor="let ssrCount of ssr.ssrCount">
                        <!-- if ssr was not on booking before -->
                        <ng-container *ngIf="ssrCount.code else emptyDiv">
                          {{ssrCount.code | translate:'ssr.name'}} x {{ ssrCount.newValue }}
                        </ng-container>
                        <ng-template #emptyDiv></ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <!-- insurance -->
          <ng-container *ngIf="insuranceChanges && insuranceChanges.length">
            <div class="extrasSummary_rowTitle" (click)="toggleVisibility(extrasToggle)">
              {{ 'Insurance' | splitcamelcasestring | translate:'itinerary' }}
            </div>
            <!-- show passengers insurance -->
            <div [ngClass]="{'extrasSummary_mobileToggle': mobileMatches}" #extrasToggle>
              <ng-container *ngFor="let ins of insuranceChanges">
                <div class="extrasSummary_rowData">
                  <div>
                    <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">{{'Passenger'|translate:'itinerary'}}
                    </div>
                    <div class="extrasSummary_mobileRight title">
                      <b>
                        {{ins.passenger.name.title}} {{ins.passenger.name.first}}
                        {{ins.passenger.name.last}}
                      </b>
                    </div>
                  </div>
                  <div class="custom-insurance">
                    <span *ngIf="journeys.length > 1 else oneWayInsuranceDisplay">
                      {{ 'Travel insurance'| translate:'extras'}}
                    </span>
                    <ng-template #oneWayInsuranceDisplay>
                      <span>
                        {{ins.insuranceName | translate:'insurance.days'}}
                      </span>
                    </ng-template>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>


    <!-- <div class="itineraryBox_wrapper" *ngIf="showExtrasOnLockFare">
      <div class="itineraryBox_title">
        <h2>{{'Extras'|translate:'itinerary'}}</h2>
        <a *ngIf="!isOnLockFareFlow && !noFlightAvailable && !isCorporateBundle" class="itineraryBox_modifyBtn" (click)="changeBooking(ChangeBooking.extras)">{{'Modify'|translate:'itinerary'}}</a>
      </div>
      <div>
        <ng-container *ngIf="ssrChanges.length === 0 && insuranceChanges.length === 0">
          <div class="extrasSummary_wrapper segm0">
            <div class="extrasSummary_rowData notFound">
              <br />
              <div>{{'No extras found!'|translate:'itinerary'}}</div>
            </div>
          </div>
        </ng-container>
        <div class="" [attr.class]="getSegCount()">
          <div *ngIf="ssrChanges.length > 0 || insuranceChanges && insuranceChanges.length" class="extrasSummary_header">
            <div>
              <div> {{ 'Passenger' | translate:'itinerary' }}</div>
              <ng-container *ngFor="let journey of journeys">
                <ng-container *ngFor="let segment of journey.segments">
                  <div>
                      {{ '{0} to {1}' | translate: null : [segment.departureStation | translate: 'station.name'] : [segment.arrivalStation | translate: 'station.name']}}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>

          <ng-container *ngFor="let ssrType of ssrChanges">
            <ng-container *ngIf="ssrType.ssrTypeName == 'Meal' || ssrType.ssrTypeName == 'Pet' || ssrType.ssrTypeName == 'SpecialEquipment' || ssrType.ssrTypeName == 'SpecialAssistance'">
              <div class="extrasSummary_rowTitle" (click)="toggleVisibility(extrasToggle)">
                {{ ssrType.ssrTypeName | splitcamelcasestring | translate: 'itinerary' }}
              </div>
              <div [ngClass]="{'extrasSummary_mobileToggle': mobileMatches}" #extrasToggle>
                <ng-container *ngFor="let paxSsr of ssrType.passengerSsrsByType">
                  <div class="extrasSummary_rowData">
                    <div>
                      <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">
                        {{'Passenger' | translate:'itinerary' }}
                      </div>
                      <div class="extrasSummary_mobileRight title">
                        <b>
                          {{paxSsr.passenger.name.title}} {{paxSsr.passenger.name.first}}
                          {{paxSsr.passenger.name.last}}
                        </b>
                      </div>
                    </div>
                    <div *ngFor="let ssr of paxSsr.ssrCountOnSegment">
                      <div class="extrasSummary_mobileSegment" *ngIf="mobileMatches && ssr.ssrCount[0].code">
                        <b>
                            {{ '{0} to {1}' | translate: null : [ssr.segment.DepartureStation | translate: 'station.name'] : [ssr.segment.ArrivalStation | translate: 'station.name']}}
                        </b>
                      </div>
                      <div *ngFor="let ssrCount of ssr.ssrCount">

                          <ng-container *ngIf="ssrCount.code else emptyDiv">
                              {{ssrCount.code | translate:'ssr.name'}} x {{ ssrCount.newValue }}
                          </ng-container>
                          <ng-template #emptyDiv></ng-template>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>


            <ng-container *ngIf="insuranceChanges && insuranceChanges.length">
              <div class="extrasSummary_rowTitle" (click)="toggleVisibility(extrasToggle)">
                {{ 'Insurance' | splitcamelcasestring | translate:'itinerary' }}
              </div>

              <div [ngClass]="{'extrasSummary_mobileToggle': mobileMatches}" #extrasToggle>
                <ng-container *ngFor="let ins of insuranceChanges">
                  <div class="extrasSummary_rowData">
                    <div>
                      <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">{{'Passenger'|translate:'itinerary'}}</div>
                      <div class="extrasSummary_mobileRight title">
                        <b>
                          {{ins.passenger.name.title}} {{ins.passenger.name.first}}
                          {{ins.passenger.name.last}}
                        </b>
                      </div>
                    </div>
                    <div class="custom-insurance">
                      <span *ngIf="journeys.length > 1 else oneWayInsuranceDisplay">
                        {{ 'Travel insurance'| translate:'extras'}}
                      </span>
                      <ng-template #oneWayInsuranceDisplay>
                        <span>
                          {{ins.insuranceName | translate:'insurance.days'}}
                        </span>
                      </ng-template>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
        </div>
      </div>
    </div> -->

    <div class="itineraryBox_wrapper" *ngIf="!isOnLockFareFlow">
      <div class="itineraryBox_title">
        <h2>{{'Seats'|translate:'itinerary'}}</h2>
        <a *ngIf="!noFlightAvailable && !isCorporateBundle" class="itineraryBox_modifyBtn"
          (click)="changeBooking(ChangeBooking.seats)">{{'Modify'|translate:'itinerary'}}</a>
      </div>
      <div>
        <ng-container *ngIf="seatChanges.length === 0">
          <div class="extrasSummary_wrapper segm0">
            <div class="extrasSummary_rowData notFound">
              <br />
              <div>{{'No seat selected!'|translate:'itinerary'}}</div>
            </div>
          </div>
        </ng-container>
        <div class="" [attr.class]="getSegCount()">
          <div *ngIf="seatChanges.length > 0" class="extrasSummary_header">
            <div>
              <div>{{'Passenger'|translate:'itinerary'}}</div>
              <ng-container *ngFor="let journey of journeys">
                <ng-container *ngFor="let segment of journey.segments">
                  <div>
                    {{ '{0} to {1}' | translate: null : [segment.departureStation | translate: 'station.name'] :
                    [segment.arrivalStation | translate: 'station.name']}}
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <ng-container *ngFor="let seatChange of seatChanges">
            <ng-container>
              <div *ngFor="let seat of seatChange.passengerSsrsByType">
                <div class="extrasSummary_rowData">
                  <div class="seatTitle">
                    <div *ngIf="!mobileMatches" class="extrasSummary_mobileLeft">
                      {{'Passenger' | translate:'itinerary' }}
                    </div>
                    <div class="extrasSummary_mobileRight title">
                      <b>
                        {{seat.passenger.name.title}} {{seat.passenger.name.first}}
                        {{seat.passenger.name.last}}
                      </b>
                    </div>
                  </div>
                  <div class="seatsContent" *ngFor="let ssr of seat.ssrCountOnSegment">
                    <div class="extrasSummary_mobileSegment seatLeft" *ngIf="mobileMatches">
                      {{ '{0} to {1}' | translate: null : [ssr.segment.DepartureStation | translate: 'station.name'] :
                      [ssr.segment.ArrivalStation | translate: 'station.name']}}
                    </div>
                    <div class="seatRight" *ngFor="let ssrCount of ssr.ssrCount">
                      <div>
                        <span>
                          {{ ssrCount.newValue }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="itineraryBox_wrapper" *ngIf="showPaxOnLockFare">
      <div class="itineraryBox_title">
        <h2>{{'Passengers'|translate:'itinerary'}}</h2>
        <a *ngIf="!isOnLockFareFlow && !hideModifyPaxFlights && !isCorporateBundle" class="itineraryBox_modifyBtn"
          (click)="changeBooking(ChangeBooking.passengers)">{{'Modify'|translate:'itinerary'}}</a>
      </div>
      <div>
        <div class="passengersSummary_wrapper">
          <div class="passengersSummary_rowTitle">
            <div>{{'Passenger'|translate:'itinerary'}}</div>
            <div>{{'Date of Birth'|translate:'itinerary'}}</div>
          </div>
          <ng-container *ngFor="let pax of passengers">
            <div class="passengersSummary_rowData" *ngIf="!pax.isAutoCompleted; else lockedFarePax">
              <div>
                {{ pax.name.title + ' ' + pax.name.first + ' ' + pax.name.last }}
                <p *ngIf="pax.hasInfant">
                  {{ '(with infant)' | translate: 'itinerary' }}
                </p>
              </div>

              <div *ngIf="pax.typeInfo.dateOfBirth">
                {{ formatDate(pax.typeInfo.dateOfBirth, 'YYYY-MM-DD') }}
              </div>

              <div *ngIf="!pax.typeInfo.dateOfBirth">
                {{ 'Not provided' | translate: 'itinerary' }}
              </div>
            </div>

            <ng-template class="passengersSummary_rowData" #lockedFarePax>
              <div class="passengersSummary_rowData">
                <check-in-passenger-type-display [cssClass]="'force_fontSettings'" [passenger]="pax">
                </check-in-passenger-type-display>

                <p *ngIf="pax.hasInfant" class="force_fontSettings">
                  {{ '(with infant)' | translate: 'itinerary' }}
                </p>
              </div>
            </ng-template>

            <ng-container *ngIf="pax.hasInfant">
              <div *ngIf="!pax.infant.isAutoCompleted; else lockedFareInf" class="passengersSummary_rowData">
                <div>
                  <check-in-passenger-name-display cssClass="force_fontSettings" [passenger]="pax.infant">
                  </check-in-passenger-name-display>
                </div>

                <div *ngIf="pax.infant.dateOfBirth">
                  {{ formatDate(pax.infant.dateOfBirth, 'YYYY-MM-DD') }}
                </div>

                <div *ngIf="!pax.infant.dateOfBirth">
                  {{ 'Not provided' | translate: 'itinerary' }}
                </div>
              </div>

              <ng-template #lockedFareInf>
                <check-in-passenger-type-display [cssClass]="'force_fontSettings'" [passenger]="pax.infant">
                </check-in-passenger-type-display>
              </ng-template>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="itineraryBox_wrapper" *ngIf="isRestricted">
      <div class="itineraryBox_title">
        <h2>
          {{ 'Passengers contact details' | translate: 'itinerary' }}
        </h2>

        <!-- <a class="itineraryBox_modifyBtn" (click)="edit()">
          {{ 'Modify' | translate: 'itinerary' }}
        </a> -->
      </div>

      <div class="passengersSummary_wrapper">
        <div class="passengersSummary_rowTitle">
          <div>
            {{ 'Passenger' | translate: 'itinerary' }}
          </div>

          <div>
            {{ 'Phone Number' | translate: 'itinerary' }}
          </div>

          <div>
            {{ 'Email Address' | translate: 'itinerary' }}
          </div>
        </div>

        <ng-container *ngFor="let pax of passengers">
          <div class="passengersSummary_rowData">
            <div>
              {{ pax.name.title + ' ' + pax.name.first + ' ' + pax.name.last }}
            </div>

            <div *ngIf="pax.addresses && pax.addresses.items && pax.addresses.items.length > 0">
              {{ pax.addresses.items[0].phoneNumber }}
            </div>

            <div *ngIf="pax.addresses && pax.addresses.items && pax.addresses.items.length > 0">
              {{ pax.addresses.items[0].emailAddress }}
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="itineraryBox_wrapper">
      <div class="itineraryBox_title">
        <div *ngIf="!isRestricted;else gdsContact">
          <h2>{{'Contact Details'|translate:'itinerary'}}</h2>
        </div>
        <ng-template #gdsContact>
          <h2>{{'Agency Contact Details'|translate:'itinerary'}}</h2>
        </ng-template>
        <a *ngIf=" !isOnLockFareFlow && !noFlightAvailable && !isCorporateBundle && !isRestricted"
          class="itineraryBox_modifyBtn"
          (click)="changeBooking(ChangeBooking.contact)">{{'Modify'|translate:'itinerary'}}</a>
      </div>
      <div>
        <div class="contactSummary_wrapper">
          <div class="contactSummary_rowTitle">
            <div>{{'Name'|translate:'itinerary'}}</div>
            <div>{{'Email'|translate:'itinerary'}}</div>
            <div>{{'Phone'|translate:'itinerary'}}</div>
            <div>{{'Address'|translate:'itinerary'}}</div>
          </div>
          <div class="contactSummary_rowData" *ngFor="let contact of contacts">
            <div>
              <div class="contactSummary_mobileTop">{{'Name'|translate:'itinerary'}}</div>{{contact.name.title +' ' +
              contact.name.first +' ' + contact.name.last}}
            </div>
            <div>
              <div class="contactSummary_mobileTop">{{'Email'|translate:'itinerary'}}</div>{{contact.emailAddress}}
            </div>
            <div>
              <div class="contactSummary_mobileTop">{{'Phone'|translate:'itinerary'}}</div>
              <span>{{contact.homePhone}}<span>{{'(primary)'|translate:'itinerary'}}</span></span>
              <span
                *ngIf="!isOnLockFareFlow">{{contact.otherPhone}}<span>{{'(emergency)'|translate:'itinerary'}}</span></span>
            </div>
            <div>
              <div class="contactSummary_mobileTop">{{'Address'|translate:'itinerary'}}</div>
              <span>{{contact.addressLine1}}</span>
              <span>{{contact.addressLine2}}</span>
              <span>{{contact.city}}</span>
              <span>{{contact.postalCode}}</span>
              <span>{{contact.countryCode|translate:'country.name'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="itineraryBox_wrapper" *ngIf="!isRestricted ">
      <div class="itineraryBox_title">
        <h2>{{'Payment summary'|translate:'itinerary'}}</h2>
        <a *ngIf="!isOnLockFareFlow && paymentDue > 0" routerLink="/summary"
          class="itineraryBox_modifyBtn twoRows">{{'Make payment'|translate:'itinerary'}}</a>
        <a *ngIf="isOnLockFareFlow && paymentDue > 0" routerLink="/extras"
          class="itineraryBox_modifyBtn twoRows">{{'Finalize booking'|translate:'itinerary'}}</a>
      </div>
      <div>
        <div class="paymentSummary_wrapper">

          <div class="paymentSummary_rowTitle">
            <div>{{'Item'|translate:'itinerary'}}</div>
            <div>{{"Amount"|translate:'itinerary':bookingCurrency}}</div>
          </div>

          <div class="paymentSummary_rowData">
            <div>{{"Fares" | translate :'itinerary'}}</div>
            <div>{{fareTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="seatTotal > 0">
            <div>{{"Seat Selection" | translate :'itinerary'}}</div>
            <div>{{seatTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngFor="let totalFee of totalFeeAmounts">
            <div>{{totalFee.ssr | splitcamelcasestring}}</div>
            <div>{{totalFee.total | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="insuranceTotal > 0">
            <div>{{"Insurance Total" | translate :'itinerary'}}</div>
            <div>{{insuranceTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="spoilageFeesOneTotal > 0">
            <div>{{"Extras Spoilage Fees" | translate :'itinerary'}}</div>
            <div>{{spoilageFeesOneTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="spoilageFeesTwoTotal > 0">
            <div>{{"Fares Spoilage Fees" | translate :'itinerary'}}</div>
            <div>{{spoilageFeesTwoTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="dateChangeFees > 0">
            <div>{{"Date Change Fees" | translate :'itinerary'}}</div>
            <div>{{dateChangeFees | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="nameChangeFeesTotal > 0">
            <div>{{"Name Change Fees" | translate :'itinerary'}}</div>
            <div>{{nameChangeFeesTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="bundleUpgradeTotal > 0">
            <div>{{"Add-on Bundles Total" | translate :'itinerary'}}</div>
            <div>{{bundleUpgradeTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowData" *ngIf="fareTaxesTotal > 0">
            <div>{{"Taxes & Fees" | translate :'itinerary'}}</div>
            <div>{{fareTaxesTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowPayment">
            <div>{{'Total'|translate:'itinerary'}}</div>
            <div>{{grandTotal | currencyDisplay}}</div>
          </div>

          <div class="paymentSummary_rowTitle">
            <div>{{'Payment Summary'|translate:'itinerary'}}</div>
            <div>{{'Amount'|translate:'itinerary':bookingCurrency}}</div>>
          </div>

          <ng-container *ngFor="let payment of payments">
            <div class="paymentSummary_rowData">
              <div *ngIf="!payment.isWallet && payment.paymentMethodCode === 'VO'">{{'Voucher'|translate:'itinerary'}}
              </div>
              <div *ngIf="payment.paymentAmount < 0 && payment.paymentMethodCode === 'WT';else paymentReceive">
                {{'Payment Refunded into customer wallet'|translate:'itinerary'}}</div>
              <ng-template #paymentReceive>
                <div *ngIf="!payment.isWallet && payment.paymentMethodCode !== 'VO'">{{'Payment
                  Received'|translate:'itinerary'}}</div>
              </ng-template>
              <div *ngIf="payment.isWallet">{{'Wallet'|translate:'itinerary'}}</div>
              <div>{{payment.paymentAmount | currencyDisplay}}</div>
            </div>
          </ng-container>

          <div class="paymentSummary_rowPayment">
            <div>{{'Payment Due'|translate:'itinerary'}}</div>
            <div>{{paymentDue | currencyDisplay}}</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</check-in-form-wrapper>

<check-in-default-modal #voucherModal [modalId]="voucherModal"
  modalTitle="{{'Voucher request' | translate: 'flight-select'}}" okButtonText="{{'Ok' | translate: 'flight-select'}}"
  [okRequired]="true" [cancelRequired]="false">

  {{'A request for voucher for this booking will be processed! You will be notified after the voucher will be
  generated.' | translate: 'flight-select'}}

  <div class="card-body">
    <div #recaptcha></div>
  </div>
</check-in-default-modal>

<check-in-default-modal #refuntToWalletEligibilityModal modalId="refuntToWalletEligibilityModal" modalTitle=""
  modalText="" okButtonText="" cancelButtonText="" [okRequired]="" [cancelRequired]="">

  <div>
    <div class="default-modal half-split asd">
      <div class="section-text padding-bottom">
        <span class="text-color-red">
          {{ 'We are sorry to inform you that you cannot refund your wallet for any of your journey segments because all
          of your journey segments are checked-in' | translate: 'itinerary' }}
        </span>
      </div>
    </div>
  </div>
</check-in-default-modal>

<check-in-default-modal #restrictedBookingModal modalId="restrictedBookingModal" modalTitle="" modalText=""
  okButtonText="" cancelButtonText="" [okRequired]="" [cancelRequired]="">

  <div class="default-modal half-split asd">
    <div class="section-text padding-bottom">
      <span class="text-color-red">
        {{ restrictionMessage | translate: 'itinerary' }}
      </span>
    </div>
  </div>
</check-in-default-modal>

<check-in-default-modal #canceledBookingModal modalId="canceledBookingModal" modalTitle="" modalText="" okButtonText=""
  cancelButtonText="" [okRequired]="" [cancelRequired]="">

  <div class="default-modal half-split asd">
    <div class="section-text padding-bottom">
      <span class="text-color-red">
        <span *ngIf="!areCanceledFlights">
          {{ "{0} - {1}" | translate: 'itinerary': [departureJourneySegment | translate: 'station.name'] :
          [arrivalJourneySegment | translate: 'station.name'] }}
        </span>
        <span *ngIf="!areCanceledFlights">
          {{ 'is cancelled.' | translate: 'itinerary' }}
        </span>

        <span *ngIf="areCanceledFlights">
          {{ 'You have canceled flights!' | translate: 'itinerary' }}
        </span>

        <br>

        <span>
          {{ 'Please update your flights, then retry!' | translate: 'itinerary' }}
        </span>
      </span>
    </div>
  </div>
</check-in-default-modal>

<blue-modal2 [id]="editModalId" class="contact-details-passengers-modal">
  <div *ngFor="let pax of passengers; let i = index">
    <!-- <span class="popup-title">
      {{ 'Contact infos' | translate:'profile-info' }}
    </span> -->

    <div class="form-row equal-fields">
      <div class="one-field pax-name">
        {{ pax.name.title + ' ' + pax.name.first + ' ' + pax.name.last }}
      </div>

      <div class="one-field">
        <label for="phoneNumber">
          {{ 'Phone number' | translate: 'itinerary'}}
        </label>
        <input id="phoneNumber" type="text" name="phoneNumber"
          placeholder="{{ 'Phone number' | translate: 'itinerary' }}" aria-labelledby="phoneNumber"
          [ngModel]="pax.addresses.items[0].phoneNumber" #phoneNumber="ngModel" [my-error]="phoneNumber.dirty"
          patternError="{{ 'Invalid phone number' | translate: 'itinerary' }}" [pattern]="phonePattern"
          (change)="setPassengerInformation(pax, $event.target.value, true)"
          *ngIf="pax.addresses && pax.addresses.items && pax.addresses.items.length > 0" />
      </div>

      <div class="one-field">
        <label for="emailAddress">
          {{ 'Email Address' | translate: 'itinerary'}}
        </label>
        <input id="emailAddress" type="email" name="emailAddress" placeholder="{{ 'Email' | translate: 'itinerary' }}"
          aria-labelledby="emailAddress" [ngModel]="pax.addresses.items[0].emailAddress" #emailAddress="ngModel"
          [my-error]="emailAddress.dirty" patternError="{{ 'Invalid email address' | translate: 'itinerary' }}"
          [pattern]="emailPattern" (change)="setPassengerInformation(pax, $event.target.value, false)"
          *ngIf="pax.addresses && pax.addresses.items && pax.addresses.items.length > 0" />
      </div>
    </div>
  </div>

  <div class="form-row btn-row space-between">
    <div style="float: left;">
      <button role="button" class="btn btn-secondary blue" (click)="cancel()">
        {{ 'Cancel' | translate:'info-box-actions' }}
      </button>
    </div>

    <div style="float: right;">
      <button role="button" class="btn btn-primary btn-blue" (click)="save()">
        {{ 'Save' | translate:'info-box-actions' }}
      </button>
    </div>
  </div>
</blue-modal2>

<check-in-default-modal #insuranceModal modalId="insuranceModal"
  modalTitle="{{ 'Important information about Travel Insurance' | translate: 'itinerary' }}" modalText=""
  okButtonText="" cancelButtonText="" [okRequired]="" [cancelRequired]="">

  <div class="default-modal half-split asd">
    <div class="section-text padding-bottom">
      <span class="text-color-red">
        {{ insuranceMesage | translate: 'itinerary' }}
      </span>
    </div>
  </div>

  <div class="modal-buttons">
    <a type="button" class="btn btn-primary" (click)="checkInsuranceAcknowledgment()">
      {{ 'I acknowledge' | translate: 'itinerary' }}
    </a>
  </div>
</check-in-default-modal>

<check-in-default-modal #insuranceAlertModal modalId="insuranceAlertModal"
  modalTitle="{{ 'Important information about Travel Insurance' | translate: 'itinerary' }}"
  okButtonText="{{ 'I acknowledge' | translate: 'extras' }}" [okRequired]="true">

  <div class="warning-sign">
  </div>

  <div class="section-text text-color-red">
    {{ 'Your booking contains Travel Protection with COVID19 Cover. If the dates of your trip change, you will need to
    contact the insurance provider to update your policy.' | translate: 'itinerary' }}
  </div>
</check-in-default-modal>