export enum SsrType {
    // inserting None so the enum is the same as on backend
    None = 0,
    Baggage = 1,
    Meal = 2,
    Pet = 3,
    SpecialEquipment = 4,
    SpecialAssistance = 5,
    Seat = 6,
    PriorityBoarding = 7,
    FastTrack = 8,
    PriorityBag = 9,
    Lounge = 10,
    Flex = 11,
    BlueBenefits = 12,
    AirportCheckin = 13,
    EarlyCheckin = 14,
    BusTransfer = 15,
    PSO = 16,
    Insurance = 200,
    BundleUpgrade = 300,
    Other = 400,
}
