import { Component, OnInit, Input } from '@angular/core';
import { ShoppingCartExtrasModel } from '../../../core/models/shopping-cart-models';
import { SsrType } from '../../../extras/ssr-type.enum';
import { filter, take } from 'rxjs/operators';
import { FlowManagerService } from 'src/app/core/flow-manager.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'shopping-cart-extras',
  templateUrl: './shopping-cart-extras.component.html',
  styleUrls: ['./shopping-cart-extras.component.scss']
})
export class ShoppingCartExtrasComponent implements OnInit {
  SsrTypeEnum = SsrType;
  isExpanded: { [ssrType: number]: boolean } = {};
  paxNumberByType: { [paxNumber: number]: number } = {};

  ssrTypeMetadata: { [ssrType: number]: { name: string, icon: string } } = {};
  extras: ShoppingCartExtrasModel[] = [];
  total: number;
  isRoundTrip: boolean;

  @Input()
  set input(value: ShoppingCartExtrasModel[]) {
    this.setupExtras(value);
  }

  constructor(flowManager: FlowManagerService) {
    const selectionService = flowManager.selectionService;
    this.setupSsrTypeMetadata();

    selectionService.passengersObs.pipe(filter(p => p ? true : false), take(1)).subscribe(passengers => {
      this.paxNumberByType = {};
      passengers.forEach(p => this.paxNumberByType[p.passengerNumber] = p.passengerNumberByType);
    });

    selectionService.flightsObs.pipe(filter(p => p ? true : false), take(1)).subscribe(flights => {
      this.isRoundTrip = flights.length > 1;
    });
  }

  ngOnInit() {
  }

  toggleExpanded(ssrType: SsrType) {
    this.isExpanded[ssrType] = !this.isExpanded[ssrType];
  }

  private setupExtras(extrasInput: ShoppingCartExtrasModel[]) {
    this.extras = (extrasInput || []).filter(e => e.flightsWithItems.length).sort2(e => e.ssrType);
    this.total = this.extras.sum(e => e.amount);
    this.validateSsrTypeMedata();

    if (this.extras[0] && this.extras[0].flights && this.extras[0].flights[0].segments.length > 1) {
      this.extras.forEach(ex => {
        if (ex.ssrType == 6) return;

        ex.flights.forEach(f => {
          for (let index = 1; index < f.segments.length; index++) {
            f.segments[index].passengers.forEach(p => {
              let passengerIndex = f.segments[index].passengers.indexOf(p);

              p.items.forEach(i => {
                let itemIndex = p.items.indexOf(i);

                f.segments[0].passengers[passengerIndex].items[itemIndex].totalAmount += i.totalAmount;
              })
            })
          }
        })
      })
    }
  }

  private setupSsrTypeMetadata() {
    let name = '';
    let icon = '';

    for (const ssrType in SsrType) {
      if (!Number(ssrType)) {
        continue;
      }
      switch (+ssrType) {
        case SsrType.Baggage:
          name = 'Hold Luggage';
          icon = 'font-ico-luggage';
          break;
        case SsrType.Meal:
          name = 'Meals';
          icon = 'font-ico-meal';
          break;
        case SsrType.Pet:
          name = 'Pets';
          icon = 'font-ico-dog';
          break;
        case SsrType.SpecialEquipment:
          name = 'Other equipments';
          icon = 'font-ico-golf';
          break;
        case SsrType.Lounge:
          name = 'Lounge';
          icon = 'font2-ico-lounge';
          break;
        case SsrType.SpecialAssistance:
          name = 'Special assistance';
          icon = 'font-ico-spec-assistance';
          break;
        case SsrType.Seat:
          name = 'Seat';
          icon = 'font-ico-seat';
          break;
        case SsrType.PriorityBag:
          name = 'Priority bag';
          icon = 'font-ico-priority-bag';
          break;
        case SsrType.PriorityBoarding:
          name = 'Priority boarding';
          icon = 'font-ico-priority-boarding-2bags';
          break;
        case SsrType.BusTransfer:
          name = 'Buss transfer';
          icon = 'font-ico-shuttle';
          break;
        case SsrType.FastTrack:
          name = 'Fast Track';
          icon = 'font-ico-fast-track';
          break;
        case SsrType.AirportCheckin:
          name = 'Airport Checkin';
          icon = 'font-ico-airport-checkin';
          break;
        case SsrType.EarlyCheckin:
          name = 'Early Checkin';
          icon = 'font-ico-early-checkin';
          break;
        case SsrType.BundleUpgrade:
          name = 'Add-on Bundles';
          icon = 'font-ico-tag';
          break;
        case SsrType.Insurance:
          name = 'Insurance';
          icon = 'font-ico-insurance';
          break;
        case SsrType.Flex:
          name = 'Flex';
          icon = 'font2-ico-flex';
          break;
        case SsrType.BlueBenefits:
          name = 'Benefits';
          icon = 'font-ico-blue-benefits';
          break;
        case SsrType.PSO:
          name = 'Pso Discount';
          icon = 'font-ico-blue-benefits';
          break;
        case SsrType.Other:
        default:
          name = 'Other';
          icon = '';
      }

      this.ssrTypeMetadata[ssrType] = { name: name, icon: icon };
    }

  }

  private validateSsrTypeMedata() {
    this.extras.forEach(e => {
      if (!this.ssrTypeMetadata[e.ssrType]) {
        this.ssrTypeMetadata[e.ssrType] = {
          name: e.ssrType.toString(),
          icon: ''
        };
      }
    });
  }
}
