<div class="invoice-buttons">
  <button class="back-arrow" (click)="goBack.emit()">
    {{'Back'|translate}}
  </button>

  <button class="link-arrow align-right" (click)="skipInvoicing.emit()">
    {{ 'Skip invoicing' | translate:'invoicing' }}
  </button>
</div>

<div class="invoice-container" [formGroup]="formGroup" [ngClass]="{submitted: submitted}">
  <div class="invoice-title">
    {{ 'Invoice title' | translate:'invoicing' }}
  </div>

  <div class="alert-holder alert-error" *ngIf="submitted && !formGroup.valid">
    <div class="alert-item secondary with-icon">

      <ul>
        <li *ngIf="!invoicingDataValidation.formValid">
          {{ 'Please correct all errors marked with red bellow' }}
        </li>
        <ng-container *ngIf="invoicingDataValidation.formValid">
          <li *ngIf="!invoicingDataValidation.dataValid && invoicingDataValidation.errors">
            {{invoicingDataValidation.errors.errMsg}}
          </li>
          <li *ngIf="!invoicingDataValidation.dataValid && !invoicingDataValidation.errors">
            {{ 'Invoice validation failed' | translate: 'invoicing' }}
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
  <blue-loading-spinner id="invoiceSpinner" *ngIf="!dataLoaded; else loaded" [showSpinner]="true">
  </blue-loading-spinner>
  <ng-template #loaded>
    <!-- {{ formGroup.value | json}} -->
    <div class="field-wrap">
      <div class="field-inner">
        <label class="label">
          {{'Customer Type' | translate: 'invoicing'}}
        </label>
        <blue-select-list [items]="customerTypes" enableSearch="false" textProperty="text" valueProperty="value"
          formControlName="customerType"></blue-select-list>
      </div>
      <ng-container *ngIf="isItalianIndividual; else NotItalianIndividual">
        <div class="field-wrap">
          <div class="field-inner">
            <label class="label" for="companyName">
              {{ 'First Name' | translate: 'invoicing'}}
              <span>*</span>
            </label>
            <input type="text" name="companyName" formControlName="companyName" />
            <show-errors [control]="formGroup.controls.companyName" [submitted]="submitted"></show-errors>
          </div>
        </div>
        <div class="field-wrap">
          <div class="field-inner">
            <label class="label" for="lastName">
              {{ 'Last Name' | translate: 'invoicing'}}
              <span>*</span>
            </label>
            <input type="text" name="lastName" formControlName="lastName" />
            <show-errors [control]="formGroup.controls.lastName" [submitted]="submitted"></show-errors>
          </div>
        </div>
      </ng-container>
      <ng-template #NotItalianIndividual>
        <div class="field-wrap">
          <div class="field-inner">
            <label class="label" for="companyName">
              {{ 'Company name' | translate: 'invoicing'}}
              <span>*</span>
            </label>
            <input type="text" name="companyName" formControlName="companyName" />
            <show-errors [control]="formGroup.controls.companyName" [submitted]="submitted"></show-errors>
          </div>
        </div>
      </ng-template>
      <div class="field-wrap">
        <div class="field-inner">
          <label class="label">
            {{'Country' | translate: 'invoicing'}}
          </label>
          <div>
            <blue-select-list [items]="countries" [hidden]="!isNonItCustomer" enableSearch="true" textProperty="name"
              valueProperty="code" formControlName="countryCode"></blue-select-list>
            <input type="text" disabled="disabled" *ngIf="!isNonItCustomer"
              value="{{ 'Italy' | translate: 'invoice'}}" />
          </div>
          <show-errors [control]="formGroup.controls.countryCode" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap" *ngIf="!isNonItCustomer">
        <div class="field-inner">
          <label class="label" for="fiscalCode">
            {{'Fiscal code' | translate: 'invoicing'}}
          </label>
          <input type="text" name="fiscalCode" formControlName="fiscalCode" placeholder="" />
          <show-errors [control]="formGroup.controls.fiscalCode" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap">
        <div class="field-inner">
          <label class="label" for="vat">
            {{'VAT' | translate: 'invoicing'}}
          </label>
          <input type="text" name="vat" formControlName="vat" placeholder="" />
          <show-errors [control]="formGroup.controls.vat" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap">
        <div class="field-inner">
          <label class="label" for="headquarterAddress">
            {{'Headquarter Address' | translate: 'invoicing'}}
            <span>*</span>
          </label>
          <input type="text" name="headquarterAddress" formControlName="headquarterAddress" />
          <show-errors [control]="formGroup.controls.headquarterAddress" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap">
        <div class="field-inner">
          <label class="label" for="addressNumber">
            {{'Address number' | translate: 'invoicing'}}
          </label>
          <input type="text" name="addressNumber" formControlName="addressNumber" />
          <show-errors [control]="formGroup.controls.addressNumber" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap" *ngIf="!isNonItCustomer">
        <div class="field-inner">
          <label class="label" for="headquarterZipCode">
            {{'Headquarter Zip Code' | translate: 'invoicing'}}
            <span>*</span>
          </label>
          <input type="text" name="headquarterZipCode" formControlName="headquarterZipCode" placeholder="" />
          <show-errors [control]="formGroup.controls.headquarterZipCode" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap" *ngIf="!isNonItCustomer">
        <div class="field-inner">
          <label class="label" for="region">
            {{'Region' | translate: 'invoicing'}}
          </label>
          <blue-select-list [items]="regions" name="region" enableSearch="true" textProperty="name" valueProperty="code"
            formControlName="region"></blue-select-list>
        </div>
      </div>
      <div class="field-wrap">
        <div class="field-inner">
          <label class="label" for="headquarterCity">
            {{'Headquarter City' | translate: 'invoicing'}}
            <span>*</span>
          </label>
          <blue-select-list [items]="citiesForRegion" *ngIf="!isNonItCustomer; else showCityInput;" enableSearch="true"
            textProperty="text" valueProperty="value" formControlName="headquarterCity"></blue-select-list>

          <ng-template #showCityInput>
            <input type="text" name="headquarterCity" formControlName="headquarterCity" />
          </ng-template>
          <show-errors [control]="formGroup.controls.headquarterCity" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap" *ngIf="isItalianCompany">
        <div class="field-inner">
          <label class="label" for="recipientCode">
            {{'Recipient code' | translate: 'invoicing'}}
          </label>
          <input type="text" name="recipientCode" formControlName="recipientCode" placeholder="" />
          <show-errors [control]="formGroup.controls.recipientCode" [submitted]="submitted"></show-errors>
        </div>
      </div>
      <div class="field-wrap" *ngIf="isItalianCompany">
        <div class="field-inner">
          <label class="label" for="emailPec">
            {{'Email PEC' | translate: 'invoicing'}}
          </label>
          <input type="text" name="emailPec" formControlName="emailPec" placeholder="" />
          <show-errors [control]="formGroup.controls.emailPec" [submitted]="submitted"></show-errors>
        </div>
      </div>
    </div>




    <!-- <div class="field-wrap">
      <div class="field-inner">
        <label class="label">
          {{'Customer Type' | translate: 'invoicing'}}
          <span>*</span>


        </label>
        <p>{{formGroup.value | json }}</p>

        <blue-select-list [items]="customerTypes" textProperty="text" valueProperty="value"
          formControlName="customerType"></blue-select-list>
      </div>
    </div> -->


  </ng-template>
</div>

<ng-template #empty>
  &nbsp;
</ng-template>