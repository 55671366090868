import * as tslib_1 from "tslib";
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { CheckinSteps, BookingStepsService } from './../../core/booking-steps.service';
import { DefaultModalComponent } from './../../shared/default-modal/default-modal.component';
import { BookingFlightSelectService } from './../booking-flight-select.service';
import { BookingService } from './../../core/booking.service';
import { ResourceService } from './../../common-modules/blue-air-common/resource.service';
import { OnInit, EventEmitter, OnDestroy, AfterViewInit } from '@angular/core';
import * as moment from 'moment';
import { CalendarDatePickerComponent } from '../calendar-date-picker/calendar-date-picker.component';
import { BookingFlowService } from '../../core/booking-flow.service';
import { PassengerTypeSelectionViewModel } from '../models/passenger-selection-view-model';
import { PassengerTypePickerComponent } from '../passenger-type-picker/passenger-type-picker.component';
import { FlightSearchModel } from '../models/flight-search-model';
import { Constants } from '../../constants';
import { CurrencyManagerService } from '../../core/currency-manager.service';
import { ProfileService } from 'src/app/core/profile.service';
import { SessionStorage } from 'ngx-webstorage';
import { BlueModalService } from '../../common-modules/blue-air-common/blue-modal/blue-modal.service';
import { UserStateService } from 'src/app/common-modules/blue-air-common/user-state.service';
import { switchMap, filter } from 'rxjs/operators';
import { ConfigService } from '../../core/config.service';
import { isNullOrUndefined, isUndefined } from 'util';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
const DEFAULT_PAX_NUMBER = 9;
export class ChangeFlightComponent {
    constructor(bookingFlowService, bookingService, bookingStepsService, currencyManager, flightSearch, loadingSpinnerService, profileService, resourceService, configService, translateService, modalService, userStateService, sanitizer) {
        this.bookingFlowService = bookingFlowService;
        this.bookingService = bookingService;
        this.bookingStepsService = bookingStepsService;
        this.currencyManager = currencyManager;
        this.flightSearch = flightSearch;
        this.loadingSpinnerService = loadingSpinnerService;
        this.profileService = profileService;
        this.resourceService = resourceService;
        this.configService = configService;
        this.translateService = translateService;
        this.modalService = modalService;
        this.userStateService = userStateService;
        this.sanitizer = sanitizer;
        this.specialPriceModalId = 'specialPriceModalId';
        this.format = 'DD/MM/YYYY';
        this.changeFlightSpinnerId = 'changeFlightSpinnerId';
        this.selectedMarketName = 'sardinia';
        this.showSpecialPrice = false;
        this._selectedSpecialMarketOption = 'noSpecialMarket';
        this.isPromoSelected = true;
        this.search = new EventEmitter();
        this.isProfilePromoActive = false;
        this.totalPaxCount = DEFAULT_PAX_NUMBER;
        this.totalPromoPaxCount = 9;
        this.showCmsView = false;
        this.isEligibleForSpecialPaxCount = false;
        this.currentSelectedMarket = null;
        this.priceOptionList = [
            {
                value: 'noSpecialMarket', text: 'Clear selection'
            },
            {
                value: 'specialPrice', text: this.getSpecialPriceCheckboxText()
            },
            {
                value: 'psoWorker', text: 'Are you a worker in Sardinia?'
            }
        ];
        this.selectedIndex = 0;
        this.today = moment().format(this.format);
        this.passengerSelection = new PassengerTypeSelectionViewModel();
        this.passengerSelection.adults = 1;
        this.superStations = [];
        this.bookingFlowService.loadSuperStations().then(s => {
            this.superStations = s;
            this.loadingSpinnerService.hideSpinnerById(this.changeFlightSpinnerId);
        });
        this.bookingStepsSubscription = this.bookingStepsService.currentStep.subscribe(currentStep => {
            if (currentStep) {
                this.isOnFlightsPage = currentStep === CheckinSteps.flights;
            }
        });
        this.availableCurrencies = this.currencyManager.currencyList.map(c => ({ text: c, value: c }));
        this.currency = this.bookingFlowService.currency || 'EUR';
        if (profileService.isAgent) {
            //resourceService.getClassesOfService().pipe(take(1)).subscribe(cs => this.classesOfService = cs);
            this.classOfService = this.classOfService || 'Y';
            this.isAgent = true;
        }
        this.profileService.profile.subscribe(profile => {
            const { organizationCode } = profile.identifier;
            if (!isNullOrUndefined(organizationCode)) {
                this.currentAgentOrganizationCode = organizationCode;
            }
        });
        this.initUserData();
        this.bookingService.memberPromotionObs.subscribe(data => {
            if (data && data.memberPromotion.isActive) {
                this.isProfilePromoActive = true;
                this.totalPromoPaxCount = data.memberPromotion.paxCount;
                this.totalPaxCount = this.totalPromoPaxCount;
            }
        });
    }
    set selectedSpecialMarketOption(value) {
        this._selectedSpecialMarketOption = value;
        this.computeMaxPaxCount();
    }
    get selectedSpecialMarketOption() {
        return this._selectedSpecialMarketOption;
    }
    get isSpecialMarket() {
        return this.selectedSpecialMarketOption === 'specialPrice' || this.selectedSpecialMarketOption === 'psoWorker';
    }
    set isSpecialMarket(value) {
        this.selectedSpecialMarketOption = value ? 'specialPrice' : 'noSpecialMarket';
    }
    set outbound(value) {
        const v = moment(value);
        this.outboundDate = v.isValid() ? v.format(this.format) : null;
    }
    get outbound() {
        const v = moment(this.outboundDate, this.format);
        return v.isValid() ? v.toDate() : null;
    }
    set inbound(value) {
        if (value) {
            const v = moment(value);
            this.inboundDate = v.isValid() ? v.format(this.format) : null;
        }
        else {
            this.inboundDate = null;
        }
        this.computeMaxPaxCount();
    }
    get inbound() {
        const v = moment(this.inboundDate, this.format);
        return v.isValid() ? v.toDate() : null;
    }
    set departure(value) {
        this._departure = value;
        this.checkIfIsSpecialPrice();
        // this.setSpecialPriceIframeUrl();
    }
    get departure() {
        return this._departure;
    }
    set destination(value) {
        this._destination = value;
        this.checkIfIsSpecialPrice();
        // this.setSpecialPriceIframeUrl();
    }
    get destination() {
        return this._destination;
    }
    set tripType(value) {
        this.isOneWay = value === Constants.OneWayTrip;
    }
    get tripType() {
        return this.isOneWay ? Constants.OneWayTrip : Constants.RoundTrip;
    }
    set flightSearchModel(model) {
        if (model) {
            this.departure = model.origin;
            this.destination = model.destination;
            this.outboundDate = moment(model.departureDate).format(this.format);
            this.inboundDate = moment(model.returnDate).format(this.format);
            this.isOneWay = !model.isRoundTrip;
            this.passengerSelection = new PassengerTypeSelectionViewModel().initFromPassengersSearch(model.passengers);
            this.currency = model.currency;
        }
    }
    ngOnInit() {
    }
    ngOnDestroy() {
        if (this.bookingStepsSubscription) {
            this.bookingStepsSubscription.unsubscribe();
        }
        if (this.flightsToChangeSubscription) {
            this.flightsToChangeSubscription.unsubscribe();
        }
        if (this.userStateSubscription) {
            this.userStateSubscription.unsubscribe();
        }
    }
    ngAfterViewInit() {
        this.flightsToChangeSubscription = this.bookingService.flightsToChangeObs.subscribe((data) => {
            if (data && data.length > 0) {
                this.flightsToChange = data;
                sessionStorage.setItem(Constants.SelectedFlightIndexForChange, (this.flightsToChange[0].journeySequenceNumber - 1).toString());
            }
        });
    }
    changePriceOptionSelection(event, index) {
        this.selectedIndex = event.target.checked ? index : undefined;
        this.selectedSpecialMarketOption = this.priceOptionList[index].value;
    }
    outboundDateSelected(newDate) {
        const outb = moment(this.outboundDate, this.format);
        const inb = moment(this.inboundDate, this.format);
        if (outb.isAfter(inb)) {
            this.inboundDate = '';
        }
        if (this.inboundDatePicker) {
            this.inboundDatePicker.open();
        }
        else {
            if (this.passengerTypePicker) {
                this.passengerTypePicker.focusIn();
            }
        }
        this.computeMaxPaxCount();
    }
    inboundDateSelected(newDate) {
        if (this.passengerTypePicker) {
            this.passengerTypePicker.focusIn();
        }
    }
    destinationStationSelected(newStation) {
        // de-comment this if we need to go directly to date-picker
        // if (newStation && this.outboundDatePicker) {
        //   setTimeout(() => this.outboundDatePicker.open(), 5);
        // }
    }
    oneWayChanged() {
        if (this.isOneWay) {
            this.inboundDate = '';
        }
        else {
            this.inboundDate = this.outboundDate;
        }
    }
    searchFlight() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.departure || !this.destination || !this.outbound ||
                (!this.isOneWay && (!this.inbound || this.inbound < this.outbound)) ||
                this.passengerSelection.count === 0) {
                return;
            }
            this.computeMaxPaxCount();
            if (this.passengerSelection.count > this.totalPaxCount) {
                this.modalService.open('numberOfPaxExcededModal');
                return;
            }
            if (this.isSpecialMarket) {
                // this.specialPriceModal.openPopup(async (isOk) => {
                //   if (!isOk) return;
                //   await this.doSearchFlight();
                // })
                this.showCmsView = true;
            }
            else {
                yield this.doSearchFlight();
            }
        });
    }
    continueSearchFlight() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.doSearchFlight();
        });
    }
    cancelSearchFlight() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.showCmsView = false;
            setTimeout(() => {
                this.loadingSpinnerService.hideSpinnerById(this.changeFlightSpinnerId);
            }, 0);
        });
    }
    doSearchFlight() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const searchModel = yield this.getFlightSearchModel(this.currency);
            this.bookingFlowService.currency = this.currency;
            this.search.next(searchModel);
            this.flightSearch.showBundleUpgrade = true;
            this.setFlightsAndNavigate(searchModel);
        });
    }
    changeFlight() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentBooking = this.bookingService.getBooking();
            // if(currentBooking.bundleDetail.bundleCodes
            //currentBooking.bundleDetail.bundleCodes.   ABPS -< use special classes
            // currentBooking.cancelFlight // convertedJourney -> Fare classOf
            // this.isSpecialClassOfService = true;
            let changeFlight = () => tslib_1.__awaiter(this, void 0, void 0, function* () {
                // todo: modify this: send event to booking info component
                //
                this.loadingSpinnerService.showSpinnerById(this.changeFlightSpinnerId);
                // const result = await this.bookingService.clearSnapshot();
                this.bookingService.createSnapshot().then((data) => tslib_1.__awaiter(this, void 0, void 0, function* () {
                    if (data) {
                        const searchModel = yield this.getFlightSearchModel(currentBooking.convertedJourneys.currencyCode);
                        this.search.next(searchModel);
                        this.bookingService.refresh(true).then(res => {
                            if (res) {
                                this.bookingService.changeFlight(data.bookingDetail.recordLocator, searchModel, currentBooking.convertedJourneys.journeys, this.flightsToChange).then((result) => {
                                    if (result && result.starterCancelRebook.canCancel) {
                                        this.setFlightsAndNavigate(searchModel);
                                    }
                                    else {
                                        this.ChangeBookingNotAllowed.openPopup({});
                                    }
                                    this.loadingSpinnerService.hideSpinnerById(this.changeFlightSpinnerId);
                                });
                            }
                        });
                    }
                }));
            });
            this.setPassengerCountForChangeFlight(currentBooking);
            let showChangeBookingPopup = false;
            const journeysCount = currentBooking.convertedJourneys.journeys.length;
            if (this.isOneWay && journeysCount > 1) {
                showChangeBookingPopup = this.flightsToChange[0].journeySequenceNumber > 1 ?
                    moment(this.outboundDate, this.format).isBefore(moment(currentBooking.convertedJourneys.journeys[0].segments[0].sTD)) :
                    moment(this.outboundDate, this.format).isAfter(moment(currentBooking.convertedJourneys.journeys[journeysCount - 1].segments[0].sTD));
            }
            if (showChangeBookingPopup) {
                this.ChangeFlightWarningModal.openPopup(okSelected => {
                    if (okSelected) {
                        changeFlight();
                    }
                });
            }
            else {
                changeFlight();
            }
        });
    }
    setPassengerCountForChangeFlight(booking) {
        this.passengerSelection.adults = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.AdultPaxType).length;
        this.passengerSelection.youngAdults = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.YoungAdultPaxType).length;
        this.passengerSelection.children = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.ChildrenPaxType).length;
        this.passengerSelection.teens = booking.passengers.items.filter(pax => pax.typeInfo.paxType === Constants.TeenPaxType).length;
        this.passengerSelection.infants = booking.passengers.infants.items.length;
    }
    getFlightSearchModel(currencyCode) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const searchModel = new FlightSearchModel();
            if (this.isAgent) {
                const currentClassOfService = yield this.getCurrentBookingClassOfService();
                console.log('currentClassOfService ' + currentClassOfService);
                const hasSpecialClass = this.configService.config.classesOfService.special.indexOf(currentClassOfService) > -1;
                const hasPsoWorkerClass = this.configService.config.classesOfService.psoWorker.indexOf(currentClassOfService) > -1;
                searchModel.classOfService = this.getClassesOfServiceBySpecialPriceOption(hasSpecialClass ?
                    'specialPrice' :
                    hasPsoWorkerClass ? 'psoWorker' : this.selectedSpecialMarketOption);
            }
            searchModel.currency = currencyCode;
            searchModel.origin = this.departure;
            searchModel.destination = this.destination;
            searchModel.isRoundTrip = !this.isOneWay;
            searchModel.passengers = this.passengerSelection.getPassengerSearch();
            searchModel.setDepartureDate(moment(this.outboundDate, this.format).toDate());
            searchModel.usePromo = this.isPromoSelected;
            if (this.inbound) {
                searchModel.setReturnDate(moment(this.inboundDate, this.format).toDate());
            }
            return Promise.resolve(searchModel);
        });
    }
    setFlightsAndNavigate(searchModel) {
        this.flightSearch.searchModel = searchModel;
        this.bookingStepsService.goToStep(CheckinSteps.flights);
    }
    getCurrentBookingClassOfService() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const currentBooking = yield this.bookingService.refresh(true);
            if (currentBooking.cancelFlight.journeys.items.length == 0)
                return null;
            return currentBooking.cancelFlight.journeys.items[0].segments.items[0].fares.items[0].classOfService;
        });
    }
    getClassesOfServiceBySpecialPriceOption(specialMarketOption) {
        switch (specialMarketOption) {
            case 'noSpecialMarket':
                return this.configService.config.classesOfService.normal.join('_');
            case 'psoWorker':
                return this.configService.config.classesOfService.psoWorker.join('_');
            case 'specialPrice':
                return this.configService.config.classesOfService.special.join('_');
        }
    }
    getSpecialPriceCheckboxText() {
        return `Are you a resident in ${this.selectedMarketName}?`;
    }
    promoselect() {
        //this.isPromoSelected = !this.isPromoSelected;
        if (this.isPromoSelected) {
            this.totalPaxCount = this.totalPromoPaxCount;
        }
        else {
            this.totalPaxCount = 9;
        }
    }
    ok() {
        this.modalService.close('numberOfPaxExcededModal');
    }
    initUserData() {
        this.userStateSubscription = this.userStateService.isLoading
            .pipe(filter(isLoading => !isLoading), switchMap(() => this.userStateService.isLoggedIn))
            .subscribe(isLoggedIn => isLoggedIn && this.bookingService.getMemberDetails());
    }
    checkIfIsSpecialPrice() {
        if (!isUndefined(this.isOnFlightsPage) && !this.isOnFlightsPage)
            return;
        if (!isNullOrUndefined(this.destination)
            && !isNullOrUndefined(this.departure)) {
            const specialMarkets = this.configService.config.markets;
            const selectedMarket = specialMarkets.find(item => this.isDestinationOrDepartureInArray(item.routes));
            if (!isNullOrUndefined(selectedMarket)) {
                this.currentSelectedMarket = selectedMarket;
                this.showSpecialPrice = true;
                this.setSpecialPriceIframeUrl(selectedMarket);
                this.selectedMarketName = selectedMarket.marketName;
                this.psoWorkerEnabled = selectedMarket.psoWorkerEnabled;
                if (selectedMarket.overrideTotalPaxCount != null &&
                    selectedMarket.overrideTotalPaxCount.organizationCodes.includes(this.currentAgentOrganizationCode)) {
                    this.isEligibleForSpecialPaxCount = true;
                }
                else {
                    this.isEligibleForSpecialPaxCount = false;
                }
                return;
            }
        }
        this.showSpecialPrice = false;
    }
    computeMaxPaxCount() {
        if (this.isEligibleForSpecialPaxCount && this.isSpecialMarket) {
            const currentDatePlus7Days = moment().add(this.currentSelectedMarket.overrideTotalPaxCount.advancePurchase, 'days');
            const outboundDate = moment(this.outboundDate, this.format);
            if (outboundDate.isAfter(currentDatePlus7Days)) {
                this.totalPaxCount = this.currentSelectedMarket.overrideTotalPaxCount.paxCount;
                return;
            }
        }
        this.totalPaxCount = DEFAULT_PAX_NUMBER;
    }
    setSpecialPriceIframeUrl(selectedMarket) {
        const cmsConfig = this.configService.config.cms;
        if (isNullOrUndefined(cmsConfig) || isNullOrUndefined(selectedMarket)) {
            this.urlSafe = '';
            return;
        }
        const language = this.translateService.culture.split('-')[0];
        const iframeUrl = `${cmsConfig.baseUrl}/${language}/pso_${selectedMarket.marketName}`;
        const urlBuilder = new URL(iframeUrl);
        urlBuilder.searchParams.append('antiCacheKey', Date.now().toString());
        this.urlSafe = this.sanitizer
            .bypassSecurityTrustResourceUrl(urlBuilder.href);
    }
    isDestinationOrDepartureInArray(marketsList) {
        for (let i = 0; i < marketsList.length; i++) {
            const element = marketsList[i];
            if ((element.indexOf(this.destination) !== -1)
                && element.indexOf(this.departure) !== -1) {
                return true;
            }
        }
        return false;
    }
}
tslib_1.__decorate([
    SessionStorage(),
    tslib_1.__metadata("design:type", String)
], ChangeFlightComponent.prototype, "classOfService", void 0);
