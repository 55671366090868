<check-in-default-modal #changeBookingNotAllowed modalId="changeBookingNotAllowed"
    modalTitle="{{ 'Info' | translate:'change-flight' }}"
    modalText="{{ 'Change booking is not allowed!' | translate:'change-flight' }}"
    okButtonText="{{ 'OK' | translate:'change-flight' }}" [okRequired]="true" [cancelRequired]="false">
</check-in-default-modal>
<check-in-default-modal #changeFlightWarning modalId="changeFlightWarning"
    modalTitle="{{ 'ChangeFlightWarning-Title' | translate:'change-flight' }}"
    modalText="{{ 'ChangeFlightWarning-Text' | translate:'change-flight' }}"
    okButtonText="{{ 'ChangeFlightWarning-OkButton' | translate:'change-flight' }}" cancelButtonText=""
    [okRequired]="true" [cancelRequired]="false">
</check-in-default-modal>
<div class="changeFlight_wrapper" *ngIf="!showCmsView">
    <blue-loading-spinner [id]="changeFlightSpinnerId" [showSpinner]="false"></blue-loading-spinner>
    <div class="changeFlight_wrapper_row">
        <div class="changeFlight_wrapper_col currency-wrap" *ngIf="isForChangingFlight">
            <h1>{{ 'Change flight' | translate:'change-flight' }}</h1>
        </div>
        <div class="changeFlight_wrapper_col currency-wrap" *ngIf="!isForChangingFlight">
            <h1>{{ 'Select flight' | translate:'change-flight' }}</h1>
        </div>
        <div class="changeFlight_wrapper_col currency-wrap" *ngIf="!isForChangingFlight">
            <div class="currency for-agent" *ngIf="false">
                <label>{{'Service class' | translate: 'change-flight'}}</label>
                <blue-select-list [items]="classesOfService" [(ngModel)]="classOfService"></blue-select-list>
            </div>
            <div class="currency" [ngClass]="{'for-agent': isAgent}" *ngIf="false">
                <label class="">{{ 'Currency' | translate:'change-flight' }}</label>
                <blue-select-list [items]="availableCurrencies" [(ngModel)]="currency"></blue-select-list>
            </div>
        </div>
    </div>
    <div class="changeFlight_wrapper_row" *ngIf="!isForChangingFlight && isProfilePromoActive">
        <input id="selected-promo" type="checkbox" [(ngModel)]="isPromoSelected" (change)="promoselect()">
        <label for="selected-promo">{{ 'Use your rainbow pass' | translate:'change-flight' }}</label>
    </div>
    <div class="changeFlight_wrapper_row">
        <div class="changeFlight_wrapper_col">
            <label class="">{{ 'From' | translate:'change-flight' }}</label>
            <station-picker *ngIf="!isForChangingFlight;else noChangeStationDeparture" [superStations]="superStations"
                [(ngModel)]="departure" [stationPickerPair]="destinationStationPicker" #departureStationPicker
                placeholder="{{ 'Select Departure' | translate:'change-flight' }}"></station-picker>
            <ng-template #noChangeStationDeparture>
                <div class="origin-destination">{{ departure | translate:'station.name'}}</div>
            </ng-template>
        </div>
        <div class="changeFlight_wrapper_col">
            <label class="">{{ 'To' | translate:'change-flight' }}</label>
            <station-picker *ngIf="!isForChangingFlight;else noChangeStationArrival" [superStations]="superStations"
                [(ngModel)]="destination" [departure]="departure" [ignoreConnectionErrors]="ignoreConnectionErrors"
                [isDestination]="true" [stationPickerPair]="departureStationPicker"
                (select)="destinationStationSelected($event)" #destinationStationPicker
                placeholder="{{ 'Select Destination' | translate:'change-flight' }}"></station-picker>
            <ng-template #noChangeStationArrival>
                <div class="origin-destination">{{ destination | translate:'station.name'}}</div>
            </ng-template>
        </div>
    </div>
    <div class="changeFlight_wrapper_row" *ngIf="!isForChangingFlight">
        <input id="one-way" type="checkbox" [(ngModel)]="isOneWay" (change)="oneWayChanged()">
        <label for="one-way">{{ 'One-way' | translate:'change-flight' }}</label>
    </div>
    <div class="changeFlight_wrapper_row" *ngIf="showSpecialPrice">
        <ng-container *ngIf="!psoWorkerEnabled; else psoWorker">
            <input id="isSpecialPriceMarket" type="checkbox" [(ngModel)]="isSpecialMarket">
            <label for="isSpecialPriceMarket">{{ getSpecialPriceCheckboxText() | translate:'change-flight' }}</label>
        </ng-container>
        <ng-template #psoWorker>

            <div *ngFor="let priceOption of priceOptionList; let index = index">
                <input id="priceOption{{index}}" type="checkbox" [ngModel]="selectedIndex === index"
                    *ngIf="(selectedIndex !== 0) || index > 0" (change)="changePriceOptionSelection($event, index)" />
                <label for="priceOption{{index}}" class="form-check-label text-break"
                    *ngIf="(selectedIndex !== 0) || index > 0">

                    {{ priceOption.text | translate:'change-flight' }}
                </label>
            </div>
        </ng-template>
    </div>
    <div class="changeFlight_wrapper_row">
        <div class="changeFlight_wrapper_col">
            <div class="changeFlight_wrapper_row calendar-date-picker-container">
                <div class="changeFlight_wrapper_col" [ngClass]="{'full-width':isOneWay}">
                    <label class="">{{ 'calendarFrom' | translate:'change-flight' }}</label>
                    <calendar-date-picker [(ngModel)]="outboundDate" [minDate]="today" [rangeStart]="outboundDate"
                        [rangeEnd]="inboundDate" [departure]="departure" [destination]="destination" [format]="format"
                        (select)="outboundDateSelected($event)" #outboundDatePicker
                        header="{{'Select you departure date' | translate:'change-flight' }}"></calendar-date-picker>
                </div>
                <div class="changeFlight_wrapper_col" *ngIf="!isOneWay">
                    <label class="">{{ 'calendarTo' | translate:'change-flight' }}</label>
                    <calendar-date-picker [(ngModel)]="inboundDate" [minDate]="outboundDate || today"
                        [rangeStart]="outboundDate" [rangeEnd]="inboundDate" [departure]="destination"
                        [destination]="departure" (select)="inboundDateSelected($event)" [format]="format"
                        #inboundDatePicker header="{{'Select you return date' | translate:'change-flight' }}">
                    </calendar-date-picker>
                </div>
            </div>
        </div>
        <div class="changeFlight_wrapper_col" *ngIf="!isForChangingFlight">
            <label class="">{{ 'Passengers' | translate:'change-flight' }}</label>
            <passenger-type-picker [(ngModel)]="passengerSelection" #passengerTypePicker
                [totalAllowedPassengers]="totalPaxCount"></passenger-type-picker>
        </div>
    </div>
    <div class="changeFlight_wrapper_row center">
        <button *ngIf="!isForChangingFlight else isforChangingFlight" (click)="searchFlight()"
            class="btn btn-primary btn-big changeFlight_button" type="button">
            <span>{{ 'Search' | translate:'change-flight' }}</span>
        </button>
        <ng-template #isforChangingFlight>
            <button *ngIf="isOnFlightsPage else notOnFlightsPage" (click)="searchFlight()"
                class="btn btn-primary btn-big changeFlight_button" type="button">
                <span>{{ 'Change flight' | translate:'change-flight' }}</span>
            </button>
            <ng-template #notOnFlightsPage>
                <button (click)="changeFlight()" class="btn btn-primary btn-big changeFlight_button" type="button">
                    <span>{{ 'Change flight' | translate:'change-flight' }}</span>
                </button>
            </ng-template>
        </ng-template>
    </div>
</div>

<div>
    <check-in-default-modal #specialPriceModal modalId="specialPriceModalId"
        modalTitle="{{'TERRITORIAL CONTINUITY REGIME'|translate:'specialPrice'}}" modalText=""
        okButtonText="{{'I agree'|translate:'specialPrice'}}"
        cancelButtonText="{{'I do not agree'|translate:'specialPrice'}}" [okRequired]="true" [cancelRequired]="true">

        <div class="modal" style="overflow: auto; height: 450px;">
            <h2 class="section-title" style="font-weight: bold;">{{'The fares for flights operated under the territorial
                continuity regime apply to
                the following passengers:'| translate :'specialPrice'}}
            </h2>
            <div class="section-text">
                <h2 style="font-weight: bold;">{{'Sardinia Resident Passengers'|translate:'specialPrice'}}</h2>
                <ul>
                    <li>
                        {{'The disabled. (*) (**)'|translate:'specialPrice'}}
                    </li>
                    <li>{{'University students up to the age of 27. (*)'|translate:'specialPrice'}}</li>
                    <li>{{'Young people aged 2 to 21. (*)'|translate:'specialPrice'}}</li>
                    <li>{{'Children under the age of 2 travel for free if they do not occupy a seat. The above resident
                        fares are also reduced by 30% for children from 2 to 12 years of
                        age.'|translate:'specialPrice'}}</li>
                    <li>{{'Seniors over 70 years. (*)'|translate:'specialPrice'}}</li>
                </ul>
            </div>
            <div class="section-text">
                <div>{{'(*) Without any discrimination related to nationality and/or
                    residence.'|translate:'specialPrice'}}</div>
                <div>{{'(**) Disabled people with civil disability with a percentage at least equal to or greater than
                    80%
                    certified by a report issued by the medical commission showing the percentage of
                    disability.'|translate:'specialPrice'}}</div>
            </div>
            <div class="section-text">
                <h2 style="font-weight: bold;">{{'NON Sardina Resident Passengers'|translate:'specialPrice'}}'</h2>
                <ul>
                    <li>
                        {{'During the IATA Winter season, non-residents in Sardinia traveling on routes charged for work
                        reasons are subject to the same fares as for passengers residing in Sardinia identified in the
                        previous table.'|translate:'specialPrice'}}
                    </li>
                    <li>{{'Tickets issued for both Residents and non-Residents have no limitations and no restrictions
                        will apply to them, nor any penalty for changing date/time/itinerary and requesting or for
                        refund.'|translate:'specialPrice'}}</li>
                    <li>{{'To this end, when booking the ticket, the presentation of a declaration in lieu of an
                        affidavit must be requested, pursuant to and for the purposes of article 47 of D.P.R 445/2000,
                        as proof of the work reasons justifying travel.'|translate:'specialPrice'}}</li>
                    <li>{{'The rates applied to all non-residents in Sardinia, including those traveling for work
                        reasons, and with the sole exception of the categories equivalent to the residents indicated
                        above, are free in the IATA Summer season.'|translate:'specialPrice'}}</li>
                    <li>{{'The maximum discounted fares indicated above include VAT and are net of taxes and airport
                        charges.'|translate:'specialPrice'}}</li>
                </ul>


            </div>
            <div class="section-text">
                <h2 style="font-weight: bold;">{{'Self-Declaration Documentation:'|translate:'specialPrice'}}
                    <a target="_blank"
                        href="https://www.aeroitalia.com/Cms_Data/Contents/aeroitalia_it/Media/docs/Autocertificazione_continuita_territoriale.pdf">{{'Download'|translate:'specialPrice'}}</a>
                </h2>
                <ul>
                    <li>
                        {{'An identity document (or certificate of residence) proving the above conditions must be
                        presented at check-in before departure.'|translate:'specialPrice'}}
                    </li>
                    <li>{{'If a valid document is not presented, the passenger will have to buy a new ticket at an
                        unconventional fare in order to travel.'|translate:'specialPrice'}}</li>
                </ul>
            </div>
            <div class="section-text">
                <h2 style="font-weight: bold;">{{'Territorial Continuity passengers will benefit from a fare that
                    includes:'|translate:'specialPrice'}}
                </h2>
                <ul>
                    <li>
                        {{'One hold baggage delivered at check-in weighing no more than
                        23kg.'|translate:'specialPrice'}}
                    </li>
                    <li>{{'One small bag (40x30x20cm) and one hand luggage (55x40x20cm) in the cabin for a maximum of
                        10kg is included for each passenger.'|translate:'specialPrice'}}</li>
                    <li>{{'Free check-in.'|translate:'specialPrice'}}</li>
                </ul>
            </div>
        </div>

    </check-in-default-modal>
</div>

<blue-modal class="checked-in-summary-popup" id="numberOfPaxExcededModal">

    <div class="default-modal">
        <h2 class="section-title">{{'Number of pax exceeded the total allowed of passengers'| translate :'change-flight'}} :{{totalPaxCount}}
        </h2>
        <div class="warning-sign"></div>

        <div class="buttons">
            <button type="submit" class="btn btn-primary btn-small btn-left"
                attr.aria-label="{{ 'Ok' | translate:'shared' }}" (click)="ok()">
                {{'Ok' | translate:'shared' }}
            </button>

        </div>
    </div>

</blue-modal>
<div class="changeFlight-cmsView" *ngIf="showCmsView">
    <div class="changeFlight-cmsView-container">
        <iframe [src]="urlSafe"></iframe>
    </div>
    <div class="cmsView-btnContainer">
        <div class="changeFlight-cmsView-buttons">
            <button type="submit" class="btn btn-primary btn-small btn-left"
                attr.aria-label="{{ 'I agree' | translate:'shared' }}" (click)="continueSearchFlight()">
                {{'I agree' | translate:'shared' }}
            </button>

            <button type="submit" class="btn btn-primary btn-small btn-right inverted"
                attr.aria-label="{{ 'I do not agree' | translate:'shared' }}" (click)="cancelSearchFlight()">
                {{'I do not agree' | translate:'shared' }}
            </button>

        </div>
    </div>

</div>
