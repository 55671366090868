import * as tslib_1 from "tslib";
import { BookingFlightSelectService } from './../../booking-flight-select/booking-flight-select.service';
import { LoadingSpinnerService } from './../../common-modules/blue-air-common/loading-spinner.service';
import { take } from 'rxjs/operators';
import { BookingChangesService } from './../../core/booking-changes.service';
import { Constants } from '../../constants';
import { OnInit, Renderer2, ElementRef, OnDestroy } from '@angular/core';
import { BookingService } from '../../core/booking.service';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { CheckinSteps, BookingStepsService, ApplicationFlowEnum } from '../../core/booking-steps.service';
import { SsrType } from '../../extras/ssr-type.enum';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { environment } from '../../../environments/environment';
import { BookingFlowService } from '../../core/booking-flow.service';
import { ChangeBooking } from '../../core/models/booking-changes-model';
import { PaymentsService } from '../../summary/payments.service';
import { PassengerModel } from '../../core/models/passenger-model';
import { ConfigService } from 'src/app/core/config.service';
import { DefaultModalComponent } from 'src/app/shared/default-modal/default-modal.component';
import { VoucherRequestService } from '../../shared/voucher-request.service';
import { ProfileService } from 'src/app/core/profile.service';
import { ProfileInfoBox } from 'src/app/profile-management/profile-info-box';
import { BlueModalService } from 'src/app/common-modules/blue-air-common/blue-modal/blue-modal.service';
import { ResourceService } from 'src/app/common-modules/blue-air-common/resource.service';
import { ECommerceService, Transaction } from '../../shared/e-commerce/ecommerce.service';
import { ECommerceCartItem } from '../../shared/e-commerce/models/e-commerce-cart-item.model';
var BookingInfoComponent = /** @class */ (function (_super) {
    tslib_1.__extends(BookingInfoComponent, _super);
    function BookingInfoComponent(bookingService, bookingFlowService, router, steps, translateService, renderer, bookingChangesService, loadingSpinnerService, activatedRoute, paymentsService, ecommerce, configService, bookingFlightSelectService, profileService, voucherRequestService, resourceService, modalService) {
        var _this = _super.call(this, modalService) || this;
        _this.bookingService = bookingService;
        _this.bookingFlowService = bookingFlowService;
        _this.router = router;
        _this.steps = steps;
        _this.translateService = translateService;
        _this.renderer = renderer;
        _this.bookingChangesService = bookingChangesService;
        _this.loadingSpinnerService = loadingSpinnerService;
        _this.activatedRoute = activatedRoute;
        _this.paymentsService = paymentsService;
        _this.ecommerce = ecommerce;
        _this.configService = configService;
        _this.bookingFlightSelectService = bookingFlightSelectService;
        _this.profileService = profileService;
        _this.voucherRequestService = voucherRequestService;
        _this.resourceService = resourceService;
        _this.currentStep = CheckinSteps.itinerary;
        _this.ChangeBooking = ChangeBooking;
        _this.journeys = [];
        _this.passengers = [];
        _this.passengersInformation = [];
        _this.temporaryBookingContacts = [];
        _this.passengersEmailAddresses = [];
        _this.contacts = [];
        _this.payments = [];
        _this.bookingCurrency = '';
        _this.passengerSsrs = [];
        _this.totalFeeAmounts = [];
        _this.changeDateActive = false;
        _this.changeFlight = new ChangeFlightModel();
        _this.hideModifyPaxFlights = false;
        _this.noFlightAvailable = false;
        _this.isCorporateBundle = false;
        _this.showPaxOnLockFare = true;
        _this.showExtrasOnLockFare = true;
        _this.ssrChanges = [];
        _this.seatChanges = [];
        _this.insuranceChanges = [];
        _this.hasInsuranceFee = false;
        _this.hasInsuranceQueueCode = false;
        _this.flightsToChange = [];
        _this.paymentAccounts = [];
        _this.recordLocators = [];
        _this.recordCodes = [];
        _this.hidePrices = true;
        _this.typeCounter = { adtCount: 0, chdCount: 0, infCount: 0 };
        _this.voucherRequest = true;
        _this.message = "";
        _this.refuntToWalletEligibilityModalId = 'refuntToWalletEligibilityModal';
        _this.restrictedBookingModalId = 'restrictedBookingModal';
        _this.insuranceModalId = 'insuranceModal';
        _this.canceledBookingModalId = 'canceledBookingModal';
        _this.restrictionMessage = "";
        _this.editModalId = 'contact-infos';
        _this.model = {};
        _this.contactInfos = {};
        _this.titles = [];
        _this.languages = [];
        _this.currencies = [];
        _this.errors = [];
        _this.phonePattern = '^[0-9+()\/-][0-9+() \/-]{0,19}?$';
        _this.emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$';
        _this._canDeactivate = false;
        _this.airportTransferExternalUrl = null;
        steps.currentStep.next(_this.currentStep);
        _this.updateWindowWidth(window.innerWidth);
        _this.assetsPath = environment.assetsPath;
        _this.activatedRoute.queryParams.pipe(take(1)).subscribe(function (params) {
            if (params) {
                _this.selectedPaymentType = _this.paymentsService.selectedPaymentMethod;
                _this.emailSent = params['em'] === 'true';
                _this.router.navigate([], { replaceUrl: true });
            }
        });
        _this.isOnLockFareFlow = _this.steps.flow === ApplicationFlowEnum.FinalizeLockFare;
        return _this;
    }
    BookingInfoComponent.prototype.onResize = function (event) {
        this.updateWindowWidth(event.target.innerWidth);
    };
    Object.defineProperty(BookingInfoComponent.prototype, "CarRentalUrl", {
        get: function () {
            return "https://aeroitaliamediaassets.blob.core.windows.net/itinerary/rentalplus/rentalplus.jpg";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BookingInfoComponent.prototype, "MaggioreUrl", {
        get: function () {
            return this.translateService.culture.match('it-IT') ?
                "https://aeroitaliamediaassets.blob.core.windows.net/itinerary/Maggiore/ita/Maggiore_aeroitalia_1200x280.jpg" :
                "https://aeroitaliamediaassets.blob.core.windows.net/itinerary/Maggiore/eng/Maggiore_aeroitalia_ENG_1200x280.jpg";
        },
        enumerable: true,
        configurable: true
    });
    BookingInfoComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var restrictedAgents;
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.addRecaptchaScript();
                        return [4 /*yield*/, this.resourceService.getRefundToWalletAgentDisplayRestrictions()];
                    case 1:
                        restrictedAgents = _a.sent();
                        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
                        this.bookingService.loadItineraryPage()
                            .then(function () { return _this.bookingFlowService.loadFlowInfo(); })
                            .then(function (bookingResponse) {
                            _this.booking = bookingResponse;
                            _this.fistJourney = _this.booking.convertedJourneys.journeys[0];
                            _this.recordLocator = _this.booking.bookingDetail.recordLocator;
                            _this.hidePrices = _this.booking.bookingDetail.pointOfSale.domainCode == 'EXT' && !_this.profileService.isAgent;
                            if (_this.booking && _this.booking.bookingDetail && _this.booking.bookingDetail.recordLocators && _this.booking.bookingDetail.recordLocators.length > 0) {
                                _this.recordLocators = _this.booking.bookingDetail.recordLocators;
                                _this.isRestricted = _this.isRestrictedBooking();
                            }
                            _this.getRecordCodes();
                            _this.journeys = _this.booking.convertedJourneys.journeys;
                            _this.isCanceledBookingStatus = _this.checkBookingStatus();
                            _this.moveFlight = _this.bookingFlightSelectService.isMoveFlightsCriteria(_this.journeys);
                            _this.voucherRequest = false; // this.bookingFlightSelectService.isMoveFlightsCriteria(this.journeys);
                            // this.pushToWallet = false;
                            var isRestrictedAgent = restrictedAgents.some(function (agent) {
                                return agent.agent == _this.booking.bookingDetail.pointOfSale.agentCode &&
                                    agent.domainCode == _this.booking.bookingDetail.pointOfSale.domainCode;
                            });
                            if (_this.profileService.isAgent || isRestrictedAgent) {
                                _this.pushToWallet = false;
                            }
                            else {
                                _this.pushToWallet = _this.journeys && _this.journeys.some(function (j) {
                                    return j.segments &&
                                        ((j.segments.some(function (s) { return s.legs && s.legs.some(function (l) { return l.legInfo.status === 'Canceled'; }); }) &&
                                            j.segments.every(function (s) { return s.paxSegments.every(function (ps) { return ps.liftStatus !== 'Boarded' && ps.liftStatus !== 'NoShow'; }); })) ||
                                            j.segments && j.segments.some(function (s) { return s.changeReasonCode && s.changeReasonCode == "SCHG" && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3; }) ||
                                            j.segments && j.segments.some(function (s) { return (!s.changeReasonCode || s.changeReasonCode && s.changeReasonCode != "SCHG") && s.paxSegments.some(function (p) { return p.timeChanged; }) && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3; }));
                                });
                            }
                            if (_this.profileService.isAgent) {
                                _this.flightStatusAffectedMessage = _this.getFlightStatusMessage();
                            }
                            if (_this.booking.passengers && _this.booking.passengers.items && _this.booking.passengers.items.length > 0) {
                                _this.passengers = _this.booking.passengers.items;
                            }
                            if (_this.booking.passengers && _this.booking.passengers.items && _this.booking.passengers.items.length > 0) {
                                _this.hasInsuranceFee = _this.passengers.some(function (p) { return p.fees && p.fees.items.some(function (f) { return f.code == "XCOVT1"; }); });
                            }
                            if (_this.booking.bookingQueueInfos && _this.booking.bookingQueueInfos.items && _this.booking.bookingQueueInfos.items.length > 0) {
                                _this.hasInsuranceQueueCode = _this.booking.bookingQueueInfos.items.some(function (q) { return q.queueCode == "INS"; });
                            }
                            if (_this.isOnLockFareFlow) {
                                _this.passengers.forEach(function (pax) {
                                    pax.isAutoCompleted = _this.isAutoCompletedField(pax.name.first)
                                        && _this.isAutoCompletedField(pax.name.last);
                                    _this.setPropsForType(pax);
                                    if (pax.hasInfant) {
                                        pax.infant.isAutoCompleted = _this.isAutoCompletedField(pax.infant.name.first)
                                            && _this.isAutoCompletedField(pax.infant.name.last);
                                        _this.setPropsForType(pax.infant, true);
                                    }
                                });
                                _this.showPaxOnLockFare = _this.passengers.some(function (x) { return !x.isAutoCompleted; })
                                    || _this.passengers.filter(function (x) { return x.hasInfant; }).map(function (x) { return x.infant; }).some(function (x) { return !x.isAutoCompleted; });
                                _this.lockedFarePaymentDueDate = moment
                                    .utc(_this.booking.bookingDetail.createdDate, 'YYYY-MM-DD HH:mm:ss')
                                    .add(48, 'hours')
                                    .local();
                            }
                            if (_this.isRestricted) {
                                _this.contacts = _this.booking.bookingContacts.items.filter(function (c) { return c.typeCode == 'G'; });
                            }
                            else {
                                _this.contacts = _this.booking.bookingContacts.items.filter(function (c) { return c.typeCode != 'I'; }); //remove the I - invoice contacts
                            }
                            _this.passengerSsrs = _this.booking.passengerSsrs.items;
                            _this.bookingCurrency = _this.booking.convertedJourneys.currencyCode;
                            _this.paymentDue = _this.booking.payments.balanceDue;
                            _this.calculatePaymentsArrayByPaymentType(_this.booking.payments.items);
                            _this.loadMemberPromotionActivationInfo();
                            var firstFutureJourney = _this.journeys
                                .find(function (x) { return moment(x.segments[0].sTD).utcOffset(x.segments[0].legs[0].legInfo.deptLTV, true)
                                .isAfter(moment()); });
                            _this.firstArrivalStation = firstFutureJourney ? firstFutureJourney.segments[firstFutureJourney.segments.length - 1].arrivalStation : '';
                            _this.insuranceTotal = _this.calculateInsuranceTotal();
                            _this.getSsrChanges();
                            _this.setHiddenButtons(_this.booking);
                            _this.multiJourney = _this.journeys.length > 2;
                        })
                            .then(function () { return _this.bookingService.manageBookingPriceBreakdown().then(function (response) {
                            if (response) {
                                _this.fareTaxesTotal = _this.calculateFareTaxesTotal(response.starterPriceBreakdown);
                                _this.bundleUpgradeTotal = _this.calculateBundleUpgradeTotal(response.starterPriceBreakdown);
                                _this.spoilageFeesOneTotal = _this.calculateExtrasSpoilageFeesTotal(response.starterPriceBreakdown);
                                _this.nameChangeFeesTotal = _this.calculateNameChangeFeesTotal(response.starterPriceBreakdown);
                                _this.dateChangeFees = _this.calculateDateChangeFeesTotal(response.starterPriceBreakdown);
                                _this.spoilageFeesTwoTotal = _this.calculateFareSpoilageFeesTotal(response.starterPriceBreakdown);
                                _this.fareTotal = _this.calculateFareTotal(response.starterPriceBreakdown);
                                _this.seatTotal = _this.calculateSeatTotal(response.starterPriceBreakdown);
                                _this.grandTotal = _this.calculateGrandTotal(response.starterPriceBreakdown);
                            }
                        }); })
                            .then(function () { return _this.paymentDue ? _this.getPaymentAccounts() : null; })
                            .then(function () { return _this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId); })
                            .then(function () {
                            var ecommerceCartItems = [];
                            var storage_breakdown = sessionStorage.getItem(Constants.CartBreakdown);
                            var breakdown = storage_breakdown && JSON.parse(sessionStorage.getItem(Constants.CartBreakdown));
                            if (breakdown && breakdown.currentShoppingCart.flights && (window.location.href.indexOf('thank-you') > -1)) {
                                ecommerceCartItems = _this.ecommerce.getAllCartDataForECommerce(breakdown, _this.isOnLockFareFlow);
                                var grandTotal_1 = 0;
                                if (_this.steps.flow === ApplicationFlowEnum.Booking) {
                                    grandTotal_1 = _this.grandTotal;
                                }
                                else if (_this.steps.flow === ApplicationFlowEnum.ManageBooking) {
                                    ecommerceCartItems.forEach(function (cartItem) {
                                        grandTotal_1 += Number(cartItem.price) * cartItem.quantity;
                                    });
                                }
                                var transaction = new Transaction(_this.recordLocator + '_' + Date.now(), ApplicationFlowEnum[_this.steps.flow], grandTotal_1.toString(), _this.fareTaxesTotal.toString(), '0', '').getItem();
                                if (_this.isOnLockFareFlow) {
                                    transaction.revenue = _this.payments[0].paymentAmount;
                                    ecommerceCartItems[0].price = '0';
                                    transaction.tax = '0';
                                    ecommerceCartItems.push(new ECommerceCartItem('LockFareFee', ecommerceCartItems[0].id, _this.payments[0].paymentAmount, 'BlueAir', 'LockFare', 'LockFare', 1, null, null, _this.ecommerce.getUserData()).getItem(false));
                                }
                                _this.ecommerce.Purchases(ecommerceCartItems, transaction);
                                sessionStorage.removeItem(Constants.CartBreakdown);
                            }
                        });
                        // this.voucherForm.form.addControl('key', new FormControl("", Validators.required));
                        this.airportTransferExternalUrl = this.getAirportTransferURL();
                        return [2 /*return*/];
                }
            });
        });
    };
    BookingInfoComponent.prototype.ngOnDestroy = function () {
        if (this.changesSubscription) {
            this.changesSubscription.unsubscribe();
        }
        if (this.profileSubscription) {
            this.profileSubscription.unsubscribe();
        }
    };
    BookingInfoComponent.prototype.addRecaptchaScript = function () {
        var _this = this;
        window['grecaptchaCallback'] = function () {
            _this.renderReCaptcha();
        };
        (function (d, s, id, obj) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'recaptcha-jssdk', this));
    };
    BookingInfoComponent.prototype.renderReCaptcha = function () {
        var _this = this;
        window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
            'sitekey': '6LfOoPYUAAAAAOEugDbmqKTiUJaxZ_IgMyB-RDA4',
            'callback': function (response) {
                _this.message = response;
                //return response;
            }
        });
    };
    BookingInfoComponent.prototype.requestVoucher = function () {
        var _this = this;
        this.voucherModal.openPopup(function (modal) {
            if (_this.message.length != 0) {
                _this.voucherRequestService.createRequest(_this.recordLocator, _this.message);
            }
            else {
                modal.IsOpen = true;
            }
        });
    };
    BookingInfoComponent.prototype.calculatePaymentsArrayByPaymentType = function (paymentsItems) {
        var _this = this;
        // iterate through all payment items from booking
        paymentsItems.forEach(function (paymentItem) {
            // check and find if the current iteration item is of type wallet
            var walletPaymentItem = paymentItem.paymentFields
                .find(function (paymentField) { return BookingInfoComponent.WalletValue.indexOf(paymentField.fieldValue) > -1; });
            // check and find if in this.payments array an item on type wallet exists.
            var existingPaymentWallet = _this.payments.find(function (payment) { return payment.paymentFields.find(function (paymentField) { return BookingInfoComponent.WalletValue.indexOf(paymentField.fieldValue) > -1; }); });
            // if it does add the amount of walletPaymentItem on top of it and not create a new item in the this.payment array
            if (walletPaymentItem && existingPaymentWallet) {
                paymentItem.isWallet = true;
                existingPaymentWallet.paymentAmount += paymentItem.paymentAmount;
            }
            else {
                // if it does not create a new item of type wallet on this.payment array
                if (walletPaymentItem) {
                    paymentItem.isWallet = true;
                }
                else {
                    // if payment is not of type wallet add it on this.payments array with isWallet prop false
                    paymentItem.isWallet = false;
                }
                _this.payments.push(Object.assign({}, paymentItem));
            }
        });
    };
    BookingInfoComponent.prototype.setHiddenButtons = function (bookingResponse) {
        var _this = this;
        this.isCorporateBundle = bookingResponse.bundleDetail.bundleCodes
            .reduce(function (a, b) { return a.concat(b); }, [])
            .map(function (x) { return x.value; })
            .some(function (x) { return Constants.CorporateBundles.includes(x); });
        this.noFlightAvailable = bookingResponse.convertedJourneys.journeys.every(function (x) { return !_this.isJourneyChangeAllowed(x); });
        // at least one passenger has not been checked in on any flight
        var allPaxCheckedIn = true;
        var checkedInPaxJourneys = bookingResponse.assignedSeats.journeys.items.map(function (x) {
            return x.segments.items
                .reduce(function (a, b) { return a.concat(b); }, [])
                .map(function (y) { return y.passengerSegments.items; })
                .reduce(function (a, b) { return a.concat(b); }, [])
                .filter(function (z) { return z.liftStatus !== Constants.NotCheckedIn; })
                .reduce(function (a, b) { return a.concat(b); }, [])
                .map(function (t) { return t.passengerNumber; });
        });
        //check if at least one journey has no pax checked-in
        allPaxCheckedIn = checkedInPaxJourneys.every(function (data) { return data.length !== 0; });
        this.hideModifyPaxFlights = allPaxCheckedIn || this.noFlightAvailable;
    };
    BookingInfoComponent.prototype.getSsrChanges = function () {
        var _this = this;
        this.bookingChangesService.getSsrChanges(this.journeys, this.passengers, null, true);
        this.changesSubscription = this.bookingChangesService.changesObs.subscribe(function (changes) {
            if (changes) {
                _this.ssrChanges = _this.bookingChangesService.ssrChanges;
                if (_this.isOnLockFareFlow) {
                    _this.showExtrasOnLockFare = _this.ssrChanges.some(function (x) { return x.passengerSsrsByType.length > 0; });
                }
                _this.seatChanges = _this.bookingChangesService.seatChanges;
                _this.insuranceChanges = _this.bookingChangesService.insuranceChanges;
                _this.totalFeeAmounts = _this.getTotalFeeAmounts(_this.passengers);
            }
        });
    };
    BookingInfoComponent.prototype.getSegCount = function () {
        return "extrasSummary_wrapper segm" + this.journeys.map(function (p) { return p.segments; }).reduce(function (a, b) { return a.concat(b); }, []).length;
    };
    BookingInfoComponent.prototype.isBookingOnHold = function () {
        if (this.booking)
            return this.booking.bookingDetail.status == "Hold";
        return false;
    };
    BookingInfoComponent.prototype.getStation = function (i, journey) {
        if (journey) {
            if (i == 0)
                return journey.segments[0].departureStation;
            if (journey.segments.length === 1)
                return journey.segments[0].arrivalStation;
            return journey.segments[journey.segments.length - 1].arrivalStation;
        }
    };
    BookingInfoComponent.prototype.updateWindowWidth = function (width) {
        this.mobileMatches = width < Constants.mobileScreenWith;
    };
    BookingInfoComponent.prototype.changeThisFlight = function (journey, index, event) {
        var _this = this;
        var target = event.target || event.srcElement;
        if (target.checked) {
            this.flightsToChange.push(journey);
        }
        else if (this.flightsToChange.indexOf(journey) !== -1) {
            this.flightsToChange.splice(this.flightsToChange.indexOf(journey), 1);
        }
        this.bookingService.flightsToChangeObs.next(this.flightsToChange);
        this.ignoreConnectionErrors = true;
        this.changeFlight.toggleJourney(index, this.getStation(0, journey), this.getStation(1, journey), moment(journey.segments[0].sTD));
        setTimeout(function () { return _this.ignoreConnectionErrors = false; }, 0);
    };
    BookingInfoComponent.prototype.initPushToWallet = function (event) {
        var _this = this;
        if (!this.isRefundToWalletEligibleSegment()) {
            this.refuntToWalletEligibilityModal.openPopup(this.refuntToWalletEligibilityModalId);
            return;
        }
        if (this.journeys.some(function (j) { return j.segments.some(function (s) { return s.paxSSRs.find(function (ssr) { return ssr.sSRCode.match("INS1"); }); }); })) {
            this.insuranceAlertModal.openPopup(function (isOk) {
                _this._canDeactivate = isOk;
                if (isOk) {
                    _this.router.navigate(['/ptw']);
                }
            });
            return;
        }
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        this.router.navigate(['/ptw']);
    };
    BookingInfoComponent.prototype.isRefundToWalletEligibleSegment = function () {
        return (this.journeys.some(function (j) { return j.segments && j.segments.some(function (s) { return s.liftStatus !== 'CheckedIn'; }); })) ? true : false;
    };
    BookingInfoComponent.prototype.isJourneyChangeAllowed = function (journey) {
        for (var i = 0; i < journey.segments.length; i++) {
            if (journey.segments[i].segmentType === 'P'
                || journey.segments[i].paxSegments.some(function (ps) { return ps.liftStatus && ps.liftStatus !== Constants.NotCheckedIn; })) {
                return false;
            }
        }
        var cancelDepartureTimeLimit = this.booking.convertedJourneys.cancelDepartureTimeLimit.split(':');
        return moment(journey.segments[0].legs[0].sTD).utcOffset(journey.segments[0].legs[0].legInfo.deptLTV, true)
            .isSameOrAfter(moment().add(Number(cancelDepartureTimeLimit[0]), 'hour')
            .add(Number(cancelDepartureTimeLimit[1]), 'minute')
            .add(Number(cancelDepartureTimeLimit[2]), 'seconds'));
    };
    BookingInfoComponent.prototype.getFlightDates = function () {
        if (this.booking) {
            var departureDate = this.booking.convertedJourneys.journeys.map(function (s) { return s.segments; }).reduce(function (a, b) { return a.concat(b); }, []).map(function (s) { return moment(s.sTD); }).reduce(function (a, b) { return b.isBefore(a) ? b : a; });
            var arrivalDate = this.booking.convertedJourneys.journeys.map(function (s) { return s.segments; }).reduce(function (a, b) { return a.concat(b); }, []).map(function (s) { return moment(s.sTA); }).reduce(function (a, b) { return b.isBefore(a) ? a : b; });
            //Fri, 21 Sep 2018
            if (this.booking.convertedJourneys.journeys[0].journeyTripType == Constants.OneWayTrip)
                return departureDate.format("dddd, DD MMM  YYYY");
            else
                return "" + departureDate.format("dddd, DD MMM  YYYY") + " - " + arrivalDate.format("dddd, DD MMM  YYYY");
        }
    };
    BookingInfoComponent.prototype.getDepartureDate = function (journey) {
        return moment(journey.segments[0].sTD).format("dddd ,DD MMM  YYYY");
    };
    BookingInfoComponent.prototype.changeBooking = function (page) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.bookingFlowService.loadFlowInfo().then(function () { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return tslib_1.__generator(this, function (_a) {
                        this.setRestrictionMessage();
                        // to do: handle event from change flight component
                        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
                        // const snapShotResult = await this.bookingService.clearSession()
                        this.bookingService.createSnapshot().then(function (snapshot) {
                            if (snapshot) {
                                _this.bookingService.refresh(true).then(function (booking) {
                                    if (booking) {
                                        _this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);
                                        switch (page) {
                                            case ChangeBooking.flight:
                                                if (_this.hasInsuranceFee || _this.hasInsuranceQueueCode) {
                                                    _this.page = page;
                                                    _this.insuranceMesage = "You booked Travel Insurance for this booking. Please be aware that you should contact our insurance provider to update your policy in case the dates of your trip change.";
                                                    _this.insuranceModal.openPopup(_this.insuranceModalId);
                                                }
                                                else {
                                                    if (_this.isRestricted) {
                                                        _this.restrictedBookingModal.openPopup(_this.restrictedBookingModalId);
                                                    }
                                                    _this.changeDateActive = true;
                                                }
                                                break;
                                            case ChangeBooking.luggage:
                                                if (_this.areCanceledBookingStatusAll()) {
                                                    _this.canceledBookingModal.openPopup(_this.canceledBookingModalId);
                                                    _this.bookingService.clearSnapshot();
                                                    break;
                                                }
                                                _this.steps.goToStep(CheckinSteps.luggage);
                                                break;
                                            case ChangeBooking.seats:
                                                if (_this.areCanceledBookingStatusAll()) {
                                                    _this.canceledBookingModal.openPopup(_this.canceledBookingModalId);
                                                    _this.bookingService.clearSnapshot();
                                                    break;
                                                }
                                                _this.steps.goToStep(CheckinSteps.seat);
                                                break;
                                            case ChangeBooking.passengers:
                                                if (_this.hasInsuranceFee || _this.hasInsuranceQueueCode) {
                                                    _this.page = page;
                                                    _this.insuranceMesage = "You booked Travel Insurance for this booking. Please be aware that you should contact our insurance provider to update your policy in case the name of the passengers from your trip change.";
                                                    _this.insuranceModal.openPopup(_this.insuranceModalId);
                                                }
                                                else {
                                                    if (_this.isRestricted) {
                                                        _this.restrictedBookingModal.openPopup(_this.restrictedBookingModalId);
                                                        _this.bookingService.clearSnapshot();
                                                    }
                                                    else if (_this.isCanceledBookingStatus || _this.areCanceledBookingStatusAll()) {
                                                        _this.canceledBookingModal.openPopup(_this.canceledBookingModalId);
                                                        _this.bookingService.clearSnapshot();
                                                    }
                                                    _this.bookingFlowService.scrollToPassengersObs.next(_this.page);
                                                    _this.steps.goToStep(CheckinSteps.passengers);
                                                }
                                                break;
                                            case ChangeBooking.contact:
                                                if (_this.isRestricted) {
                                                    _this.restrictedBookingModal.openPopup(_this.restrictedBookingModalId);
                                                    break;
                                                }
                                                else if (_this.isCanceledBookingStatus || _this.areCanceledBookingStatusAll()) {
                                                    _this.canceledBookingModal.openPopup(_this.canceledBookingModalId);
                                                    _this.bookingService.clearSnapshot();
                                                    break;
                                                }
                                                _this.bookingFlowService.scrollToPassengersObs.next(page);
                                                _this.steps.goToStep(CheckinSteps.passengers);
                                                break;
                                        }
                                    }
                                });
                            }
                        });
                        return [2 /*return*/];
                    });
                }); });
                return [2 /*return*/];
            });
        });
    };
    BookingInfoComponent.prototype.isRestrictedBooking = function () {
        return (this.recordLocators.some(function (r) { return r.owningSystemCode !== null && r.owningSystemCode !== undefined; })) ? true : false;
    };
    BookingInfoComponent.prototype.checkBookingStatus = function () {
        var e_1, _a, e_2, _b, e_3, _c;
        try {
            // to be merged
            for (var _d = tslib_1.__values(this.journeys), _e = _d.next(); !_e.done; _e = _d.next()) {
                var j = _e.value;
                try {
                    for (var _f = tslib_1.__values(j.segments), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var s = _g.value;
                        try {
                            for (var _h = tslib_1.__values(s.legs), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var l = _j.value;
                                if (l.legInfo.status === "Canceled") {
                                    this.departureJourneySegment = s.departureStation;
                                    this.arrivalJourneySegment = s.arrivalStation;
                                    return true;
                                }
                            }
                        }
                        catch (e_3_1) { e_3 = { error: e_3_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_3) throw e_3.error; }
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return false;
    };
    BookingInfoComponent.prototype.areCanceledBookingStatusAll = function () {
        var e_4, _a, e_5, _b, e_6, _c;
        // to be merged
        var canceledJourneysCounter = 0;
        var isCanceledJourney;
        try {
            for (var _d = tslib_1.__values(this.journeys), _e = _d.next(); !_e.done; _e = _d.next()) {
                var j = _e.value;
                try {
                    for (var _f = tslib_1.__values(j.segments), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var s = _g.value;
                        try {
                            for (var _h = tslib_1.__values(s.legs), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var l = _j.value;
                                if (l.legInfo.status === "Canceled") {
                                    isCanceledJourney = true;
                                }
                            }
                        }
                        catch (e_6_1) { e_6 = { error: e_6_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_6) throw e_6.error; }
                        }
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
                if (isCanceledJourney) {
                    canceledJourneysCounter++;
                    isCanceledJourney = false;
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_4) throw e_4.error; }
        }
        if (canceledJourneysCounter >= 2) {
            this.areCanceledFlights = true;
            return true;
        }
        return false;
    };
    BookingInfoComponent.prototype.getRecordCodes = function () {
        var e_7, _a;
        try {
            for (var _b = tslib_1.__values(this.recordLocators), _c = _b.next(); !_c.done; _c = _b.next()) {
                var r = _c.value;
                this.recordCodes.push(r.recordCode);
            }
        }
        catch (e_7_1) { e_7 = { error: e_7_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_7) throw e_7.error; }
        }
    };
    BookingInfoComponent.prototype.setRestrictionMessage = function () {
        if (this.isRestricted) {
            if (this.profileService.isAgent) {
                this.restrictionMessage = "This booking does not allow changes through the website. Please use the original booking channel.";
            }
            else if (this.recordLocators.some(function (r) { return r.owningSystemCode.includes("ACE"); })) {
                this.restrictionMessage = "This booking does not allow changes through the website since there are other carriers involved in the itinerary. Please call to the phone provided in the booking confirmation.";
            }
            else {
                this.restrictionMessage = "This booking does not allow changes through the website. Please contact your Travel Agency. Note any additional service added on our website should be booked again after changing your flight.";
            }
        }
    };
    BookingInfoComponent.prototype.setPassengerInformation = function (passenger, inputValue, isPhoneNumber) {
        if (passenger === void 0) { passenger = {}; }
        if (inputValue === void 0) { inputValue = ""; }
        if (isPhoneNumber === void 0) { isPhoneNumber = false; }
        this.passengersInformation.push({ passenger: passenger, inputValue: inputValue, isPhoneNumber: isPhoneNumber });
    };
    BookingInfoComponent.prototype.edit = function () {
        this.setPassengersAddressesDefaultData();
        this.openModal();
    };
    BookingInfoComponent.prototype.save = function () {
        var _this = this;
        this.setPassengersInformation();
        this.saveBookingContacts();
        this.bookingService.savePassengers(this.passengers, [])
            .then(function () { _this.steps.goToStep(CheckinSteps.summary); });
        this.closeModal();
    };
    BookingInfoComponent.prototype.cancel = function () {
        this.closeModal();
    };
    BookingInfoComponent.prototype.setPassengersAddressesDefaultData = function () {
        for (var p = 0; p < this.passengers.length; p++) {
            if (!this.passengers[p].addresses.items || this.passengers[p].addresses.items.length < 1) {
                this.passengers[p].addresses.items.push({
                    emailAddress: "",
                    phoneNumber: "",
                    typeCode: "C"
                });
            }
        }
    };
    BookingInfoComponent.prototype.setPassengersInformation = function () {
        var _this = this;
        this.passengers.forEach(function (p) {
            _this.passengersInformation.forEach(function (i) {
                if (p.passengerNumber == i.passenger.passengerNumber) {
                    if (i.isPhoneNumber) {
                        p.addresses.items[0].phoneNumber = i.inputValue.trim();
                    }
                    else {
                        p.addresses.items[0].emailAddress = i.inputValue.trim();
                    }
                    _this.setBookingContact(p);
                }
            });
        });
    };
    BookingInfoComponent.prototype.setBookingContact = function (passenger) {
        if (passenger === void 0) { passenger = {}; }
        var contact = { name: {} };
        contact.typeCode = Constants.TypeCodes[passenger.passengerNumber];
        contact.homePhone = passenger.addresses.items[0].phoneNumber ? passenger.addresses.items[0].phoneNumber : "";
        contact.emailAddress = passenger.addresses.items[0].emailAddress ? passenger.addresses.items[0].emailAddress : "";
        contact.name.first = passenger.name.first ? passenger.name.first : "";
        contact.name.last = passenger.name.last ? passenger.name.last : "";
        contact.distributionOption = "Email";
        contact.addressLine1 = "";
        contact.addressLine2 = "";
        contact.addressLine3 = "";
        contact.city = "";
        contact.companyName = "";
        contact.countryCode = "";
        contact.countryProvinceState = "";
        contact.customerNumber = "";
        contact.faxNumber = "";
        contact.otherPhone = "";
        contact.postalCode = "";
        contact.provinceState = "";
        contact.receivePromotional = false;
        contact.sourceOrganization = "";
        contact.workPhone = "";
        this.temporaryBookingContacts.push(contact);
    };
    BookingInfoComponent.prototype.saveBookingContacts = function () {
        var _this = this;
        var steadyBookingContacts = [];
        this.temporaryBookingContacts.reverse().forEach(function (s) {
            if (!steadyBookingContacts.some(function (t) { return t.typeCode == s.typeCode; })) {
                steadyBookingContacts.push(s);
            }
        });
        steadyBookingContacts.forEach(function (contact) {
            _this.bookingService.saveContact(contact);
        });
    };
    BookingInfoComponent.prototype.formatDate = function (date, format) {
        return moment(date).format(format);
    };
    /**
     * Get total fee amounts from passengers as an array filtered by SSRs and amount paid
     * @param passengers Array of passengers
    */
    BookingInfoComponent.prototype.getTotalFeeAmounts = function (passengers) {
        var paxFeeTotals = new Array();
        for (var ssrType in SsrType) {
            if (isNaN(Number(ssrType))) {
                var type = SsrType[ssrType.toString()];
                if (type !== SsrType.Seat) {
                    var ssrTypeList = this.getSSRTypeListFromNest(type);
                    if (ssrTypeList.length > 0) {
                        var paxFeeItems = this.getFeeItemsFromPassengersBySSRType(passengers, ssrTypeList);
                        var feeTotal = this.calculateFeeTotalForSSRType(paxFeeItems, type);
                        if (feeTotal) {
                            paxFeeTotals.push(feeTotal);
                        }
                    }
                }
            }
        }
        return paxFeeTotals;
    };
    BookingInfoComponent.prototype.getInfantPriceTotal = function () {
        return this.passengers
            .map(function (x) { return x.fees; })
            .map(function (x) { return x.items; })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .filter(function (x) { return x.code === Constants.InfantSsrCode; })
            .map(function (x) { return x.serviceCharges; })
            .map(function (x) { return x.items; })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .filter(function (z) { return z.chargeType !== BookingInfoComponent.IncludedTax; })
            .reduce(function (a, b) { return a + b.amount; }, 0);
    };
    /**
     * Reducing function for total fee using SSRs and amount paid
     * @param prev
     * @param next
     */
    BookingInfoComponent.prototype.reduceArrayBySSRAndAmount = function (prev, next) {
        var key = next.chargeCode;
        if (!prev[key]) {
            prev[key] = next;
        }
        else {
            prev[key].amount += next.amount;
            prev[key].foreignAmount += next.foreignAmount;
        }
        return prev;
    };
    /**
     * Calculate fare taxes total + infants
     */
    BookingInfoComponent.prototype.calculateFareTaxesTotal = function (priceBreakdown) {
        var infantTotal = this.getInfantPriceTotal();
        return (priceBreakdown.fareTaxAndFees + infantTotal) || 0;
    };
    /**
     * Calculate fare total
     */
    BookingInfoComponent.prototype.calculateFareTotal = function (priceBreakdown) {
        return priceBreakdown.fareTotal || 0;
    };
    BookingInfoComponent.prototype.calculateBundleUpgradeTotal = function (priceBreakdown) {
        return priceBreakdown.bundleFees || 0;
    };
    BookingInfoComponent.prototype.calculateExtrasSpoilageFeesTotal = function (priceBreakdown) {
        return priceBreakdown.spoilageFees || 0;
    };
    BookingInfoComponent.prototype.calculateFareSpoilageFeesTotal = function (priceBreakdown) {
        return (priceBreakdown.serviceFees + priceBreakdown.serviceFeeAdjustments) || 0;
    };
    BookingInfoComponent.prototype.calculateDateChangeFeesTotal = function (priceBreakdown) {
        return priceBreakdown.cancelChangeFees || 0;
    };
    BookingInfoComponent.prototype.calculateNameChangeFeesTotal = function (priceBreakdown) {
        return priceBreakdown.nameChangeFees || 0;
    };
    /**
     * Calculate seat total
     */
    BookingInfoComponent.prototype.calculateSeatTotal = function (priceBreakdown) {
        return priceBreakdown.seatFees || 0;
    };
    /**
     * Calculate insurance total
     */
    BookingInfoComponent.prototype.calculateInsuranceTotal = function () {
        return Math.abs(this.passengers
            .map(function (x) { return x.fees; })
            .map(function (x) { return x.items; })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .filter(function (x) { return x.code === Constants.InsuranceFeeCode; })
            .map(function (x) { return x.serviceCharges; })
            .map(function (x) { return x.items; })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .filter(function (z) { return z.chargeType !== BookingInfoComponent.IncludedTax; })
            .reduce(function (a, b) { return a + b.amount; }, 0)) || 0;
    };
    /**
     * Calculate grand total
     */
    BookingInfoComponent.prototype.calculateGrandTotal = function (priceBreakdown) {
        return priceBreakdown.grandTotalPrice || 0;
    };
    /**
     * Get SSR type list from nest SSR
     * @param ssrType The nest SSR type
     */
    BookingInfoComponent.prototype.getSSRTypeListFromNest = function (ssrType) {
        return this.bookingChangesService.getSsrTypeList(ssrType);
    };
    /**
     * Get all fee items from passengers by certain SSR type
     * @param passengers Passengers array
     * @param ssrTypeList SSR types array
     */
    BookingInfoComponent.prototype.getFeeItemsFromPassengersBySSRType = function (passengers, ssrTypeList) {
        return passengers.map(function (p) { return p.fees.items; })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .filter(function (item) { return ssrTypeList.includes(item.ssrCode); })
            .map(function (x) { return x.serviceCharges.items; })
            .reduce(function (a, b) { return a.concat(b); }, [])
            .filter(function (z) { return z.chargeType !== BookingInfoComponent.IncludedTax; })
            .reduce(this.reduceArrayBySSRAndAmount, {});
    };
    /**
     * Calculate fee total for a certain SSR type
     * @param paxFeeItems Passenger fee items
     * @param ssrType SSR type to filter
     */
    BookingInfoComponent.prototype.calculateFeeTotalForSSRType = function (paxFeeItems, ssrType) {
        var arrayvalues = Object.values(paxFeeItems);
        var sumOfFees = arrayvalues.reduce(function (a, b) { return a + b.amount; }, 0);
        if (sumOfFees <= 0) {
            return null;
        }
        var feeObject = {
            'total': sumOfFees,
            'ssr': this.translateService.instant(SsrType[ssrType], "itinerary", ["ssr.name"])
        };
        return feeObject;
    };
    BookingInfoComponent.prototype.toggleVisibility = function (extrasToggle) {
        if (extrasToggle.classList.contains('show')) {
            this.renderer.removeClass(extrasToggle, 'show');
        }
        else {
            this.renderer.addClass(extrasToggle, 'show');
        }
    };
    BookingInfoComponent.prototype.getPaymentAccounts = function () {
        var _this = this;
        if (this.selectedPaymentType) {
            switch (this.selectedPaymentType) {
                case 'OBT':
                    this.selectedPaymentTypeDescription = 'bank transfer';
                    break;
                case 'CD':
                    this.selectedPaymentTypeDescription = 'cash deposit';
                    break;
                default:
                    this.selectedPaymentTypeDescription = '';
                    break;
            }
            return this.paymentsService.getPaymentAccounts(this.selectedPaymentType, this.bookingCurrency).then(function (paymentAccounts) {
                _this.paymentAccounts = paymentAccounts;
            });
        }
        return Promise.resolve();
    };
    BookingInfoComponent.prototype.isNextDay = function (departureDate, arrivalDate) {
        if (moment(departureDate).date() !== moment(arrivalDate).date()) {
            return true;
        }
    };
    BookingInfoComponent.prototype.print = function () {
        window.print();
    };
    BookingInfoComponent.prototype.subtractTimezoneOffset = function (date, offset) {
        return moment(date).utcOffset(offset, true);
    };
    BookingInfoComponent.prototype.isAutoCompletedField = function (value) {
        return value && PassengerModel.checkNameFormat(String(value));
    };
    BookingInfoComponent.prototype.setPropsForType = function (pax, isInfant) {
        var paxType = null;
        if (pax.typeInfo) {
            paxType = pax.typeInfo.paxType.substring(0, 3);
            this.typeCounter[paxType] = (this.typeCounter[paxType] || 0) + 1;
            pax.passengerNumberByType = this.typeCounter[paxType];
        }
        if (isInfant) {
            pax.isInfant = true;
        }
        else {
            switch (paxType) {
                case Constants.AdultPaxType:
                    pax.isAdult = true;
                    break;
                case Constants.YoungAdultPaxType:
                    pax.isYouth = true;
                    break;
                case Constants.TeenPaxType:
                    pax.isTeen = true;
                    break;
                case Constants.ChildrenPaxType:
                    pax.isChild = true;
                    break;
            }
        }
    };
    BookingInfoComponent.prototype.getAirportTransferURL = function () {
        return "https://noleggiobrevetermine.rentalplus.it/?discount=AEROITALIA";
    };
    BookingInfoComponent.prototype.getAirportTransferURLMaggiore = function () {
        var language = "";
        var selectedLanguage = this.translateService.culture ? this.translateService.culture : 'en-GB';
        switch (selectedLanguage) {
            case 'en-GB':
            case 'ro-RO':
                language = "en/partner/aeroitalia/offer.html";
                break;
            default:
                language = 'partner/aeroitalia/offerta.html';
        }
        return "https://www.maggiore.it/" + language;
    };
    BookingInfoComponent.prototype.getAirportParkingURL = function () {
        var selectedLanguage = this.translateService.culture ? this.translateService.culture : 'en-GB';
        return "http://blueair.parkcloud.com/" + selectedLanguage;
    };
    BookingInfoComponent.prototype.loadMemberPromotionActivationInfo = function () {
        var _this = this;
        if (!this.paymentDue || this.paymentDue === 0) {
            this.bookingService.loadMemberPromotionActivationInfo()
                .then(function (activationData) {
                if (!activationData) {
                    _this.bbActivationInfo = null;
                    return;
                }
                _this.bbActivationInfo = {
                    promoId: activationData.promoId,
                    activationId: activationData.activationId,
                    url: _this.configService.config.corporateUrl + ("m/shop/bluebenefits/activate/" + activationData.activationId)
                };
            });
        }
        else {
            this.bbActivationInfo = null;
        }
    };
    BookingInfoComponent.prototype.getFlightStatusMessage = function () {
        var canceledJourneys = this.journeys && this.journeys.some(function (j) {
            return j.segments &&
                (j.segments.some(function (s) { return s.legs && s.legs.some(function (l) { return l.legInfo.status === 'Canceled'; }); }) &&
                    j.segments.every(function (s) { return s.paxSegments.every(function (ps) { return ps.liftStatus !== 'Boarded' && ps.liftStatus !== 'NoShow'; }); }));
        });
        var scheduleChangedJourneys = this.journeys && this.journeys.some(function (j) {
            return j.segments &&
                (j.segments && j.segments.some(function (s) { return s.changeReasonCode && s.changeReasonCode == "SCHG" && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3; }) ||
                    j.segments && j.segments.some(function (s) { return (!s.changeReasonCode || s.changeReasonCode && s.changeReasonCode != "SCHG") && s.paxSegments.some(function (p) { return p.timeChanged; }) && (Math.abs(Number(new Date(s.originalDepartureTime)) - Number(new Date(s.sTD))) / 36e5) >= 3; }));
        });
        var refunds = this.booking.comments.items.filter(function (c) { return c.text.match("process-started"); });
        if (canceledJourneys) {
            return "Affected by a cancellation";
        }
        else if (scheduleChangedJourneys) {
            return "Affected by a schedule change";
        }
        else if (refunds.length > 0) {
            return "Canceled";
        }
        else {
            return this.booking.bookingDetail.status;
        }
    };
    BookingInfoComponent.prototype.checkInsuranceAcknowledgment = function () {
        this.insuranceModal.ok();
        if (this.page == ChangeBooking.flight) {
            this.insuranceModal.ok();
            if (this.isRestricted) {
                this.restrictedBookingModal.openPopup(this.restrictedBookingModalId);
            }
            this.changeDateActive = true;
        }
        else if (this.page == ChangeBooking.passengers) {
            this.insuranceModal.ok();
            if (this.isRestricted) {
                this.restrictedBookingModal.openPopup(this.restrictedBookingModalId);
                this.bookingService.clearSnapshot();
            }
            else if (this.isCanceledBookingStatus || this.areCanceledBookingStatusAll()) {
                this.canceledBookingModal.openPopup(this.canceledBookingModalId);
                this.bookingService.clearSnapshot();
            }
            this.bookingFlowService.scrollToPassengersObs.next(this.page);
            this.steps.goToStep(CheckinSteps.passengers);
        }
    };
    BookingInfoComponent.WalletValue = ['WLTVO', 'VIRAL'];
    BookingInfoComponent.IncludedTax = 'IncludedTax';
    return BookingInfoComponent;
}(ProfileInfoBox));
export { BookingInfoComponent };
var ChangeFlightModel = /** @class */ (function () {
    function ChangeFlightModel() {
        this.journeys = [];
    }
    ChangeFlightModel.prototype.toggleJourney = function (index, departure, arrival, date) {
        if (this.journeys.filter(function (p) { return p.index === index; }).length > 0) {
            var i = this.journeys.findIndex(function (p) { return p.index === index; });
            this.journeys.splice(i, 1);
        }
        else {
            this.journeys.push({ index: index, departure: departure, arrival: arrival, date: date });
        }
        if (this.journeys && this.journeys.length > 1) {
            this.journeyType = Constants.RoundTrip;
            var first = this.journeys.find(function (p) { return p.index === 0; });
            this.departureStation = first.departure;
            this.arrivalStation = first.arrival;
            this.outboundDate = moment(first.date).format("YYYY/MM/DD");
            this.inboundDate = this.journeys.find(function (p) { return p.index === 1; }).date;
        }
        else if (this.journeys.length === 1) {
            var theJourney = this.journeys[0];
            this.arrivalStation = '';
            this.departureStation = '';
            this.departureStation = theJourney.departure;
            this.arrivalStation = theJourney.arrival;
            this.outboundDate = moment(theJourney.date).format("YYYY/MM/DD");
            this.journeyType = Constants.OneWayTrip;
        }
        else {
            this.arrivalStation = '';
            this.departureStation = '';
            this.outboundDate = '';
            // this.journeyType  = 'OneWay';
        }
    };
    ChangeFlightModel.prototype.reset = function () {
        this.arrivalStation = '';
        this.departureStation = '';
        this.outboundDate = '';
        this.journeys.length = 0;
    };
    return ChangeFlightModel;
}());
export { ChangeFlightModel };
