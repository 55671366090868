import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {of as observableOf, Observable, config} from 'rxjs';
import { retryWhen, delay, map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ISpecialMarket} from "../booking-flight-select/change-flight/change-flight.component";
import {isNullOrUndefined} from "util";

@Injectable()
export class ConfigService {
  config: any;
  assetsPath: string;

  private configPath: string;
  // Razvan Paisa:  removed the functionality
  //get HubServiceUrl(): string { return this.config.hubServiceUrl; }
  get RememberSearch(): string { return this.config.apiUrl + 'api/RememberSearch'; }
  get BookingFlightSearch(): string { return this.config.apiUrl + 'api/InternalFlightSearch'; }
  get PriceItinerary(): string { return this.config.apiUrl + 'api/BluePriceItinerary'; }
  get SellFlight(): string { return this.config.apiUrl + 'api/Flight'; }
  get SessionUrl(): string { return this.config.apiUrl + 'api/Session'; }
  get FlightSearch(): string { return this.config.apiUrl + 'api/FlightSearch'; }
  get CancelRebook(): string { return this.config.apiUrl + 'api/CancelRebook'; }
  get Booking(): string { return this.config.apiUrl + 'api/Booking'; }
  get CheckinRetrieveUrl(): string { return this.config.apiUrl + 'api/CheckinRetrieve'; }
  get CheckinRetrieveRestrictionsUrl(): string { return this.config.apiUrl + 'api/CheckinRetrieveRestrictions'; }
  get SeatMap(): string { return this.config.apiUrl + 'api/seatmap'; }
  // get RetrieveByLastName():string {return this.config.apiUrl + 'api/RetrieveByLastName';}
  get CheckinInput(): string { return this.config.apiUrl + 'api/Checkin'; }
  get CheckinInfo(): string { return this.config.apiUrl + 'api/CheckinInfo'; }
  get CheckinPassengers(): string { return this.config.apiUrl + 'api/CheckInPassengers'; }
  // get SsrsInfoBytTypeUrl(): string { return this.config.apiUrl + 'api/SsrsByType'; }
  get SsrsInfoUrl(): string { return this.config.apiUrl + 'api/ssrs'; }
  get SsrsApplyUrl(): string { return this.config.apiUrl + 'api/ssrs'; }
  get PriceBreakdown(): string { return this.config.apiUrl + 'api/FullPriceBreakdown'; }
  get BookingPriceBreakdown(): string { return this.config.apiUrl + 'api/PriceBreakdown'; }
  get ShoppingCartBreakdownUrl(): string { return this.config.apiUrl + 'api/ShoppingCartBreakdown'; }

  get PaymentAccountsUrl(): string { return this.config.apiUrl + 'api/paymentinfo'; }
  get PaymentsManagerUrl(): string { return this.config.apiUrl + 'api/PaymentsManager'; }
  get InvoicingInfoUrl(): string { return this.config.apiUrl + 'api/Invoice'; }
  get PaymentMethodInfoUrl(): string { return this.config.apiUrl + 'api/PaymentInfo'; }
  get PaymentBeginUrl(): string { return this.config.corporateUrl + 'payments/begin'; }

  get LowFareUrl(): string { return this.config.corporateUrl + 'api/LowestFare/DayByIata'; }

  get FinalizeCheckinUrl(): string { return this.config.apiUrl + 'api/CheckInFinalize'; }

  get BookingSnapshot(): string { return this.config.apiUrl + 'api/BookingSnapshot'; }

  get BoardingPassUrl(): string { return this.config.apiUrl + 'api/BoardingPass'; }
  get SendBoardingPassUrl(): string { return this.config.apiUrl + 'api/SendBoardingPass'; }

  get PassengersUrl(): string { return this.config.apiUrl + 'api/Passengers'; }

  get ContactUrl(): string { return this.config.apiUrl + 'api/Contact'; }

  get LockFareDetails(): string { return this.config.apiUrl + 'api/lockfaredetails'; }

  get PaymentPortalUrl(): string { return this.config.paymentPortalUrl ? this.config.paymentPortalUrl + 'paymentbegin' : null; }

  get BundleDetails(): string { return this.config.apiUrl + 'api/BundleUpgrade'; }

  get SuperStationsUrl(): string { return this.config.apiUrl + 'customResource/SuperStationsWithCountries'; }

  get InsuranceDetails(): string { return this.config.apiUrl + 'api/Insurance'; }

  get SellFee(): string { return this.config.apiUrl + 'api/Fee'; }

  get ScheduleUrl(): string { return this.config.corporateUrl + 'Schedule'; }

  get SsrChanges(): string { return this.config.apiUrl + 'api/SsrChanges'; }

  get BlueRetrieve(): string { return this.config.apiUrl + 'api/BlueRetrieve'; }

  get ChangeFlight(): string { return this.config.apiUrl + 'api/ChangeFlight'; }

  get ClearSession(): string { return this.config.apiUrl + 'api/ClearSession'; }

  get Itinerary(): string { return this.config.apiUrl + 'api/Itinerary'; }

  get Flights(): string { return this.config.apiUrl + 'api/Flights'; }

  get LockFareUrl(): string { return this.config.apiUrl + 'api/LockFare'; }

  get NewsletterSubscriptionUrl(): string { return this.config.apiUrl + 'api/NewsletterSubscription'; }

  get RetrieveAgentBookings(): string { return this.config.apiUrl + 'api/RetrieveAgentBookings'; }

  get AgentLoginUrl(): string { return this.config.apiUrl + 'api/agentLogin'; }

  get MemberLoginUrl(): string { return this.config.apiUrl + 'api/memberLogin'; }

  get LogoutUrl(): string { return this.config.apiUrl + 'api/memberLogout'; }

  get ProfileUrl(): string { return this.config.apiUrl + 'api/member'; }

  get ResetPasswordUrl(): string { return this.config.apiUrl + 'api/resetPassword'; }

  get SaveProfileInfoUrl(): string { return this.config.apiUrl + 'api/MemberInfo'; }

  get SaveAgentInfoUrl(): string { return this.config.apiUrl + 'api/AgentInfo'; }

  get SaveTravelDocumentUrl(): string { return this.config.apiUrl + 'api/MemberTravelDocument'; }

  get SaveAddressBookItemUrl(): string { return this.config.apiUrl + 'api/MemberAddress'; }

  get SaveSeatPreferencesUrl(): string { return this.config.apiUrl + 'api/MemberSeatPreferences'; }

  get CompanionsUrl(): string { return this.config.apiUrl + 'api/MemberCompanion'; }

  get SaveCompanionsTravelDocumentUrl(): string { return this.config.apiUrl + 'api/MemberCompanionTravelDocument'; }

  get MemberBookingsUrl(): string { return this.config.apiUrl + 'api/MemberBookings'; }

  get MemberDetails(): string { return this.config.apiUrl + 'api/Member'; }

  get CheckUserEligibilityAndUsePromotion(): string { return this.config.apiUrl + 'api/CheckUserEligibilityAndUsePromotion'; }

  get MemberPromotionActivationInfoUrl(): string { return this.config.apiUrl + 'api/MemberPromotionActivationInfo'; }

  get PromotionUrl(): string { return this.config.apiUrl + 'api/MemberPromotions'; }

  get MoveFlight(): string { return this.config.apiUrl + 'api/MoveFlight'; }

  get MoveFlightSearch(): string { return this.config.apiUrl + 'api/InternalMoveFlightSearch'; }

  get SellMovedFlight(): string { return this.config.apiUrl + 'api/MoveFlightSell'; }

  get PushToWalletUrl(): string { return this.config.apiUrl + 'api/PushToWallet'; }

  get PromoCodeUrl(): string { return this.config.apiUrl + 'api/PromoCode'; }

  get RefundCashUrl(): string { return this.config.apiUrl + 'api/CustomerRefundCashRequest'; }

  get PassengerConsentUrl(): string { return this.config.apiUrl + 'api/PassengerConsent'; }

  get UnsubscribeBookingFromNotificationsUrl(): string { return this.config.flyBlueAirApiUrl + 'api/BookingOperations/v1/unsubscribeFromNotifications' }

  get AddBookingCommentUrl(): string { return this.config.flyBlueAirApiUrl + 'api/BookingOperations/v1/addBookingComment' }

  constructor(private http: HttpClient) {
    this.setConfigPath(environment.configPath);
    this.assetsPath = environment.assetsPath;
    // this.assetsPath = window['assetsPath'] || '/assets/';
    // this.configPath = window['configPath'] || '/config/';
    // delete window['assetsPath'];
    // delete window['configPath'];
  }

  load(): Promise<any> {
    return this.tryGetConfig(this.configPath, 3)
      .toPromise()
      .then(
        config => this.config = config
      );
  }

  private setConfigPath(configPath: string) {
    if (configPath) {
      this.configPath = configPath + '?rt=' + (new Date()).getTime();
    }
  }

  private tryGetConfig(configPath: string, retryCount: number): Observable<any> {
    const headers = new HttpHeaders().set('X-Skip-Interceptor', '');
    return this.http
      .get(configPath, { headers: headers }).pipe(
        retryWhen(error => {
          return error.pipe(
            delay(500),
            map(err => {
              if (--retryCount < 0) {
                throw err;
              }
              return err;
            }));
        }),
        catchError(err => observableOf({
          'apiUrl': 'https://stage-webapi.aeroitalia.com/',
          'resourcesUrl': 'https://stage-webapi.aeroitalia.com/',
          'corporateUrl': 'https://www.blueairweb.com/',
          // tslint:disable-next-line: max-line-length
          'translationsUrl': 'config/translations/{lang}/translations.json',
          'printUrl': '',
          'paymentPortalUrl': 'https://stage-paymenthub.aeroitalia.com/',
        })));
  }

  public getSardiniaConfiguration(): ISpecialMarket | null {
    if (isNullOrUndefined(this.config) || isNullOrUndefined(this.config.markets)) {
      return null;
    }

    return this.config.markets.find(market => market.marketName === 'sardinia') || null;
  }

  public getSiciliaConfiguration(): ISpecialMarket | null {
    if (isNullOrUndefined(this.config) || isNullOrUndefined(this.config.markets)) {
      return null;
    }

    return this.config.markets.find(market => market.marketName === 'sicilia') || null;
  }

  public getMarcheConfiguration(): ISpecialMarket | null {
    if (isNullOrUndefined(this.config) || isNullOrUndefined(this.config.markets)) {
      return null;
    }

    return this.config.markets.find(market => market.marketName === 'marche') || null;
  }
}

