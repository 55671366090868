import { PassengerTypes } from './../models/passenger-selection-view-model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BookingStepsService, CheckinSteps } from '../../core/booking-steps.service';
import { BookingFlightSelectService } from '../booking-flight-select.service';
import { FlightSearchModel, IPassengerSearch } from '../models/flight-search-model';
import * as moment from 'moment';
import { isNullOrUndefined, isNumber } from 'util';
import { Constants } from '../../constants';
import { LoadingSpinnerService } from '../../common-modules/blue-air-common/loading-spinner.service';
import { BookingService } from '../../core/booking.service';
import { TranslateService } from '../../common-modules/blue-air-common/translator/translate.service';
import { ConfigService } from '../../core/config.service';
import { environment } from '../../../environments/environment';
import { ProfileService } from '../../core/profile.service';
import { Session } from 'protractor';
import { SessionService } from '../../common-modules/blue-air-common/session.service';

@Component({
  selector: 'check-in-deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.scss']
})
export class DeeplinkComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private configService: ConfigService,
    private bookingSteps: BookingStepsService,
    private flightSearch: BookingFlightSelectService, private loadingSpinnerService: LoadingSpinnerService,
    private profileService: ProfileService,
    private sessionService: SessionService,
    private bookingService: BookingService) { }

  invalidSearch = false;
  timer: number;

  ngOnInit() {
    this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);

    const snapshot = this.route.snapshot;
    switch (snapshot.paramMap.get('action')) {
      case 'search':
        this.applySearch(snapshot.queryParamMap);
        break;
      case 'mmb':
      default:
        this.bookingSteps.goToStep(CheckinSteps.search);
    }
  }


  // https://tstbooking.blueairweb.com/(S(mwsgwsz0tf1ne3fo3jfnksgi))/Flight/CorporateSelect?
  // s=true&o1=OTP&d1=FCO&dd1=2018-12-28&dd2=2018-12-30&ADT=1&YTH=1&CLD=1&CHD=1&INL=1&r=true&mon=true&tabId=0dc31882-2c34-4bf0-a7d9-82b85035fc9d&culture=en-GB&bc=EUR
  applySearch(parameters: ParamMap) {
    const searchModel = new FlightSearchModel();
    searchModel.origin = parameters.get('o1');
    searchModel.destination = parameters.get('d1');
    searchModel.isRoundTrip = parameters.get('r') === 'true';
    searchModel.usePromo = parameters.get('uapc') === 'true';
    searchModel.culture = parameters.get('culture');



    const departureDate = moment(parameters.get('dd1'), 'YYYY-MM-DD');
    if (departureDate.isValid()) {
      searchModel.setDepartureDate(departureDate.toDate());
    }

    if (searchModel.isRoundTrip) {
      const returnDate = moment(parameters.get('dd2'), 'YYYY-MM-DD');
      if (returnDate.isValid()) {
        searchModel.setReturnDate(returnDate.toDate());
      }
    }

    // Razvan: this is default EUR for beginning
    searchModel.currency = 'EUR';//parameters.get('bc');

    const knownPassengerTypes = [Constants.AdultPaxType,
    Constants.YoungAdultPaxType,
    Constants.TeenPaxType,
    Constants.ChildrenPaxType,
    Constants.InfantPaxType];

    searchModel.passengers = [];

    knownPassengerTypes.forEach((paxType: PassengerTypes) => {
      const val = parameters.get(paxType);
      const count = +val;
      if (!isNaN(count) && isNumber(count) && count > 0) {
        searchModel.passengers.push({
          count: +count,
          paxType: paxType
        });
      }
    });
    if (searchModel.isValid()) {
      this.flightSearch.searchModel = searchModel;
      this.flightSearch.isInitialSearch = parameters.get('is') ? true : false;

      this.translateService.culture = searchModel.culture;
      this.translateService.load(this.configService.config.translationsUrl, this.configService.config.collectTranslationsUrl,
        environment.collectTranslations);

      const agent = parameters.get('agent');

      if (this.flightSearch.isInitialSearch) {

        this.bookingService.clearSession().then(() => {
          if (!isNullOrUndefined(agent)) {
            this.profileService.loginAgent(agent, "##toGetPass##")
              .subscribe(item => {
                this.bookingSteps.goToStep(CheckinSteps.flights);
              });
          }
          else {
            this.sessionService.resetSession();
          }
          this.bookingSteps.goToStep(CheckinSteps.flights)
        }
        );
      } else {
        if (!isNullOrUndefined(agent)) {
          this.profileService
            .loginAgent(agent, "##toGetPass##")
            .subscribe(item => {
              this.bookingSteps.goToStep(CheckinSteps.flights);
            });;
        }
      }
    } else {
      this.showInvalidSearch();
    }
  }


  showInvalidSearch() {
    this.loadingSpinnerService.hideSpinnerById(Constants.mainSpinnerId);

    this.invalidSearch = true;
    this.timer = 10;

    const interval = setInterval(() => {
      if (--this.timer === 0) {
        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
        setTimeout(() => {
          window.location.href = '/';
        }, 0);
        clearInterval(interval);
      }
    }, 1000);
  }

}
