import * as tslib_1 from "tslib";
import { BehaviorSubject } from 'rxjs';
import { SeatSelectedNotification } from '../shared/seat-selected-notification/seat-selected-notification.component';
import { OnInit, EventEmitter } from '@angular/core';
import { BookingStepsService, CheckinSteps } from '../core/booking-steps.service';
import { LoadingSpinnerService } from '../common-modules/blue-air-common/loading-spinner.service';
import { Constants } from '../constants';
// import { SsrsService } from '../ssrs.service';
import { SsrsComponent } from '../extras/ssrs/ssrs.component';
import { InsuranceComponent } from '../extras/insurance/insurance.component';
import { DefaultModalComponent } from '../shared/default-modal/default-modal.component';
import { SsrType } from '../extras/ssr-type.enum';
import { PassengerBundleComponent } from '../shared/passenger-bundle/passenger-bundle.component';
// import { IHasPendingChanges } from '../../core/has-pending-changes.interface';
import { SelectionsClearWarningModalComponent } from '../shared/selections-clear-warning-modal/selections-clear-warning-modal.component';
import { filter, take } from 'rxjs/operators';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
import { environment } from 'src/environments/environment';
import { ProfileService } from 'src/app/core/profile.service';
import { BookingService } from '../core/booking.service';
import { SsrSellingTypeHelper } from '../extras/ssr-selling-type.enum';
import { SsrsService } from '../extras/ssrs.service';
import { SsrBoxComponent } from '../extras/ssr-box/ssr-box.component';
import { BookingFareSelectSyncService } from '../booking-flight-select/booking-fare-select/booking-fare-select-sync-service.service';
import { TranslateService } from '../common-modules/blue-air-common/translator/translate.service';
import { ECommerceService } from '../shared/e-commerce/ecommerce.service';
var LuggageComponent = /** @class */ (function () {
    function LuggageComponent(steps, loadingSpinnerService, flowManager, ecommerce, bookingService, profileService, ssrsService, syncService, translateService) {
        var _this = this;
        this.steps = steps;
        this.loadingSpinnerService = loadingSpinnerService;
        this.flowManager = flowManager;
        this.ecommerce = ecommerce;
        this.bookingService = bookingService;
        this.profileService = profileService;
        this.ssrsService = ssrsService;
        this.syncService = syncService;
        this.translateService = translateService;
        this._canDeactivate = false;
        this.currentStep = CheckinSteps.luggage;
        this.modalId = 'pbagModal';
        this.priorityDetailsFreeModalId = 'priorityDetailsFreeModal';
        this.priorityDetailsPaidModalId = 'priorityDetailsPaidModal';
        this.ssrBoxId = 'ssrBox';
        this.insuranceEnabled = false; // disable insurance as requested
        this.pendingSsrsList = [];
        this.flightsObs = new BehaviorSubject(null);
        this.currentFlights = null;
        this.enableSameOptionsForAllFlights = false;
        this.isModified = false;
        this.recordLocators = [];
        this.continue = new EventEmitter();
        this.applicationFlowService = flowManager.applicationFlowService;
        // disable insurance as requested
        // this.insuranceEnabled = !this.profileService.isAgent;
        this.assetsPath = environment.assetsPath;
        this.steps.currentStep.next(this.currentStep);
        this.applicationFlowService.shoppingCartBreakdown
            .pipe(filter(function (b) { return b && b.currentShoppingCart ? true : false; }), take(1))
            .subscribe(function (breakdown) { return _this.hasSpoilageFees = breakdown.currentShoppingCart.checkExtrasForSsrOrFeeCode(SsrType.Other, 'SPL'); });
        this.bookingServiceSubscription = this.bookingService.bookingObs.subscribe(function (result) {
            if (result) {
                if (result.bookingDetail && result.bookingDetail.recordLocator !== null && result.bookingDetail.recordLocator !== undefined && result.bookingDetail.recordLocator !== "") {
                    _this.hasRecordLocator = true;
                }
                if (result.bookingDetail && result.bookingDetail.recordLocators && result.bookingDetail.recordLocators.length > 0) {
                    _this.recordLocators = result.bookingDetail.recordLocators;
                    _this.isRestricted = _this.isRestrictedBooking();
                }
            }
        });
        this.enableFlightsUpdates();
        if (this.translateService.culture.match('en-GB')) {
            this.priorityPopupImg = 'img/ssrs/pop-up_priority_en.jpg';
            this.twoCabinBagsPopupImg = 'img/ssrs/pop-up_twocabinbags_en.jpg';
        }
        else {
            this.priorityPopupImg = 'img/ssrs/pop-up_priority_ro.png';
            this.twoCabinBagsPopupImg = 'img/ssrs/pop-up_twocabinbags_ro.png';
        }
    }
    Object.defineProperty(LuggageComponent.prototype, "hasAllPriorityBoardingOptionsSet", {
        /*
        This gets if the client chose at least one option for the priority boarding,
        no matter if is is "no priority boarding" or with "priority boarding"
        If this returns false, a modal with a validation will be shown and the user CANNOT PROCEED TILL HE CHOOSES AN OPTION
        */
        get: function () {
            var _this = this;
            if (!this.ssrsService.availableSsrs.some(function (ssr) {
                return ssr.value.flatMap(function (item) { return item.value.flatMap(function (item) { return item.name; }); }).includes("PBRD");
            }))
                return true; //in case the Priority Boarding is not  available, the user can proceed without validating the selection
            if (!this.syncService.flights)
                return false;
            var result = this.syncService.flights
                .filter(function (flight) { return !flight.isInThePast; })
                .every(function (flights) {
                return flights.passengers.every(function (passenger) { return _this.syncService.passengersInitialPriorityBoardingChoice[passenger.passengerUniqueId] ? true : false; });
            });
            return result;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LuggageComponent.prototype, "shouldHaveAllPriorityBoardingAdded", {
        /*
        This get if all the passengers are with priority boarding or not.
        If at least one of the passengers is without priority boarding, this is false and a warning modal should be shown.
        That warning has the option to move further as this is only an warning.
        */
        get: function () {
            if (!this.ssrsService.availableSsrs.some(function (ssr) {
                return ssr.value.flatMap(function (item) { return item.value.flatMap(function (item) { return item.name; }); }).includes("PBRD");
            }))
                return true; //in case the Priority Boarding is not  available, the user can proceed without validating the selection
            if (!this.syncService.flights)
                return false;
            var result = this.syncService.flights
                .filter(function (flight) { return !flight.isInThePast; })
                .every(function (flights) {
                return flights.passengers.every(function (passenger) { return passenger.hasPriorityBoarding; });
            });
            return result;
        },
        enumerable: true,
        configurable: true
    });
    LuggageComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.ssrsService.isLuggageStep = true;
        this.ssrsService.isExtrasStep = false;
        this.syncService.passengersInitialPriorityBoardingChoice = {};
        // if (!localStorage.getItem("pageState")) {
        //   localStorage.setItem("pageState", "reloaded");
        //   window.location.reload();
        // }
        this.ssrsService.ssrs;
        this.profileService.refreshProfile();
        this.flightsSubscription = this.flowManager.selectionService.flightsObs.subscribe(function (flights) {
            if (flights) {
                _this.flights = flights;
                _this.isCanceledBookingStatus = _this.checkBookingStatus();
            }
        });
    };
    LuggageComponent.prototype.ngOnDestroy = function () {
        this.ssrsService.isLuggageStep = false;
        if (this.bookingServiceSubscription) {
            this.bookingServiceSubscription.unsubscribe();
        }
        if (this.flightsSubscription) {
            this.flightsSubscription.unsubscribe();
        }
        localStorage.removeItem("pageState");
    };
    LuggageComponent.prototype.skipAndComplete = function () {
        this.loadingSpinnerService.showSpinnerById(Constants.mainSpinnerId);
        this.steps.goToStep(CheckinSteps.summary);
    };
    LuggageComponent.prototype.goToNextStep = function (checkinStep) {
        var _this = this;
        var e_1, _a;
        var components = this.ssrsComponent.getComponentModals();
        if (this.insuranceComponent) {
            components.push(this.insuranceComponent);
        }
        try {
            for (var _b = tslib_1.__values(this.ssrsService.luggagePassengersMap.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var allHaveSelectedOption = _c.value;
                this.ssrsComponent.ssrBoxes.find(function (x) { return x.ssrType === SsrType.Baggage; }).showLuggageErrors();
                if (!allHaveSelectedOption) {
                    return;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        var myCallback = function (isOkCallback, index) {
            if (isOkCallback) {
                if (components.length === 0) {
                    _this._canDeactivate = true;
                    if (checkinStep !== null && checkinStep !== undefined) {
                        _this.steps.goToStep(checkinStep);
                    }
                    else {
                        var ecommerceCartItems_1 = [];
                        _this.applicationFlowService.loadPriceBreakdown().then(function (breakdown) {
                            if (breakdown) {
                                ecommerceCartItems_1 = _this.ecommerce.getAllCartDataForECommerce(breakdown);
                                _this.ecommerce.Checkout(ecommerceCartItems_1, 2, 'Extras step');
                            }
                        });
                        _this.steps.goToNextStepFrom(_this.currentStep);
                    }
                }
                do {
                    index++;
                } while (index < components.length && !components[index].openModal(function (isOk) { return myCallback(isOk, index); }));
                if (index === components.length) {
                    _this._canDeactivate = true;
                    if (checkinStep !== null && checkinStep !== undefined) {
                        _this.steps.goToStep(checkinStep);
                    }
                    else {
                        var ecommerceCartItems_2 = [];
                        _this.applicationFlowService.loadPriceBreakdown().then(function (breakdown) {
                            if (breakdown) {
                                ecommerceCartItems_2 = _this.ecommerce.getAllCartDataForECommerce(breakdown);
                                _this.ecommerce.Checkout(ecommerceCartItems_2, 2, 'Extras step');
                            }
                        });
                        _this.steps.goToNextStepFrom(_this.currentStep);
                    }
                }
            }
        };
        // this._canDeactivate = this.hasPendingChanges();
        if (checkinStep == CheckinSteps.seat || checkinStep == CheckinSteps.passengers) {
            myCallback(true, -1);
        }
        else if (!this.hasAllPriorityBoardingOptionsSet) {
            this.prioritySsrAllSelectionsShouldBeSetForPriorityBoardingModal.openPopup(function (isOk) {
            });
        }
        else if (!this.shouldHaveAllPriorityBoardingAdded) {
            this.prioritySsrAllPriorityBoardingShouldBeSelectedWarningModal.openPopup(function (isOk) {
                if (!isOk) {
                    _this.scrollIntoView();
                }
                else {
                    myCallback(isOk, -1);
                }
            });
        }
        else {
            myCallback(true, -1);
        }
    };
    LuggageComponent.prototype.canDeactivate = function (currentRoute, currentState, nextState) {
        var checkinStep = this.steps.extractStep(nextState);
        var redirectingToOtherBookingStep = true;
        if (checkinStep === null) {
            redirectingToOtherBookingStep = false;
            checkinStep = CheckinSteps.search;
        }
        if (this.clearSelectionsModal.shouldShowWarning(this.currentStep, checkinStep)) {
            return this.clearSelectionsModal.showWarningAndNavigate(this.currentStep, redirectingToOtherBookingStep ? checkinStep : null, nextState.url);
        }
        if (!this._canDeactivate) {
            this.goToNextStep(checkinStep);
        }
        return this._canDeactivate;
    };
    LuggageComponent.prototype.hasPendingChanges = function () {
        var e_2, _a;
        var ssrsOk = true;
        this.showPriorityBags = false;
        this.showLuggageBag = true;
        try {
            //check passengers priority boarding
            for (var _b = tslib_1.__values(this.ssrsService.luggagePassengersMap.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var atLeastOneHasLugg = _c.value;
                if (atLeastOneHasLugg) {
                    this.showLuggageBag = false;
                    break;
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        //check passengers priority boarding
        ssrsOk = this.hasPriorityBoarding(ssrsOk);
        if (this.showLuggageBag) {
            ssrsOk = false;
        }
        return ssrsOk;
    };
    LuggageComponent.prototype.hasPriorityBoarding = function (ssrsOk) {
        var e_3, _a;
        try {
            for (var _b = tslib_1.__values(this.ssrsService.priorityPassengersMap.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var hasPBRD = _c.value;
                if (!hasPBRD) {
                    ssrsOk = false;
                    this.showPriorityBags = true;
                }
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_3) throw e_3.error; }
        }
        return ssrsOk;
    };
    LuggageComponent.prototype.scrollIntoView = function () {
        if (this.pendingSsrsList.length) {
            var firstPendingSsrType_1 = this.pendingSsrsList[0];
            if (firstPendingSsrType_1 === SsrType.Insurance) {
                this.insuranceComponent.scrollToView();
            }
            else {
                this.ssrsComponent.ssrBoxes.find(function (x) { return x.ssrType === firstPendingSsrType_1; }).validationModalAction(false);
            }
        }
    };
    LuggageComponent.prototype.enableFlightsUpdates = function () {
        var _this = this;
        if (!this.flightsSub || this.flightsSub.closed) {
            this.flightsSub = this.flightsObs.subscribe(function (newFlights) {
                _this.currentFlights = newFlights;
                _this.updateSsrAvailabilityByFlight();
                _this.updateTotalPrice(true);
                _this.enableSameOptionsForAllFlights =
                    _this.currentFlights && _this.currentFlights.length === 2 &&
                        _this.currentFlights[0].departureStation.iataCode === _this.currentFlights[1].arrivalStation.iataCode &&
                        _this.currentFlights[0].arrivalStation.iataCode === _this.currentFlights[1].departureStation.iataCode;
            });
        }
    };
    LuggageComponent.prototype.updateSsrAvailabilityByFlight = function () {
        if (!this.currentFlights || !this.ssrType) {
            return;
        }
    };
    LuggageComponent.prototype.updateTotalPrice = function (forced) {
        var _this = this;
        if (forced === void 0) { forced = false; }
        if (!this.shoppingCartExtras || !this.ssrType) {
            return;
        }
        var shoppingCartItem = this.shoppingCartExtras.find(function (e) { return e.ssrType === _this.ssrType; });
        this.totalPrice = shoppingCartItem ? shoppingCartItem.amount : 0;
        this.isModified = this.getIsModified();
        this.temporaryTotalPrice = this.totalPrice;
        if (this.currentFlights && (!this.flightPrices || forced)) {
            this.flightPrices = this.currentFlights.map(function (f) {
                var prices = [];
                prices.length = f.segments.length;
                prices.fill(0);
                if (shoppingCartItem) {
                    var shoppingCartSegments_1 = shoppingCartItem.flights
                        .filter(function (sf) { return sf.departureStation === f.departureStation.iataCode && sf.arrivalStation === f.arrivalStation.iataCode; })
                        .reduce(function (segments, sf) { return segments.concat(sf.segments); }, []);
                    f.segments.forEach(function (seg, i) {
                        prices[i] = shoppingCartSegments_1
                            .filter(function (ss) { return ss.departureStation === seg.departureStation.iataCode && ss.arrivalStation === seg.arrivalStation.iataCode; })
                            .sum(function (ss) { return ss.amount; });
                    });
                    if (SsrSellingTypeHelper.IsSsrTypeSoldByJourney(_this.ssrType)) {
                        prices = [prices.sum(function (p) { return p; })];
                    }
                }
                return prices;
            });
        }
    };
    LuggageComponent.prototype.getIsModified = function () {
        switch (this.ssrType) {
            case SsrType.SpecialAssistance:
            case SsrType.Meal:
            case SsrType.Baggage:
            case SsrType.SpecialEquipment: {
                return this.areSsrsSelected();
            }
            default: {
                return this.totalPrice > 0;
            }
        }
    };
    LuggageComponent.prototype.areSsrsSelected = function () {
        var _this = this;
        return this.shoppingCartExtras.some(function (x) { return x.ssrType === _this.ssrType && x.flightsWithItems.length > 0; });
    };
    LuggageComponent.prototype.toggleLuggagePriorityDetailsFreeOptionModal = function () {
        this.priorityDetailsFreeModal.openPopup(this.priorityDetailsFreeModalId);
        this.ssrsService.isDisplayedPriorityDetailsFreeModal = false;
    };
    LuggageComponent.prototype.toggleLuggagePriorityDetailsPaidOptionModal = function () {
        this.priorityDetailsPaidModal.openPopup(this.priorityDetailsPaidModalId);
        this.ssrsService.isDisplayedPriorityDetailsPaidModal = false;
    };
    LuggageComponent.prototype.toggleLuggageBagDetailsModal = function () {
        this.pbagModal.openPopup(this.modalId);
        this.ssrsService.isDisplayedBagDetailsModal = false;
    };
    LuggageComponent.prototype.checkBookingStatus = function () {
        var e_4, _a, e_5, _b, e_6, _c;
        try {
            for (var _d = tslib_1.__values(this.flights), _e = _d.next(); !_e.done; _e = _d.next()) {
                var f = _e.value;
                try {
                    for (var _f = tslib_1.__values(f.segments), _g = _f.next(); !_g.done; _g = _f.next()) {
                        var s = _g.value;
                        try {
                            for (var _h = tslib_1.__values(s.legs), _j = _h.next(); !_j.done; _j = _h.next()) {
                                var l = _j.value;
                                if (l.legInfo.status === "Canceled") {
                                    return true;
                                }
                            }
                        }
                        catch (e_6_1) { e_6 = { error: e_6_1 }; }
                        finally {
                            try {
                                if (_j && !_j.done && (_c = _h.return)) _c.call(_h);
                            }
                            finally { if (e_6) throw e_6.error; }
                        }
                    }
                }
                catch (e_5_1) { e_5 = { error: e_5_1 }; }
                finally {
                    try {
                        if (_g && !_g.done && (_b = _f.return)) _b.call(_f);
                    }
                    finally { if (e_5) throw e_5.error; }
                }
            }
        }
        catch (e_4_1) { e_4 = { error: e_4_1 }; }
        finally {
            try {
                if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
            }
            finally { if (e_4) throw e_4.error; }
        }
        return false;
    };
    LuggageComponent.prototype.isRestrictedBooking = function () {
        return (this.recordLocators.some(function (r) { return r.owningSystemCode !== null && r.owningSystemCode !== undefined; })) ? true : false;
    };
    return LuggageComponent;
}());
export { LuggageComponent };
