import { OnInit } from '@angular/core';
import { SsrType } from '../../../extras/ssr-type.enum';
import { filter, take } from 'rxjs/operators';
import { FlowManagerService } from 'src/app/core/flow-manager.service';
var ShoppingCartExtrasComponent = /** @class */ (function () {
    function ShoppingCartExtrasComponent(flowManager) {
        var _this = this;
        this.SsrTypeEnum = SsrType;
        this.isExpanded = {};
        this.paxNumberByType = {};
        this.ssrTypeMetadata = {};
        this.extras = [];
        var selectionService = flowManager.selectionService;
        this.setupSsrTypeMetadata();
        selectionService.passengersObs.pipe(filter(function (p) { return p ? true : false; }), take(1)).subscribe(function (passengers) {
            _this.paxNumberByType = {};
            passengers.forEach(function (p) { return _this.paxNumberByType[p.passengerNumber] = p.passengerNumberByType; });
        });
        selectionService.flightsObs.pipe(filter(function (p) { return p ? true : false; }), take(1)).subscribe(function (flights) {
            _this.isRoundTrip = flights.length > 1;
        });
    }
    Object.defineProperty(ShoppingCartExtrasComponent.prototype, "input", {
        set: function (value) {
            this.setupExtras(value);
        },
        enumerable: true,
        configurable: true
    });
    ShoppingCartExtrasComponent.prototype.ngOnInit = function () {
    };
    ShoppingCartExtrasComponent.prototype.toggleExpanded = function (ssrType) {
        this.isExpanded[ssrType] = !this.isExpanded[ssrType];
    };
    ShoppingCartExtrasComponent.prototype.setupExtras = function (extrasInput) {
        this.extras = (extrasInput || []).filter(function (e) { return e.flightsWithItems.length; }).sort2(function (e) { return e.ssrType; });
        this.total = this.extras.sum(function (e) { return e.amount; });
        this.validateSsrTypeMedata();
        if (this.extras[0] && this.extras[0].flights && this.extras[0].flights[0].segments.length > 1) {
            this.extras.forEach(function (ex) {
                if (ex.ssrType == 6)
                    return;
                ex.flights.forEach(function (f) {
                    var _loop_1 = function (index) {
                        f.segments[index].passengers.forEach(function (p) {
                            var passengerIndex = f.segments[index].passengers.indexOf(p);
                            p.items.forEach(function (i) {
                                var itemIndex = p.items.indexOf(i);
                                f.segments[0].passengers[passengerIndex].items[itemIndex].totalAmount += i.totalAmount;
                            });
                        });
                    };
                    for (var index = 1; index < f.segments.length; index++) {
                        _loop_1(index);
                    }
                });
            });
        }
    };
    ShoppingCartExtrasComponent.prototype.setupSsrTypeMetadata = function () {
        var name = '';
        var icon = '';
        for (var ssrType in SsrType) {
            if (!Number(ssrType)) {
                continue;
            }
            switch (+ssrType) {
                case SsrType.Baggage:
                    name = 'Hold Luggage';
                    icon = 'font-ico-luggage';
                    break;
                case SsrType.Meal:
                    name = 'Meals';
                    icon = 'font-ico-meal';
                    break;
                case SsrType.Pet:
                    name = 'Pets';
                    icon = 'font-ico-dog';
                    break;
                case SsrType.SpecialEquipment:
                    name = 'Other equipments';
                    icon = 'font-ico-golf';
                    break;
                case SsrType.Lounge:
                    name = 'Lounge';
                    icon = 'font2-ico-lounge';
                    break;
                case SsrType.SpecialAssistance:
                    name = 'Special assistance';
                    icon = 'font-ico-spec-assistance';
                    break;
                case SsrType.Seat:
                    name = 'Seat';
                    icon = 'font-ico-seat';
                    break;
                case SsrType.PriorityBag:
                    name = 'Priority bag';
                    icon = 'font-ico-priority-bag';
                    break;
                case SsrType.PriorityBoarding:
                    name = 'Priority boarding';
                    icon = 'font-ico-priority-boarding-2bags';
                    break;
                case SsrType.BusTransfer:
                    name = 'Buss transfer';
                    icon = 'font-ico-shuttle';
                    break;
                case SsrType.FastTrack:
                    name = 'Fast Track';
                    icon = 'font-ico-fast-track';
                    break;
                case SsrType.AirportCheckin:
                    name = 'Airport Checkin';
                    icon = 'font-ico-airport-checkin';
                    break;
                case SsrType.EarlyCheckin:
                    name = 'Early Checkin';
                    icon = 'font-ico-early-checkin';
                    break;
                case SsrType.BundleUpgrade:
                    name = 'Add-on Bundles';
                    icon = 'font-ico-tag';
                    break;
                case SsrType.Insurance:
                    name = 'Insurance';
                    icon = 'font-ico-insurance';
                    break;
                case SsrType.Flex:
                    name = 'Flex';
                    icon = 'font2-ico-flex';
                    break;
                case SsrType.BlueBenefits:
                    name = 'Benefits';
                    icon = 'font-ico-blue-benefits';
                    break;
                case SsrType.PSO:
                    name = 'Pso Discount';
                    icon = 'font-ico-blue-benefits';
                    break;
                case SsrType.Other:
                default:
                    name = 'Other';
                    icon = '';
            }
            this.ssrTypeMetadata[ssrType] = { name: name, icon: icon };
        }
    };
    ShoppingCartExtrasComponent.prototype.validateSsrTypeMedata = function () {
        var _this = this;
        this.extras.forEach(function (e) {
            if (!_this.ssrTypeMetadata[e.ssrType]) {
                _this.ssrTypeMetadata[e.ssrType] = {
                    name: e.ssrType.toString(),
                    icon: ''
                };
            }
        });
    };
    return ShoppingCartExtrasComponent;
}());
export { ShoppingCartExtrasComponent };
